import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
  Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";

import { RELATIONSHIPOPTIONS } from "../../constants";
import { useDispatch } from "react-redux";

export default function DialogForm({
  open,
  handleClose,
  handleSubmit,
  isEditMode,
  rowData,
}) {
  const [formState, setFormState] = useState({
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    relationship: "",
    status: "Active",
    digitalSignature: false,
  });

  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    relationship: false,
    digitalSignature: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditMode && rowData) {
      setFormState(rowData);
    } else {
      setFormState({
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        relationship: "",
        status: "Active",
        digitalSignature: false,
      });
    }

    setErrors({
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      relationship: false,
      digitalSignature: false,
    });
  }, [isEditMode, rowData, open]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({ ...prevState, [name]: false }));
  };

  const handleRelationChange = (event, newValue) => {
    setFormState((prevState) => ({
      ...prevState,
      relationship: newValue || "",
    }));
    setErrors((prevState) => ({ ...prevState, relationship: false }));
  };

  const handleCheckboxChange = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      digitalSignature: e.target.checked,
    }));
    setErrors((prevState) => ({ ...prevState, digitalSignature: false }));
  };

  // Email validation regex
  const emailValidator = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Phone validation regex (allows digits, spaces, dashes, parentheses)
  const phoneValidator = (phone) => {
    const phoneRegex = /^[0-9\-\(\)\s]+$/;
    return phoneRegex.test(phone) && phone.length >= 10 && phone.length <= 15; // Assuming a minimum of 10 digits
  };

  const validateForm = () => {
    const newErrors = {
      firstName: !formState.firstName,
      lastName: !formState.lastName,
      email: !formState.email || !emailValidator(formState.email), // Email validation
      phone: !formState.phone || !phoneValidator(formState.phone), // Phone validation
      relationship: !formState.relationship,
      digitalSignature: !formState.digitalSignature,
    };

    setErrors(newErrors);

    return (
      !newErrors.firstName &&
      !newErrors.lastName &&
      !newErrors.email &&
      !newErrors.phone &&
      !newErrors.relationship &&
      !newErrors.digitalSignature
    );
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      handleSubmit(formState);
    }
  };

  const handleActivate = () => {
    setFormState((prevState) => ({
      ...prevState,
      status: "Active",
    }));
    handleSubmit(formState, "reactivate");
  };

  const isInactive = formState.status === "Inactive";

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {isEditMode ? "Edit Entry" : "Add Entry"}
        {isEditMode && isInactive && (
          <Button
            onClick={handleActivate}
            sx={{
              backgroundColor: "blue",
              color: "white",
              position: "absolute",
              right: 16,
              top: 16,
              "&:hover": {
                backgroundColor: "darkblue",
              },
            }}>
            Activate
          </Button>
        )}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          {/* First Name and Last Name in a row */}
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formState.firstName}
              onChange={handleInputChange}
              required
              error={errors.firstName}
              helperText={errors.firstName && "First Name is required"}
              disabled={isInactive}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formState.lastName}
              onChange={handleInputChange}
              required
              error={errors.lastName}
              helperText={errors.lastName && "Last Name is required"}
              disabled={isInactive}
            />
          </Grid>

          {/* Email */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              required
              error={errors.email}
              helperText={errors.email ? "Valid Email is required" : ""}
              disabled={isInactive}
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phone"
              value={formState.phone}
              onChange={handleInputChange}
              required
              error={errors.phone}
              helperText={
                errors.phone
                  ? "Valid phone number is required (digits, dashes, spaces)"
                  : ""
              }
              disabled={isInactive}
            />
          </Grid>

          {/* Relationship */}
          <Grid item xs={12}>
            <Autocomplete
              options={RELATIONSHIPOPTIONS}
              value={formState.relationship}
              onChange={handleRelationChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Relationship"
                  required
                  error={errors.relationship}
                  helperText={errors.relationship && "Relationship is required"}
                  disabled={isInactive}
                />
              )}
            />
          </Grid>

          {/* Digital Signature and Agreement */}
          <Grid item xs={12}>
            <Typography
              sx={{
                height: "150px",
                overflowY: "auto",
                border: "1px solid #ccc",
                padding: "10px",
                marginBottom: "10px",
              }}>
              By selecting "I agree", I give permission to{" "}
              <b>
                {formState.firstName} {formState.lastName}
              </b>{" "}
              to access and view data related to my performance in the doctor's
              session. This agreement serves as my digital signature, confirming
              my consent to share the session's performance data.
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formState.digitalSignature}
                    onChange={handleCheckboxChange}
                    sx={{
                      "&.Mui-checked": { color: "green" },
                    }}
                    disabled={isInactive}
                  />
                }
                label={
                  <Typography
                    sx={{
                      fontWeight: "bold",
                    }}>
                    I agree
                  </Typography>
                }
              />
              <Typography sx={{ color: "red", fontWeight: "bold" }}>
                This is equivalent to a digital signature
              </Typography>
              {errors.digitalSignature && (
                <Typography sx={{ color: "red" }}>
                  You must agree to the terms and conditions before saving.
                </Typography>
              )}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          sx={{
            backgroundColor: "red",
            color: "white",
            "&:hover": {
              backgroundColor: "darkred",
            },
          }}>
          Cancel
        </Button>
        <Button
          onClick={handleFormSubmit}
          sx={{
            backgroundColor: "blue",
            color: "white",
            "&:hover": {
              backgroundColor: "darkblue",
            },
          }}
          disabled={isInactive}>
          {isEditMode ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
