// src/redux/reducers/clientReducer.js
import * as types from "../../Actions/actionTypes";

const initialState = {
  loading: false,
  otpSent: false,
  otpVerified: false,
  userSignedUp: false,
  consentDetails: null,
  consentFormError: null,
  consentFormSaved: false,
  consentFormDeleted: false,
  consentFormUpdated: false,
  mainClients: null,
  error: null,
};

export const clientAcessReducer = (state = initialState, action) => {
  switch (action.type) {
    // Send OTP Reducer
    case types.SEND_OTP_REQUEST:
      return { ...state, loading: true, otpSent: false, error: null };
    case types.SEND_OTP_SUCCESS:
      return { ...state, loading: false, otpSent: true };
    case types.SEND_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Verify OTP Reducer
    case types.VERIFY_OTP_REQUEST:
      return { ...state, loading: true, otpVerified: false, error: null };
    case types.VERIFY_OTP_SUCCESS:
      return { ...state, loading: false, otpVerified: true };
    case types.VERIFY_OTP_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Signup Reducer
    case types.SIGNUP_CLIENT_REQUEST:
      return { ...state, loading: true, userSignedUp: false, error: null };
    case types.SIGNUP_CLIENT_SUCCESS:
      return { ...state, loading: false, userSignedUp: true };
    case types.SIGNUP_CLIENT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Get Consent Form Reducer
    case types.GET_CONSENT_FORM_REQUEST:
      return { ...state, loading: true, consentFormError: null };
    case types.GET_CONSENT_FORM_SUCCESS:
      return { ...state, loading: false, consentDetails: action.payload };
    case types.GET_CONSENT_FORM_FAILURE:
      return { ...state, loading: false };
    case types.FETCH_MAIN_CLIENTS:
      return { ...state, mainClients: action.payload };

    // Save Consent Form Reducer
    case types.SAVE_CONSENT_FORM_REQUEST:
      return { ...state, loading: true, consentFormSaved: false, error: null };
    case types.SAVE_CONSENT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        consentFormSaved: true,
        consentDetails: [...state.consentDetails, action.payload],
      };
    case types.SAVE_CONSENT_FORM_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Delete Consent Form Reducer
    case types.DELETE_CONSENT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        consentFormDeleted: false,
        error: null,
      };
    case types.DELETE_CONSENT_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        consentFormDeleted: true,
        consentDetails: state.consentDetails.map((consent) =>
          consent.id === action.payload.id
            ? { ...consent, status: "Inactive" }
            : consent
        ),
      };
    case types.DELETE_CONSENT_FORM_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Retrieve Consent Form Reducer
    case types.RETRIEVE_CONSENT_FORM_REQUEST:
      return { ...state, loading: true, error: null };
    case types.RETRIEVE_CONSENT_FORM_SUCCESS:
      return { ...state, loading: false, consentDetails: action.payload };
    case types.RETRIEVE_CONSENT_FORM_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Update Consent Form Reducer
    case types.UPDATE_CONSENT_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        consentFormUpdated: false,
        error: null,
      };
    case types.UPDATE_CONSENT_FORM_SUCCESS:
      console.log(action.payload, "payload details ", state.consentDetails);
      return {
        ...state,
        loading: false,
        consentFormUpdated: true,
        consentDetails: state.consentDetails?.map((consent) =>
          consent.id === action.payload.id ? action.payload : consent
        ),
      };
    case types.UPDATE_CONSENT_FORM_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
