import React from "react";
import "./login.css";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../SessionManagement/authContext";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { Alert, CircularProgress } from "@mui/material";
import SessionTimeout from "../SessionTimeOut";
import useSharedLink from "../SharedLink";
import {
  fetchRoles,
  fetchStaff,
  fetchPermissions,
  fetchBcbaAndAbove,
} from "../../Redux/ActionCreators/StaffActionCreator";
import { fetchClients } from "../../Redux/ActionCreators/ClientActionCreator";
import { useDispatch } from "react-redux";
import { API_BASE_URL } from "../../constants";
import axiosInstance from "../../components/AxiosInstance";
import ClientAccessPages from "../../ClientAccessPages/AuthenTication/index";
import {
  getConsents,
  getMainClients,
} from "../../Redux/ActionCreators/ClientAccessActionCreator";
import { usePermissions } from "../../Phase3/CheckPermission";

const Login = () => {
  const sharedLink = useSharedLink();
  const { checkAllClientPermission } = usePermissions;
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const [sessionActive, setSessionActive] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [alertcontent, setAlertContent] = useState();
  const [alert, setAlert] = useState(false);
  const [info, setInfo] = useState(false);
  const [infocontent, setInfoContent] = useState();

  const [isSignUp, setIsSignUp] = useState(false); // State to handle Sign Up page

  const dispatch = useDispatch();
  console.log(sharedLink);
  const handleSessionTimeout = () => {
    // Handle session timeout (e.g., clear local storage, redirect)
    localStorage.clear();
    sessionStorage.setItem("sessionExpired", "true");
    navigate("/");
  };
  useEffect(() => {
    if (sessionStorage.getItem("sessionExpired") === "true") {
      handleSessionTimeout();
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.clear();
    setAlert(false);
    setInfo(true);
    setInfoContent("Please wait while we are logging you in");

    console.log("hey login");
    const encodedCredentials = btoa(`${password}`);

    // const data = new FormData();
    const data = {
      username: username, // Do not send the username and password in plain text
      password: password,
    };
    // data.append("password", encodedCredentials);
    console.log("Before calling the login API");

    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/authenticate",
        data // Sending the form data
      );

      const token = response.data.jwt;
      localStorage.setItem("ABAtoken", token);
      console.log("Login response received:", response);
      console.log("response.redirected :", response.redirected);
      console.log("response.status :", response.status);
      console.log("response.url :", response.url);
      console.log("response.headers :", response.headers);
      console.log("response.ok :", response.ok);
      if (response.ok || response.redirected || response.status === 200) {
        // const userDetails = await response.body();
        console.log("Login response/userDetails received:", response);
        try {
          const res1 = await axiosInstance.get(
            // `${sharedLink}/userdetails?username=${username}&password=${password}`,
            `${sharedLink}/userdetails?username=${username}&password=${encodedCredentials}`
            // `${sharedLink}/userdetails?credentials=${encodedCredentials}`,
          );
          console.log("heu you", res1);
          if (res1.status === 200) {
            setInfo(false);
            localStorage.setItem("username", username);
            localStorage.setItem("ABApassword", password);
            if (res1 && res1.data?.details[0]) {
              localStorage.setItem(
                "role",
                username === "admin"
                  ? "admin"
                  : res1.data?.details[0].role
                  ? res1.data?.details[0].role
                  : res1.data?.type
              );

              localStorage.setItem(
                "name",
                username === "admin"
                  ? "Admin"
                  : `${res1.data?.details[0].firstName} ${res1.data?.details[0].lastName}`
              );
              localStorage.setItem(
                "userId",
                username === "admin" ? 1 : res1.data?.details[0].id
              );
              localStorage.setItem(
                "facilityId",
                res1?.data?.details[0]?.facilityId
              );
              localStorage.setItem(
                "supervisorId",
                res1?.data?.details[0]?.supervisor
              );
            }
            // Dispatch API calls
            await Promise.all([
              dispatch(fetchPermissions()),
              dispatch(fetchStaff()),
              dispatch(fetchClients()),
              dispatch(fetchRoles()),
              dispatch(fetchBcbaAndAbove()),
              dispatch(getMainClients()),
            ]);

            // Redirect to the UI dashboard
            if (res1.data?.type == "staff") navigate("/dashboard");
            else navigate("/clientsgraph");
            return;
          } else {
          }
        } catch (err) {
          console.log(err);
          setInfo(false);
          setAlert(true);
          setAlertContent("Invalid Credentials!");
          return;
        }
      } else {
        setInfo(false);
        setAlert(true);
        setAlertContent("Invalid Credentials!");
        return;
      }
      console.log(response);
      const url = response.url;
      console.log(url);
      const er = url.split("?")[1];
      console.log(er);

      if (er && er === "error") {
        console.log("Login failed!");
        setInfo(false);
        setAlert(true);
        setAlertContent("Invalid Credentials!");
      } else {
        console.log("Login successful!");
        setInfo(false);
      }
    } catch (error) {
      console.log("There was an error!");
      console.log(error);
      setInfo(false);
      setAlert(true);
      setAlertContent("Invalid Credentials");
    }
  };

  // This function will show the Sign Up page immediately when the button is clicked
  const handleSignUpClick = () => {
    // setIsSignUp(true); // Show Sign Up page directly
    navigate("/signup");
  };

  // If the user clicks on the Sign Up button, show the Sign Up page
  if (isSignUp) {
    return (
      <div className="signup-container">
        <ClientAccessPages /> {/* This will render the Sign Up page */}
      </div>
    );
  }

  return (
    <div className="login-container">
      {/* {sessionActive && (
        <SessionTimeout
          timeoutDuration={30 * 60}
          onTimeout={handleSessionTimeout}
        />
      )} */}
      {info && (
        <Alert severity="info" onClose={() => setInfo(false)}>
          {infocontent}
        </Alert>
      )}
      {alert && (
        <Alert severity="error" onClose={() => setAlert(false)}>
          {alertcontent}
        </Alert>
      )}
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <label>E m a i l</label>
          <input
            name="username"
            placeholder="Enter your email"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>P a s s w o r d</label>
          <input
            type="password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-btn">
          Login
        </button>
        {/* Navigate to the Sign Up page when clicked */}
        <button
          type="button"
          className="google-btn"
          onClick={handleSignUpClick}>
          Sign Up
        </button>
      </form>
    </div>
  );
};

export default Login;
