import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, TextField, Box, Button } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import LoadingIndicator from "../../scenes/Loader";
import MyLineChart from "../../components/LineGraph";
import { useDispatch, useSelector } from "react-redux";
import { themeStyles } from "../../constants";
import GoalsLineGraphs from "../../Phase2/GoalLineGraph";
export default function ClientsGraph() {
  const dispatch = useDispatch();
  const [goals, setGoals] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);
  const clients =
    useSelector((state) => state?.clientAccess?.mainClients) || [];

  return (
    <Box sx={{ padding: 2 }}>
      {/* Autocomplete for selecting a client */}
      <Autocomplete
        id="client-selector"
        options={clients}
        getOptionLabel={(option) => option.firstName + " " + option.lastName}
        renderInput={(params) => (
          <TextField {...params} label="Select User Profile" variant="filled" />
        )}
        onChange={(event, newValue) => {
          setSelectedClient(newValue);
        }}
        sx={{ width: "auto", flexGrow: 1, ...themeStyles.textField }}
        value={selectedClient}
      />

      {/* Display chart after goal is selected */}
      {selectedClient && (
        <GoalsLineGraphs
          clientId={selectedClient.id}
          showClose={false}
          showDowload={false}
        />
      )}
    </Box>
  );
}
