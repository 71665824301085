import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import {
  DATA_COLLECTION_PAGE,
  SCHEDULER_PAGE,
  STAFF_PAGE,
  CLIENT_PAGE,
  SESSION_NOTE_PAGE,
  ADMIN_PAGE,
  CALENDAR,
  MANAGE_CONSENT_PAGE,
  PERFORMANCE_GRAPH_PAGE,
  READ,
  DASHBOARD,
} from "../../constants";
import { usePermissions } from "../../Phase3/CheckPermission";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PollIcon from "@mui/icons-material/Poll";
import { useNavigate, useLocation } from "react-router-dom";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import { useTheme } from "@mui/material/styles";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import axiosInstance from "../../components/AxiosInstance";
import Consent from "../../ClientAccessPages/Consent";

const Item = ({ title, to, icon, onClick, handleCloseAll }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = location.pathname === to;

  const handleItemClick = () => {
    if (onClick) onClick();
    navigate(to);
    if (handleCloseAll) handleCloseAll();
  };

  const activeStyle = {
    display: "flex",
    alignItems: "center",
    fontWeight: isActive ? "bold" : "normal",
    color: isActive ? "white" : "inherit",
    backgroundColor: isActive ? "#3A57E8" : "transparent",
    borderRadius: "5px",
    boxShadow: isActive ? "0 0 10px rgba(0, 0, 0, 0.2)" : "none",
    padding: "10px",
    width: "100%",
  };

  const activeIcon =
    icon &&
    React.cloneElement(icon, {
      style: { marginRight: 8, color: isActive ? "white" : "inherit" },
    });

  return (
    <MenuItem onClick={handleItemClick}>
      <Box style={activeStyle}>
        {activeIcon}
        <Typography>{title}</Typography>
      </Box>
    </MenuItem>
  );
};

const toCamelCase = (str) => {
  return str.toUpperCase();
};

const Ssidebar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [subMenuAnchor, setSubMenuAnchor] = useState(null);
  const [subSubMenuAnchor, setSubSubMenuAnchor] = useState(null);
  const [currentSubMenu, setCurrentSubMenu] = useState(null);
  const [currentSubSubMenu, setCurrentSubSubMenu] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const rawUserName = localStorage.getItem("name") || "ADMIN";
  const nameParts = rawUserName.split(" ");
  const firstName = toCamelCase(nameParts[0]);
  const lastName = toCamelCase(nameParts.slice(1).join(" "));
  const { checkPermission } = usePermissions();
  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSubMenuAnchor(null);
    setCurrentSubMenu(null);
    setSubSubMenuAnchor(null);
    setCurrentSubSubMenu(null);
  };

  const handleSubMenuOpen = (event, submenu) => {
    setSubMenuAnchor(event.currentTarget);
    setCurrentSubMenu(submenu);
    setSubSubMenuAnchor(null);
    setCurrentSubSubMenu(null);
  };

  const handleSubSubMenuOpen = (event, subsubmenu) => {
    setSubSubMenuAnchor(event.currentTarget);
    setCurrentSubSubMenu(subsubmenu);
  };

  const handleMenuCloseAll = () => {
    setMenuAnchor(null);
    setSubMenuAnchor(null);
    setCurrentSubMenu(null);
    setSubSubMenuAnchor(null);
    setCurrentSubSubMenu(null);
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
    handleMenuClose();
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleLogout = async () => {
    setOpenLogoutDialog(false);
    setLogoutInProgress(true);
    try {
      const res = await axiosInstance.get(
        "https://thebestqualityabanotes.com/logout",
        { withCredentials: true }
      );
      if (res.status === 200) {
        localStorage.clear();
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLogoutInProgress(false);
    }
  };

  if (logoutInProgress) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ marginRight: 2 }}>
      <IconButton onClick={handleMenuOpen}>
        <MenuOutlinedIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
        <MenuItem disabled>
          <Typography variant="h6">
            {firstName} {lastName}
          </Typography>
        </MenuItem>
        {checkPermission(DASHBOARD, READ) && (
          <Item
            title="Dashboard"
            to="/dashboard"
            icon={<HomeIcon />}
            handleCloseAll={handleMenuCloseAll}
          />
        )}
        {checkPermission(DATA_COLLECTION_PAGE, "r") && (
          <MenuItem
            onClick={(event) => handleSubMenuOpen(event, "dataCollection")}>
            <Box display="flex" alignItems="center">
              <PollIcon style={{ marginRight: 8 }} />
              <Typography>Data Collection</Typography>
            </Box>
          </MenuItem>
        )}
        <Divider sx={{ bgcolor: "blue" }} />
        {currentSubMenu === "dataCollection" && (
          <Menu
            anchorEl={subMenuAnchor}
            open={Boolean(subMenuAnchor)}
            onClose={() => setSubMenuAnchor(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: -10,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            <MenuItem
              onClick={(event) => handleSubSubMenuOpen(event, "goalBank")}>
              <Typography>Goal Bank</Typography>
            </MenuItem>
            <Divider sx={{ bgcolor: "blue" }} />
            {currentSubSubMenu === "goalBank" && (
              <Menu
                anchorEl={subSubMenuAnchor}
                open={Boolean(subSubMenuAnchor)}
                onClose={() => setSubSubMenuAnchor(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: -10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <Item
                  title="Add Goal"
                  to="/addGoal"
                  handleCloseAll={handleMenuCloseAll}
                />
                <Divider sx={{ bgcolor: "blue" }} />
                <Item
                  title="Manage Goal"
                  to="/manageGoal"
                  handleCloseAll={handleMenuCloseAll}
                />
              </Menu>
            )}
            <MenuItem
              onClick={(event) =>
                handleSubSubMenuOpen(event, "programBookTemplate")
              }>
              <Typography>Program Book Template</Typography>
            </MenuItem>
            <Divider sx={{ bgcolor: "blue" }} />
            {currentSubSubMenu === "programBookTemplate" && (
              <Menu
                anchorEl={subSubMenuAnchor}
                open={Boolean(subSubMenuAnchor)}
                onClose={() => setSubSubMenuAnchor(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: -10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <Item
                  title="Add Program Book Template"
                  to="/addProgramBookTemplate"
                  handleCloseAll={handleMenuCloseAll}
                />
                <Divider sx={{ bgcolor: "blue" }} />
                <Item
                  title="Manage Program Book Template"
                  to="/manageProgramBookTemplate"
                  handleCloseAll={handleMenuCloseAll}
                />
              </Menu>
            )}
            <MenuItem
              onClick={(event) => handleSubSubMenuOpen(event, "programBook")}>
              <Typography>Program Book</Typography>
            </MenuItem>
            <Divider sx={{ bgcolor: "blue" }} />
            {currentSubSubMenu === "programBook" && (
              <Menu
                anchorEl={subSubMenuAnchor}
                open={Boolean(subSubMenuAnchor)}
                onClose={() => setSubSubMenuAnchor(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: -10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <Item
                  title="Add Program Book"
                  to="/addProgramBook"
                  handleCloseAll={handleMenuCloseAll}
                />
                <Divider sx={{ bgcolor: "blue" }} />
                <Item
                  title="Manage Program Book"
                  to="/manageProgramBook"
                  handleCloseAll={handleMenuCloseAll}
                />
              </Menu>
            )}
          </Menu>
        )}
        {(checkPermission(SESSION_NOTE_PAGE, "C") ||
          checkPermission(SESSION_NOTE_PAGE, "r")) && (
          <MenuItem
            onClick={(event) => handleSubMenuOpen(event, "sessionNotes")}>
            <Box display="flex" alignItems="center">
              <NoteAltIcon style={{ marginRight: 8 }} />
              <Typography>Session Notes</Typography>
            </Box>
          </MenuItem>
        )}
        <Divider sx={{ bgcolor: "blue" }} />
        {currentSubMenu === "sessionNotes" && (
          <Menu
            anchorEl={subMenuAnchor}
            open={Boolean(subMenuAnchor)}
            onClose={() => setSubMenuAnchor(null)}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: -10,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}>
            {checkPermission(SESSION_NOTE_PAGE, "c") && (
              <Item
                title="Add Session Note"
                to="/chooseSessionNote"
                handleCloseAll={handleMenuCloseAll}
              />
            )}
            <Divider sx={{ bgcolor: "blue" }} />{" "}
            {checkPermission(SESSION_NOTE_PAGE, "r") && (
              <Item
                title="View Session Note"
                to="/viewSessionNotes"
                handleCloseAll={handleMenuCloseAll}
              />
            )}
          </Menu>
        )}
        {checkPermission(ADMIN_PAGE, "r") && (
          <>
            <MenuItem onClick={(event) => handleSubMenuOpen(event, "admin")}>
              <Box display="flex" alignItems="center">
                <AdminPanelSettingsIcon style={{ marginRight: 8 }} />
                <Typography>Admin</Typography>
              </Box>
            </MenuItem>
            <Divider sx={{ bgcolor: "blue" }} />
            {currentSubMenu === "admin" && (
              <Menu
                anchorEl={subMenuAnchor}
                open={Boolean(subMenuAnchor)}
                onClose={() => setSubMenuAnchor(null)}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      left: -10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                <MenuItem
                  onClick={(event) => handleSubSubMenuOpen(event, "staff")}>
                  <Typography>Staff</Typography>
                </MenuItem>
                <Divider sx={{ bgcolor: "blue" }} />
                {currentSubSubMenu === "staff" && (
                  <Menu
                    anchorEl={subSubMenuAnchor}
                    open={Boolean(subSubMenuAnchor)}
                    onClose={() => setSubSubMenuAnchor(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: -10,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                    <Item
                      title="Add Staff"
                      to="/addStaff"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="Manage Staff"
                      to="/manageStaff"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="View Staff"
                      to="/viewStaff"
                      handleCloseAll={handleMenuCloseAll}
                    />
                  </Menu>
                )}
                <MenuItem
                  onClick={(event) => handleSubSubMenuOpen(event, "client")}>
                  <Typography>Client</Typography>
                </MenuItem>
                <Divider sx={{ bgcolor: "blue" }} />
                {currentSubSubMenu === "client" && (
                  <Menu
                    anchorEl={subSubMenuAnchor}
                    open={Boolean(subSubMenuAnchor)}
                    onClose={() => setSubSubMenuAnchor(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: -10,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                    <Item
                      title="Add Client"
                      to="/addClient"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="Manage Client"
                      to="/manageClient"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="View Client"
                      to="/viewClient"
                      handleCloseAll={handleMenuCloseAll}
                    />
                  </Menu>
                )}
                <MenuItem
                  onClick={(event) => handleSubSubMenuOpen(event, "facility")}>
                  <Typography>Facility</Typography>
                </MenuItem>
                <Divider sx={{ bgcolor: "blue" }} />
                {currentSubSubMenu === "facility" && (
                  <Menu
                    anchorEl={subSubMenuAnchor}
                    open={Boolean(subSubMenuAnchor)}
                    onClose={() => setSubSubMenuAnchor(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: -10,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                    <Item
                      title="Add Facility"
                      to="/addFacility"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="Manage Facility"
                      to="/manageFacility"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="View Facility"
                      to="/viewFacility"
                      handleCloseAll={handleMenuCloseAll}
                    />
                  </Menu>
                )}

                <MenuItem
                  onClick={(event) =>
                    handleSubSubMenuOpen(event, "priorAuthorization")
                  }>
                  <Typography>Prior Authorization</Typography>
                </MenuItem>
                <Divider sx={{ bgcolor: "blue" }} />

                {currentSubSubMenu === "priorAuthorization" && (
                  <Menu
                    anchorEl={subSubMenuAnchor}
                    open={Boolean(subSubMenuAnchor)}
                    onClose={() => setSubSubMenuAnchor(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: 10, // Change the left value to 10
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                    <Item
                      title="Add Authorization"
                      to="/AuthorizationClient"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="Manage Authorization"
                      to="/ManagePriorAuthorization"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                  </Menu>
                )}
                <MenuItem
                  onClick={(event) => handleSubSubMenuOpen(event, "files")}>
                  <Typography>Files</Typography>
                </MenuItem>
                <Divider sx={{ bgcolor: "blue" }} />

                {currentSubSubMenu === "files" && (
                  <Menu
                    anchorEl={subSubMenuAnchor}
                    open={Boolean(subSubMenuAnchor)}
                    onClose={() => setSubSubMenuAnchor(null)}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: -10, // Ensure left value is -10 for files submenu
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}>
                    <Item
                      title="Upload File"
                      to="/UploadFile"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                    <Item
                      title="Manage File"
                      to="/ManageFile"
                      handleCloseAll={handleMenuCloseAll}
                    />
                    <Divider sx={{ bgcolor: "blue" }} />
                  </Menu>
                )}
              </Menu>
            )}
          </>
        )}
        {checkPermission(CALENDAR, "r") && (
          <>
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarMonthIcon />}
              handleCloseAll={handleMenuCloseAll}
            />
          </>
        )}
        <Divider sx={{ bgcolor: "blue" }} />
        {checkPermission(SCHEDULER_PAGE, READ) && (
          <>
            <Item
              title="Scheduler"
              to="/scheduler"
              icon={<ScheduleIcon />}
              handleCloseAll={handleMenuCloseAll}
            />
          </>
        )}
        <Divider sx={{ bgcolor: "blue" }} />
        {checkPermission(MANAGE_CONSENT_PAGE, READ) && (
          <>
            <Item
              title="Consent Authorities"
              to="/consent"
              icon={<GroupsIcon />}
              handleCloseAll={handleMenuCloseAll}
            />
          </>
        )}
        <Divider sx={{ bgcolor: "blue" }} />
        <Divider sx={{ bgcolor: "blue" }} />
        {checkPermission(PERFORMANCE_GRAPH_PAGE, READ) && (
          <>
            <Item
              title="Performance Graph"
              to="/clientsgraph"
              icon={<TimelineIcon />}
              handleCloseAll={handleMenuCloseAll}
            />
          </>
        )}
        <Divider sx={{ bgcolor: "blue" }} />
      </Menu>

      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog}>No</Button>
          <Button onClick={handleLogout} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Ssidebar;
