import { Box, Button, TextField, Autocomplete, Alert } from "@mui/material";
import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import useSharedLink from "../SharedLink";
import {
  themeStyles,
  buttonStyle,
  STATES,
  GENDERS,
  MARITALSTATUS,
  STATUS,
  DIAGNOSISCODES,
} from "../../constants";
import LoadingIndicator from "../Loader";
import { useSnackbar } from "../../components/SnackBarProvider";
import { useNavigate, useLocation } from "react-router-dom";

const ClientForm = ({ mode = "add" }) => {
  const sharedLink = useSharedLink();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { openSnackbar } = useSnackbar();
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("Id");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setInitialValues(defaultInitialValues);
    async function fetchData() {
      try {
        const facilityResponse = await axiosInstance.post(
          `${sharedLink}/viewallfacility`,
          { withCredentials: true }
        );
        const formattedFacilities = facilityResponse.data.map(
          (facility) => `${facility.facilityName} (${facility.id})`
        );
        setFacilities(formattedFacilities);

        const staffResponse = await axiosInstance.post(
          `${sharedLink}/viewallstaff`,
          {},
          { withCredentials: true }
        );
        const formattedStaff = staffResponse.data
          .filter((user) => user.status === "Active")
          .map((user) => `${user.firstName} ${user.lastName} (${user.id})`);
        setUsers(formattedStaff);

        if (mode === "update" && id) {
          const clientResponse = await axiosInstance.post(
            `${sharedLink}/viewclient`,
            {
              id,
            },
            { withCredentials: true }
          );
          const data = clientResponse.data[0];
          const formattedPolicyDate = dayjs(data.policyEffectiveDate);
          const formattedDateOfBirth = dayjs(data.dateOfBirth);
          const formattedDateOfAdmission = dayjs(data.dateOfAdmission);
          const formattedDateOfDischarge = dayjs(data.dateOfDischarge);
          const userArray = data?.assignedUser?.map((userId, index) => {
            const userName = data?.assignedUserName[index];
            return `${userName} (${userId})`;
          });
          const initialValues = {
            firstname: data.firstName,
            lastname: data.lastName,
            email: data.email,
            streetaddress: data.streetAddress,
            city: data.city,
            policynumber: data.policyNumber,
            gender: data.gender,
            insurancename: data.insuranceName,
            groupname: data.groupName,
            status: data.status,
            assigneduser: userArray,
            state: data.state,
            zipcode: data.zipCode,
            country: data.country,
            facilityid: `${data.facilityName} (${data.facilityId})`,
            phonenumber: data.phoneNumber,
            diagnosiscode: data.diagnosisCode,
            maritalstatus: data.maritalStatus,
            initialcomment: data.initialComment,
            policyeffectivedate: data.policyEffectiveDate
              ? formattedPolicyDate
              : null,
            dateofadmission: formattedDateOfAdmission,
            dateofdischarge: data.dateOfDischarge
              ? formattedDateOfDischarge
              : null,
            dateofbirth: formattedDateOfBirth,
          };
          console.log("initial values", initialValues);
          setInitialValues(initialValues);
        } else {
          setInitialValues(defaultInitialValues);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        openSnackbar("Error fetching data", "error");
      }
    }

    fetchData();
  }, [id]);
  const handleReset = (resetForm) => {
    resetForm();
  };
  const disableFutureDates = (date) => date > new Date();

  const handleFormSubmit = async (
    values,
    { resetForm, setErrors, validateForm }
  ) => {
    console.log("inside");

    const errors = await validateForm();

    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      openSnackbar("Please fill out all required fields", "error");
      return;
    }

    const formattedDateOfBirth = dayjs(values.dateofbirth).format("YYYY-MM-DD");
    const admissionDate = dayjs(values.dateofadmission);
    const dischargeDate = dayjs(values.dateofdischarge);

    if (dischargeDate.isBefore(admissionDate, "day")) {
      openSnackbar("Discharge date must be after Admission date", "error");
      return;
    }
    openSnackbar("Saving.. ", "info");

    const ids = values.assigneduser?.map(
      (user) => user.match(/\(([^)]+)\)/)[1]
    );
    const concatenatedIds = ids ? ids.join(",") : "";

    const payload = {
      ...values,
      dateofbirth: formattedDateOfBirth,
      dateofadmission: dayjs(values.dateofadmission).format("YYYY-MM-DD"),
      dateofdischarge:
        dayjs(values.dateofdischarge).format("YYYY-MM-DD") === "Invalid Date"
          ? null
          : dayjs(values.dateofdischarge).format("YYYY-MM-DD"),
      policyeffectivedate:
        dayjs(values.policyeffectivedate).format("YYYY-MM-DD") ===
        "Invalid Date"
          ? null
          : dayjs(values.policyeffectivedate).format("YYYY-MM-DD"),
      facilityid: values.facilityid.split("(")[1].split(")")[0],
      assigneduser: concatenatedIds,
    };

    try {
      if (mode === "add") {
        const res = await axiosInstance.post(
          `${sharedLink}/addclient`,
          payload,
          { withCredentials: true }
        );
        if (res.status === 200) {
          resetForm();
          openSnackbar("Client Created Successfully", "success");
          setSubmissionSuccess(true);
        } else {
          openSnackbar("Error in Creating Client", "error");
        }
      } else {
        payload.id = id;
        const res = await axiosInstance.post(
          `${sharedLink}/updateclient`,
          payload,
          { withCredentials: true }
        );
        if (res.status === 200) {
          openSnackbar("Client Updated Successfully", "success");
          setSubmissionSuccess(true);
        } else {
          openSnackbar("Error in Updating Client", "error");
        }
      }
    } catch (error) {
      openSnackbar(error, "error");
    }
  };

  if (loading) return <LoadingIndicator />;

  return (
    <div className="client-background">
      <Box m="20px">
        <Header
          title={mode === "add" ? "Add Client" : "Update Client"}
          subtitle=""
        />
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          enableReinitialize>
          {({
            values,
            errors,
            touched,
            resetForm,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            validateForm,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 2" },
                }}>
                <Autocomplete
                  fullWidth
                  options={facilities}
                  name="facilityid"
                  autoHighlight
                  className="animated-input"
                  onChange={(event, newValue) =>
                    setFieldValue("facilityid", newValue)
                  }
                  disabled={values.status == "Inactive"}
                  value={values.facilityid}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Facility Name"
                      variant="filled"
                      required
                      error={touched.facilityid && Boolean(errors.facilityid)}
                      helperText={touched.facilityid && errors.facilityid}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Facility Name",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  className="animated-input"
                  label="User Name (Email)"
                  required
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  disabled={values.status == "Inactive"}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  type="text"
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  disabled={values.status == "Inactive"}
                  name="firstname"
                  required
                  error={touched.firstname && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Last Name"
                  disabled={values.status == "Inactive"}
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastname}
                  required
                  name="lastname"
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  className="animated-input"
                  variant="filled"
                  type="tel"
                  label="Contact Number"
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  required
                  onChange={handleChange}
                  value={values.phonenumber}
                  name="phonenumber"
                  inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                  error={touched.phonenumber && Boolean(errors.phonenumber)}
                  helperText={touched.phonenumber && errors.phonenumber}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <DatePicker
                  fullWidth
                  variant="filled"
                  label="Date of Birth"
                  onBlur={handleBlur}
                  required
                  disabled={values.status == "Inactive"}
                  onChange={(value) => setFieldValue("dateofbirth", value)}
                  value={values.dateofbirth || null}
                  name="dateofbirth"
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      error: touched.dateofbirth && Boolean(errors.dateofbirth),
                      helperText: touched.dateofbirth && errors.dateofbirth,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                  disableFuture
                />

                <Autocomplete
                  fullWidth
                  options={GENDERS}
                  autoHighlight
                  className="animated-input"
                  value={values.gender}
                  onChange={(event, newValue) =>
                    setFieldValue("gender", newValue)
                  }
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Gender"
                      variant="filled"
                      required
                      error={touched.gender && Boolean(errors.gender)}
                      helperText={touched.gender && errors.gender}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Gender",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Country"
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={values.status == "Inactive"}
                  value={values.country}
                  name="country"
                  required
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Street Address"
                  className="animated-input"
                  required
                  onBlur={handleBlur}
                  disabled={values.status == "Inactive"}
                  onChange={handleChange}
                  value={values.streetaddress}
                  name="streetaddress"
                  error={touched.streetaddress && Boolean(errors.streetaddress)}
                  helperText={touched.streetaddress && errors.streetaddress}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={STATES}
                  autoHighlight
                  className="animated-input"
                  value={values.state}
                  onChange={(event, newValue) =>
                    setFieldValue("state", newValue)
                  }
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="filled"
                      required
                      error={touched.state && Boolean(errors.state)}
                      helperText={touched.state && errors.state}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select state",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="City"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="animated-input"
                  required
                  value={values.city}
                  name="city"
                  disabled={values.status == "Inactive"}
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  className="animated-input"
                  label="Zip code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.zipcode}
                  name="zipcode"
                  disabled={values.status == "Inactive"}
                  error={touched.zipcode && Boolean(errors.zipcode)}
                  helperText={touched.zipcode && errors.zipcode}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  className="animated-input"
                  label="Policy Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled={values.status == "Inactive"}
                  required
                  value={values.policynumber}
                  name="policynumber"
                  error={touched.policynumber && Boolean(errors.policynumber)}
                  helperText={touched.policynumber && errors.policynumber}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <DatePicker
                  fullWidth
                  variant="filled"
                  label="Policy Effective Date"
                  onBlur={handleBlur}
                  required
                  onChange={(value) =>
                    setFieldValue("policyeffectivedate", value)
                  }
                  value={values.policyeffectivedate || null}
                  name="policyeffectivedate"
                  disabled={values.status == "Inactive"}
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      error:
                        touched.policyeffectivedate &&
                        Boolean(errors.policyeffectivedate),
                      helperText:
                        touched.policyeffectivedate &&
                        errors.policyeffectivedate,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                  disablePast
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Insurance Name"
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className="animated-input"
                  value={values.insurancename}
                  name="insurancename"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Group Name"
                  disabled={values.status == "Inactive"}
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.groupname}
                  name="groupname"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <DatePicker
                  fullWidth
                  variant="filled"
                  label="Date of Admission"
                  onBlur={handleBlur}
                  required
                  disabled={values.status == "Inactive"}
                  onChange={(value) => setFieldValue("dateofadmission", value)}
                  value={values.dateofadmission || null}
                  name="dateofadmission"
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      error:
                        touched.dateofadmission &&
                        Boolean(errors.dateofadmission),
                      helperText:
                        touched.dateofadmission && errors.dateofadmission,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                  disableFuture
                />

                <DatePicker
                  fullWidth
                  variant="filled"
                  type="date"
                  label="Date of Discharge"
                  onBlur={handleBlur}
                  disabled={values.status == "Inactive"}
                  onChange={(value) => setFieldValue("dateofdischarge", value)}
                  value={values.dateofdischarge || null}
                  name="dateofdischarge"
                  InputLabelProps={{ shrink: true }}
                  error={
                    touched.dateofdischarge && Boolean(errors.dateofdischarge)
                  }
                  helperText={touched.dateofdischarge && errors.dateofdischarge}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={DIAGNOSISCODES}
                  autoHighlight
                  className="animated-input"
                  value={values.diagnosiscode}
                  onChange={(event, newValue) =>
                    setFieldValue("diagnosiscode", newValue)
                  }
                  onBlur={handleBlur}
                  disabled={values.status == "Inactive"}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Diagnosis Code"
                      variant="filled"
                      required
                      error={
                        touched.diagnosiscode && Boolean(errors.diagnosiscode)
                      }
                      helperText={touched.diagnosiscode && errors.diagnosiscode}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select diagnosis code",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Initial Comment"
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.initialcomment}
                  name="initialcomment"
                  disabled={values.status == "Inactive"}
                  error={
                    touched.initialcomment && Boolean(errors.initialcomment)
                  }
                  helperText={touched.initialcomment && errors.initialcomment}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={MARITALSTATUS}
                  autoHighlight
                  className="animated-input"
                  value={values.maritalstatus}
                  onChange={(event, newValue) =>
                    setFieldValue("maritalstatus", newValue)
                  }
                  disabled={values.status == "Inactive"}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Marital Status"
                      variant="filled"
                      required
                      error={
                        touched.maritalstatus && Boolean(errors.maritalstatus)
                      }
                      helperText={touched.maritalstatus && errors.maritalstatus}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select marital status",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={STATUS}
                  className="animated-input"
                  autoHighlight
                  value={values.status}
                  onChange={(event, newValue) =>
                    setFieldValue("status", newValue)
                  }
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="filled"
                      required
                      error={touched.status && Boolean(errors.status)}
                      helperText={touched.status && errors.status}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select status",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  className="animated-input"
                  multiple
                  options={users}
                  name="assigneduser"
                  autoHighlight
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) =>
                    setFieldValue("assigneduser", newValue || [])
                  }
                  disabled={values.status == "Inactive"}
                  value={values.assigneduser || []}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assign User"
                      variant="filled"
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select Assigned User",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
              </Box>

              <Box display="flex" justifyContent="end" mt="20px">
                {mode == "update" ? (
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{ ...buttonStyle }}>
                    Go Back
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleReset(resetForm);
                    }}
                    sx={{ ...buttonStyle }}>
                    Clear
                  </Button>
                )}
                <Button
                  type="submit"
                  sx={{ ...buttonStyle }}
                  variant="contained">
                  {mode === "add" ? "Add Client" : "Update Client"}
                </Button>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/manageclient")}
                    sx={{ ...buttonStyle }}>
                    See in Manage Client
                  </Button>
                }
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const phoneRegExp = /^[0-9]{10}$/;
const checkoutSchema = yup.object().shape({
  facilityid: yup.string().required("Facility is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  firstname: yup.string().required("First name is required"),
  lastname: yup.string().required("Last name is required"),
  phonenumber: yup
    .string()
    .matches(phoneRegExp, "Contact number must be 10 digits")
    .required("Contact number is required"),
  dateofbirth: yup.date().required("Date of birth is required"),
  gender: yup.string().required("Gender is required"),
  country: yup.string().required("Country is required"),
  streetaddress: yup.string().required("Street address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipcode: yup.string().required("Zip code is required"),
  policynumber: yup.string().required("Policy Number is required"),
  policyeffectivedate: yup.date().required("Policy Effective Date is required"),
  dateofadmission: yup.date().required("Date of Admission is required"),
  diagnosiscode: yup.string().required("Diagnosis Code is required"),
  maritalstatus: yup.string().required("Marital Status is required"),
  status: yup.string().required("Status is required"),
});

const defaultInitialValues = {
  firstname: "",
  lastname: "",
  email: "",
  streetaddress: "",
  city: "",
  state: "",
  zipcode: "",
  country: "United States",
  phonenumber: "",
  dateofbirth: null,
  policynumber: "",
  policyeffectivedate: null,
  facilityid: "",
  maritalstatus: "",
  gender: "",
  assigneduser: [],
  status: "Active",
  dateofadmission: null,
  dateofdischarge: null,
  diagnosiscode: "",
  initialcomment: "",
  groupname: "",
  insurancename: "",
  lastupdatedby: "",
  enteredby: "",
};

export default ClientForm;
