import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk"; // Corrected import for thunk
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client"; // Updated for React 18
import { CheckboxProvider } from "./components/InactiveCheckbox";
import App from "./App";
import filefolderReducer from "./Redux/Reducers/FileFolderReducer/filefolderReducer";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import rolesReducer from "./Redux/Reducers/RolesReducer";
import staffReducer from "./Redux/Reducers/StaffReducer";
import permissionsReducer from "./Redux/Reducers/PermissionReducer";
import clientReducer from "./Redux/Reducers/ClientReducer";
import eventReducer from "./Redux/Reducers/CalenderReducer";
import { clientAcessReducer } from "./Redux/Reducers/ClientAccessReducer";
import { goalReducer } from "./Redux/Reducers/GoalRReducer";
const rootReducer = combineReducers({
  filefolders: filefolderReducer,
  roles: rolesReducer,
  staffs: staffReducer,
  permissions: permissionsReducer,
  clients: clientReducer,
  clientAccess: clientAcessReducer,
  calender: eventReducer,
  goals: goalReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  // If you have other middleware, you can add them here as well
  // Redux DevTools Extension is enabled by default in development mode
});

// Updated rendering method for React 18
const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <CheckboxProvider>
      <App />
    </CheckboxProvider>
  </Provider>
);
