import { Box, FormControlLabel, Checkbox ,Button} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";

import { tokens } from "../../../theme";
import { buttonStyle } from "../../../constants";
import Header from "../../../components/Header";
import { useTheme } from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import { useState, useEffect } from "react";
import { Alert } from "@mui/material";
import LoadingIndicator from "../../../scenes/Loader";
import useSharedLink from "../../../scenes/SharedLink";
import { useNavigate } from "react-router-dom";
const formatDate = (date) => {
  if (!date) return "";
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};

const dateTimeFormatter = (value, locale = "en-US") => {
  if (!value) return "";

  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // getMonth() is zero-based
  const day = date.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;

  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true, // true for AM/PM notation
  });

  const formattedTime = timeFormatter.format(date);

  return `${formattedDate} ${formattedTime}`;
};

const ManageProgramBook = () => {
  const navigate = useNavigate();
  const sharedLink = useSharedLink();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const [allbooks, setAllBooks] = useState([]);
  
  const handleClose = () => {
    setAlertDanger(false);
  };
  const handleCheckboxChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (event.target.checked) {
      setRows(allbooks);
    } else {
      setRows(allbooks.filter((row) => row.status === "Active"));
    }
  };
  const handleDeleteClick = (row) => () => {
    if (window.confirm("Sure want to delete?")) {
      const idd = row.id;
      const idToDelete = { id: idd };
      console.log("Sending", idToDelete);
      try {
        axiosInstance
          .post(sharedLink + "/removeprogrambook", idToDelete)
          .then((res) => {
            console.log(res);
            if (res.status === 200) {
              console.log("succss");

              setRows((prevRows) =>
                prevRows.map((row) =>
                  row.id === idd ? { ...row, status: "Inactive" } : row
                )
              );
            } else {
            }
          })
          .catch((error) => {});
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleEditClick = (row) => () => {
    //open
    const id = row.id;
    console.log(id);
    navigate(`/updateProgramBook?id=${id}`);
  };
  useEffect(() => {
    async function fetchData(retryDelay = 2000, retryCount = 0) {
      const maxRetries = 5; // Define the maximum number of retries

      try {
        const res = await axiosInstance.post(sharedLink + "/getallprogrambooks", {
          staffid: localStorage.getItem("userId"),
        }, { withCredentials: true });

        console.log("res", res);
        if (res.status == 200) {
          setRows(res.data.filter((data) => data.status === "Active"));
          setAllBooks(res.data);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        if (retryCount < maxRetries) {
          console.log(
            `Retrying in ${retryDelay / 1000} seconds... (${
              retryCount + 1
            }/${maxRetries})`
          );
          setTimeout(() => fetchData(retryDelay, retryCount + 1), retryDelay);
        } else {
          console.error("Max retries reached. Aborting fetch.");
          setAlertContentDanger(
            "Failed to fetch data after multiple attempts."
          );
          setAlertDanger(true);
          setLoading(false);
        }
      }
    }

    fetchData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },

    {
      field: "programBookName",
      headerName: "Program Book Name",
      width: 150,
    },
    {
      field: "programBookDescription",

      headerName: "Program Book Description",
      width: 350,
    },
    {
      field: "facilityName",

      headerName: "Facility Name",
      width: 250,
    },
    {
      field: "clientName",

      headerName: "Client Name",
      width: 150,
    },
    ,
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      width: 200,
    },

    {
      field: "enteredBy",
      headerName: "Entered By",
      width: 100,
      editable: false,
    },
    {
      field: "clientId",
      headerName: "Client Id",
      width: 100,
      editable: false,
    },
    {
      field: "facilityId",

      headerName: "Facility Id",
      width: 100,
    },

    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    ,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const id = params.row.id;

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(params.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(params.row)}
            disabled={params.row.status === "Inactive"}
            color="inherit"
          />,
        ];
      },
    },
  ];
  if (loading) {
    return <LoadingIndicator />;
  } else
    return (
      <div className="staff-background">
        <Box m="20px">
          {alertDanger && (
            <Alert severity="error" onClose={handleClose}>
              {alertContentDanger}
            </Alert>
          )}
          <Header title="Manage Program Book " subtitle="" />
          <Box
            m="40px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.grey[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#3A57E8",
                borderBottom: "white",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#3A57E8",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.grey[200]} !important`,
              },
            }}>
           <Box display="flex" alignItems="center" justifyContent="space-between" mb="20px">
          <FormControlLabel
            control={
              <Checkbox
                checked={includeInactive}
                onChange={handleCheckboxChange}
                name="includeInactive"
              />
            }
            label="Include Inactive Program Book"
          />
          <Button
            onClick={() => navigate("/addProgramBook")}
            color="primary"
            variant="contained"
            
            sx={{ ...buttonStyle }}
          >
            Add Program Book
          </Button>
        </Box>
            <DataGridPro
              initialState={{
                pagination: { paginationModel: { pageSize: 25 } },
                columns: {
                  columnVisibilityModel: {
                    // Hide columns status and traderName, the other columns will remain visible
                    id: false,
                    clientId: false,
                    facilityId: false,
                    lastUpdatedBy: false,
                    lastUpdatedTime: false,
                    enteredTime: false,
                    enteredBy: false,
                  },
                },
              }}
              sx={{
                boxShadow: 5,
                border: 5,
                borderColor: "primary.light",
                "& .MuiDataGrid-cell:hover": {
                  color: "primary.main",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "white",
                  fontWeight: "bold !important",
                },
                "& .MuiDataGrid-footerContainer": {
                  color: "white",
                  fontWeight: "bold !important",
                },
              }}
              pageSizeOptions={[25, 50, 100]}
              rows={rows}
              columns={columns}
              onRowDoubleClick={(params) => {
                // Call the handleEditClick function or directly navigate
                handleEditClick(params.row)();
              }}
              components={{ Toolbar: GridToolbar }}
            />
            {/* <InitialFilters row={row} /> */}
          </Box>
        </Box>
      </div>
    );
};

export default ManageProgramBook;
