import {
  Box,
  Button,
  TextField,
  MenuItem,
  Snackbar,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  FormControl,
  TextareaAutosize,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import "./index.css";
import { Alert } from "@mui/material";
import { themeStyles, buttonStyle, UPDATE, READ } from "../../constants";

import SignaturePad from "react-signature-canvas";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro";

import axiosInstance from "../../components/AxiosInstance";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Field, Form } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "../../components/SnackBarProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import LoadingIndicator from "../Loader";
import useSharedLink from "../SharedLink";
import { usePermissions } from "../../Phase3/CheckPermission";
import { SESSION_NOTE_97153 } from "../../constants";
const SessionNote = () => {
  const sharedLink = useSharedLink();
  const { checkPermission, giveStaff, giveClients, checkApprover } =
    usePermissions();
  const [canApprove, setCanApprove] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { openSnackbar } = useSnackbar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("sessionId");
  const owner = location?.state?.owner || null;
  const isDisabled =
    checkPermission(SESSION_NOTE_97153, UPDATE, owner) == false;
  const [previouspagevalues, setPreviousPageValues] = useState(
    location.state?.previouspagevalues
  );
  const [signatureAlreadyPresent, setSignatureAlreadyPresent] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [clients, setClients] = useState(
    giveClients(SESSION_NOTE_97153, isDisabled ? READ : UPDATE)
  );
  const [staffs, setStaffs] = useState(
    giveStaff(SESSION_NOTE_97153, isDisabled ? READ : UPDATE)
  );
  console.log(clients, staffs, "update");
  const [supervisor, setSupervisor] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const [sessionData, setSessionData] = useState();
  const [vals, setVals] = useState({});

  const sessionType = ["Adaptive Behaviour Treatment", "Other"];
  const placeOfService = ["Home", "School", "Other"];
  const diagnosisCode = ["F84.0", "Other"];
  const procedureCode = ["97151", "97153", "97156", "97155", "Other"];
  const navigate = useNavigate();
  const moDifier = ["HM", "HN", "HO", "Other"];

  const parentOrGurdian = ["Parent", "Gurdian", "Other"];
  const from = location.state?.from;

  const sigParent = useRef({});
  const sigSupervisor = useRef({});
  const sigStaff = useRef({});
  const clearParentSignature = () => {
    sigParent.current.clear();
  };
  const disableFutureDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Disable dates that are before today
    return date > today;
  };

  const clearSupervisorSignature = () => {
    sigSupervisor.current.clear();
    setIsSignaturePresent(false);
  };
  const handleSupervisorSignature = (event) => {
    console.log(event.target.value);
    console.log(sigSupervisor.current.isEmpty());
    if (sigSupervisor.current.isEmpty()) {
      setIsSignaturePresent(false);
    } else {
      setIsSignaturePresent(true);
    }
  };

  const clearStaffSignature = () => {
    sigStaff.current.clear();
  };

  useEffect(() => {
    async function fetchData(retryCount = 0) {
      const maxRetries = 5; // Define a maximum number of retries to avoid infinite loops
      const retryDelay = 2000; // Delay between retries, in milliseconds (e.g., 2 seconds)

      try {
        const userRole = localStorage.getItem("role");
        const [sessiondata, supervisorResponse, facilitiesResponse] =
          await Promise.all([
            axiosInstance.post(
              sharedLink + "/viewasessionnote",
              { id: sessionId, procedurecode: "97153" },
              { withCredentials: true }
            ),
            axiosInstance.post(
              sharedLink + "/roles/supervisorofrole",
              { role: userRole },
              { withCredentials: true }
            ),
            axiosInstance.post(sharedLink + "/viewallfacility", {
              withCredentials: true,
            }),
          ]);
        setSessionData(sessiondata.data[0]);

        console.log("hye", sessiondata.data[0]);
        const payload = {
          parentsignatureexist: sessiondata.data[0].parentSignatureExist,
          staffsignatureexist: sessiondata.data[0].staffSignatureExist,
          supervisorsignatureexist:
            sessiondata.data[0].supervisorSignatureExist,
          facilityid: {
            id: sessiondata.data[0].facilityId,
            facilityName: sessiondata.data[0].facilityName,
          },
          clientid: {
            id: sessiondata.data[0].clientId,
            firstName: sessiondata.data[0].clientFirstName,
            lastName: sessiondata.data[0].clientLastName,
          },
          staffid: {
            id: sessiondata.data[0].staffId,
            firstName: sessiondata.data[0].staffFirstName,
            lastName: sessiondata.data[0].staffLastName,
          },
          supervisorid: {
            id: sessiondata.data[0].supervisorId,
            name:
              sessiondata.data[0].supervisorFirstName +
              " " +
              sessiondata.data[0].supervisorLastName,
          },
          insurancename: sessiondata.data[0].insuranceName,
          insurancepolicynumber: sessiondata.data[0].insurancePolicyNumber,

          parentorgurdianname: sessiondata.data[0].parentOrGurdianName,

          sessiontype: sessiondata.data[0].sessionType,

          sessiondate: dayjs(sessiondata.data[0].sessionDate),
          placeofservicecode: sessiondata.data[0].placeOfServiceCode,
          otherplaceofservice: sessiondata.data[0].otherPlaceOfService,
          modifier: sessiondata.data[0].modifier,
          procedurecode: sessiondata.data[0].procedureCode,

          diagnosiscode1: sessiondata.data[0].diagnosisCode1,

          otherdiagnosiscode: sessiondata.data[0].otherDiagnosisCode,
          primarytargetareasaddressed:
            sessiondata.data[0].primaryTargetAreasAddressed,
          reinforcerusedduringsession:
            sessiondata.data[0].reinforcerUsedDuringSession,
          maladaptivebehaviors: sessiondata.data[0].malAdaptiveBehaviors,
          sessionid: sessiondata.data[0].sessionId,

          clientmoodresponse: sessiondata.data[0].clientMoodResponse,
          sessionsummary: sessiondata.data[0].sessionSummary,
          planfornextvisit: sessiondata.data[0].planForNextVisit,
          sessionstatus: sessiondata.data[0].sessionStatus,

          staffsignaturedate:
            sessiondata.data[0].staffSignatureDate == null
              ? null
              : dayjs(sessiondata.data[0].staffSignatureDate),
          supervisorsignaturedate:
            sessiondata.data[0].supervisorSignatureDate == null
              ? null
              : dayjs(sessiondata.data[0].supervisorSignatureDate),
          parentorgurdiansignaturedate:
            sessiondata.data[0].parentOrGurdianSignatureDate == null
              ? null
              : dayjs(sessiondata.data[0].parentOrGurdianSignatureDate),
        };
        if (sessiondata.data[0].parentOrGurdianSignatureFileName) {
          const dataURLParent = `data:image/png;base64,${sessiondata.data[0].parentOrGurdianSignatureFileName}`;
          payload.parentImage = dataURLParent;
        }
        if (sessiondata.data[0].staffSignatureFileName) {
          const dataURLStaff = `data:image/png;base64,${sessiondata.data[0].staffSignatureFileName}`;
          payload.staffImage = dataURLStaff;
        }
        if (sessiondata.data[0].supervisorSignatureFileName) {
          const dataURLsupervisor = `data:image/png;base64,${sessiondata.data[0].supervisorSignatureFileName}`;
          payload.supervisorImage = dataURLsupervisor;
          setSignatureAlreadyPresent(true);
        } else {
          try {
            const approval = await checkApprover(sessiondata.data[0].staffId);
            console.log("app", approval);
            setCanApprove(approval);
          } catch (error) {
            console.error("Error checking approver:", error);
            setCanApprove(false);
          }
        }

        // Create a data URL. I'm assuming it's a PNG image here, but adjust the MIME type if it's different.

        const convert = (time12Hour) => {
          const [hour, minutePart] = time12Hour.split(":");
          const [minute, period] = minutePart.split(" ");

          let hour24 = parseInt(hour, 10);
          if (period === "PM" && hour24 !== 12) {
            hour24 += 12;
          } else if (period === "AM" && hour24 === 12) {
            hour24 = 0;
          }

          return `${String(hour24).padStart(2, "0")}:${minute}`;
        };

        const start = convert(sessiondata.data[0].sessionStartTime);
        const end = convert(sessiondata.data[0].sessionEndTime);

        // Construct the full datetime string without specifying a time zone
        const initialStartTime = moment(
          `Mon Oct 30 2023 ${start}:00`,
          "ddd MMM DD YYYY HH:mm:ss"
        );
        const initialEndTime = moment(
          `Mon Oct 30 2023 ${end}:00`,
          "ddd MMM DD YYYY HH:mm:ss"
        );

        console.log(initialStartTime.format("ddd MMM DD YYYY HH:mm:ss")); // Outputs the date and start time as it is
        console.log(initialEndTime.format("ddd MMM DD YYYY HH:mm:ss")); // Outputs the date and end time as it is

        payload.sessiontime = [initialStartTime, initialEndTime];

        sessiondata.data[0].skillStrategiesApplied?.forEach((element) => {
          const lowerCaseElement = element.toLowerCase().trim();
          console.log(lowerCaseElement);
          if (lowerCaseElement === "role playing or modeling") {
            payload.roleplaying = true;
            console.log("fdsff");
          }
          if (lowerCaseElement === "discrete trial training") {
            payload.discretetrialtraining = true;
          }
          if (lowerCaseElement === "behavior momentum") {
            payload.behaviourmomentum = true;
          }
          if (lowerCaseElement === "generalization training") {
            payload.generalizationtraining = true;
          }
          if (lowerCaseElement === "shaping/chaining") {
            payload.shapingchaining = true;
          }
          if (lowerCaseElement === "natural environment teaching") {
            payload.naturalenvironmentteaching = true;
          }
          if (lowerCaseElement === "providing support with prompt fading") {
            payload.providingsupport = true;
          }
          if (lowerCaseElement === "n/a") {
            payload.na = true;
          }
          if (lowerCaseElement === "other") {
            payload.otherskill = true;
            payload.otherskillstrategiesapplied =
              sessiondata.data[0].otherSkillStrategiesApplied;
          }
        });

        sessiondata.data[0].sessionParticipants?.forEach((element) => {
          const lowerCaseElement = element.toLowerCase();
          console.log(lowerCaseElement);
          if (lowerCaseElement === "child") {
            payload.child = true;
          }
          if (lowerCaseElement === "parent") {
            payload.parent = true;
          }
          if (lowerCaseElement === "bcba") {
            payload.bcba = true;
          }
          if (lowerCaseElement === "behaviour technician") {
            payload.behaviourtechnician = true;
          }
          if (lowerCaseElement === "other") {
            payload.other = true;
            payload.othersessionparticipants =
              sessiondata.data[0].otherSessionParticipants;
          }
        });

        sessiondata.data[0].rbtWorkStyle?.forEach((element) => {
          const lowerCaseElement = element.toLowerCase();
          console.log(lowerCaseElement);
          if (lowerCaseElement === "rbt/bt worked on goals in treatment plan") {
            console.log("hey");
            payload.optiona = true;
          }
          if (
            lowerCaseElement === "rbt/bt worked on pairing with reinforcers"
          ) {
            payload.optionb = true;
          }
          if (lowerCaseElement === "other") {
            console.log("hu");
            payload.otherrbt = true;
            payload.otherrbtworkstyle = sessiondata.data[0].otherRbtWorkStyle;
          }
        });

        sessiondata.data[0].behaviorStrategiesApplied?.forEach((element) => {
          const lowerCaseElement = element.toLowerCase();
          if (lowerCaseElement === "differential reinforcement") {
            payload.differntialreinforcement = true;
          }
          if (lowerCaseElement === "verbal reminders provided") {
            payload.verbalremindersprovided = true;
          }
          if (
            lowerCaseElement ===
            "visual supports (timer, schedule, token board etc.)"
          ) {
            payload.visualsupports = true;
          }
          if (lowerCaseElement === "first/then statements") {
            payload.firstthenstatements = true;
          }
          if (lowerCaseElement === "modeling") {
            payload.modeling = true;
          }
          if (lowerCaseElement === "guided compliance") {
            payload.guidedcompliance = true;
          }
          console.log(lowerCaseElement);
          if (lowerCaseElement === "contingent rewards/reinforcers") {
            payload.contigentrewards = true;
          }
          if (lowerCaseElement === "other") {
            payload.otherbehavior = true;
            payload.otherbehaviorstrategiesapplied =
              sessiondata.data[0].otherBehaviorStrategiesApplied;
          }
          if (lowerCaseElement === "n/a") {
            payload.nabehavior = true;
          }
        });

        setVals(payload);

        setSupervisor(supervisorResponse.data);
        setFacilities(facilitiesResponse.data);

        setIsloading(false);
        console.log(payload.parentImage);
      } catch (error) {
        console.error("Error fetching data:", error);

        if (retryCount < maxRetries) {
          console.log(`Retrying (${retryCount + 1}/${maxRetries})...`);
          setTimeout(() => fetchData(retryCount + 1), retryDelay);
        } else {
          console.error("Max retries reached. Aborting fetch.");
          openSnackbar(
            "Failed to fetch data after multiple attempts.",
            "error"
          );
        }
      }
    }

    fetchData();
  }, [isloading]);
  const handleData = (formData) => {
    console.log(formData, "submitted");
    const formattedSessionDate = dayjs(formData.sessiondate).format(
      "YYYY-MM-DD"
    );

    var today = new Date();
    var currdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1 < 10
        ? "0" + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      "-" +
      (today.getDate() < 10 ? "0" + today.getDate() : today.getDate());

    console.log(currdate);

    const { sessiontime, ...otherValues } = formData;
    const payload = {
      id: sessionId,
      lastupdatedby: localStorage.getItem("username"),
      parentsignatureexist: formData.parentsignatureexist,
      staffsignatureexist: formData.staffsignatureexist,
      supervisorsignatureexist: formData.supervisorsignatureexist,
      facilityid: formData.facilityid,

      clientid: formData.clientid,

      staffid: formData.staffid,

      supervisorid: formData.supervisorid,

      insurancename: formData.insurancename,
      policynumber: formData.insurancepolicynumber,
      parentorgurdianname: formData.parentorgurdianname,
      sessiontype: formData.sessiontype,
      placeofservicecode: formData.placeofservicecode,
      otherplaceofservice:
        formData.placeofservicecode === "Other"
          ? formData.otherplaceofservice
          : "",
      modifier: formData.modifier,
      procedurecode: formData.procedurecode,
      diagnosiscode1: formData.diagnosiscode1,
      clientmoodresponse: formData.clientmoodresponse,
      sessionsummary: formData.sessionsummary,
      planfornextvisit: formData.planfornextvisit,
      primarytargetareasaddressed: formData.primarytargetareasaddressed,
      reinforcerusedduringsession: formData.reinforcerusedduringsession,
      maladaptivebehaviors: formData.maladaptivebehaviors,
      otherdiagnosiscode:
        formData.diagnosiscode1 === "Other" ? formData.otherdiagnosiscode : "",

      otherbehaviorstrategiesapplied: formData.otherbehaviorstrategiesapplied
        ? formData.otherbehaviorstrategiesapplied
        : "",
      othersessionparticipants: formData.othersessionparticipants
        ? formData.othersessionparticipants
        : "",
      otherskillstrategiesapplied: formData.otherskillstrategiesapplied
        ? formData.otherskillstrategiesapplied
        : "",
      otherrbtworkstyle: formData.otherrbtworkstyle
        ? formData.otherrbtworkstyle
        : "",

      sessiondate: formattedSessionDate,

      sessionendtime: formData.sessiontime[1].format("HH:mm:a"),
      sessionstarttime: formData.sessiontime[0].format("HH:mm:a"),

      sessionparticipants:
        (formData.child ? "Child;" : "") +
        (formData.parent ? "Parent;" : "") +
        (formData.bcba ? "BCBA;" : "") +
        (formData.behaviourtechnician ? "Behaviour Technician;" : "") +
        (formData.other ? "Other;" : ""),

      rbtworkstyle:
        (formData.optiona ? "RBT/BT worked on goals in treatment plan;" : "") +
        (formData.optionb ? "RBT/BT worked on pairing with reinforcers;" : "") +
        (formData.otherrbt ? "Other;" : ""),

      skillstrategiesapplied:
        (formData.roleplaying ? "Role Playing Or Modeling;" : "") +
        (formData.discretetrialtraining ? "Discrete Trial Training;" : "") +
        (formData.behaviourmomentum ? "Behavior Momentum;" : "") +
        (formData.generalizationtraining ? "Generalization Training;" : "") +
        (formData.shapingchaining ? "Shaping/Chaining;" : "") +
        (formData.naturalenvironmentteaching
          ? "Natural Environment Teaching;"
          : "") +
        (formData.providingsupport
          ? "Providing Support With Prompt Fading;"
          : "") +
        (formData.na ? "N/A;" : "") +
        (formData.otherskill ? "Other;" : ""),

      behaviorstrategiesapplied:
        (formData.differntialreinforcement
          ? "Differential Reinforcement;"
          : "") +
        (formData.verbalremindersprovided ? "Verbal Reminders Provided;" : "") +
        (formData.visualsupports
          ? "Visual supports (timer, schedule, token board etc.);"
          : "") +
        (formData.firstthenstatements ? "First/Then Statements;" : "") +
        (formData.modeling ? "Modeling;" : "") +
        (formData.guidedcompliance ? "Guided Compliance;" : "") +
        (formData.contigentrewards ? "Contingent Rewards/Reinforcers;" : "") +
        (formData.otherbehavior ? "Other;" : "") +
        (formData.nabehavior ? "N/A;" : ""),
    };

    if (formData.otherbehavior && !formData.otherbehaviorstrategiesapplied) {
      console.log("form", formData.otherbehavior);
      openSnackbar("Please fill in the other behavior strategies", "error");
      return null;
    }
    if (formData.other && !formData.othersessionparticipants) {
      openSnackbar("Please fill in the other session participants", "error");
      return null;
    }
    if (formData.otherskill && !formData.otherskillstrategiesapplied) {
      openSnackbar("Please fill in the other skill strategies", "error");
      return null;
    }
    if (formData.otherrbt && !formData.otherrbtworkstyle) {
      openSnackbar("Please fill in the other rbt work style", "error");
      return null;
    }

    // Processing signatures
    if (!formData.staffImage) {
      if (!sigStaff.current.isEmpty()) {
        payload.staffsignaturefilename = sigStaff.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        payload.staffsignaturedate = currdate;
        payload.staffsignatureexist = false;
      }
    }
    if (!formData.parentImage) {
      if (!sigParent.current.isEmpty()) {
        payload.parentorgurdiansignaturefilename = sigParent.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        payload.parentorgurdiansignaturedate = currdate;
        payload.parentsignatureexist = false;
      }
    }
    if (!formData.supervisorImage) {
      if (canApprove && !sigSupervisor.current.isEmpty()) {
        payload.supervisorsignaturefilename = sigSupervisor.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        payload.supervisorsignaturedate = dayjs(
          formData.supervisorsignaturedate
        ).format("YYYY-MM-DD");
        payload.supervisorSignatureExist = false;
      }
    }
    console.log("session", payload.sessionparticipants, "ok");

    if (
      !payload.sessionparticipants ||
      payload.sessionparticipants.trim() === ""
    ) {
      openSnackbar("Please fill in the session participants", "error");
      return null;
    }
    if (payload.rbtworkstyle == "") {
      openSnackbar("Please fill in the RBT work style", "error");
      return null;
    }
    if (payload.skillstrategiesapplied == "") {
      openSnackbar("Please fill in the skill strategies applied", "error");
      return null;
    }
    if (payload.behaviorstrategiesapplied == "") {
      openSnackbar("Please fill in the behavior strategies applied", "error");
      return null;
    }
    if (
      formData.placeofservicecode == "Other" &&
      formData.otherplaceofservice == ""
    ) {
      openSnackbar("Please fill in the other place of service", "error");
      return null;
    }
    if (
      formData.diagnosiscode1 == "Other" &&
      formData.otherdiagnosiscode == ""
    ) {
      openSnackbar("Please fill in the other diagnosis code", "error");
      return null;
    }

    console.log("payload", payload);
    return payload;
  };
  const handleErrors = (formData) => {
    if (!formData.sessiondate) {
      openSnackbar("Please fill in the session date", "error");
      return true;
    }
    if (!formData.sessiontype) {
      openSnackbar("Please fill in the session type", "error");
      return true;
    }
    if (!formData.facilityid) {
      openSnackbar("Please fill in the facility name", "error");
      return true;
    }
    if (!formData.clientid) {
      openSnackbar("Please fill in the client name", "error");
      return true;
    }
    if (!formData.staffid) {
      openSnackbar("Please fill in the staff name", "error");
      return true;
    }
    if (!formData.supervisorid) {
      openSnackbar("Please fill in the supervisor name", "error");
      return true;
    }
    if (!formData.parentorgurdianname) {
      openSnackbar("Please fill in the parent or guardian name", "error");
      return true;
    }
    if (!formData.placeofservicecode) {
      openSnackbar("Please fill in the place of service", "error");
      return true;
    }
    if (!formData.modifier) {
      openSnackbar("Please fill in the modifier", "error");
      return true;
    }
    if (!formData.procedurecode) {
      openSnackbar("Please fill in the procedure code", "error");
      return true;
    }
    if (!formData.diagnosiscode1) {
      openSnackbar("Please fill in the diagnosis code", "error");
      return true;
    }
    if (
      !formData.sessiontime ||
      !formData.sessiontime[0] ||
      !formData.sessiontime[1] ||
      formData.sessiontime[0].format("HH:mm:a") === "Invalid Date" ||
      formData.sessiontime[1].format("HH:mm:a") === "Invalid Date" ||
      formData.sessiontime[0].format("HH:mm:a") >
        formData.sessiontime[1].format("HH:mm:a")
    ) {
      openSnackbar("Please fill in the session time", "error");
      return true;
    }
    if (
      formData.placeofservicecode === "Other" &&
      !formData.otherplaceofservice
    ) {
      openSnackbar("Please fill in the other place of service", "error");
      return true;
    }
    if (
      formData.sessiontime[0].format("HH:mm:a") === "Invalid Date" ||
      formData.sessiontime[1].format("HH:mm:a") === "Invalid Date"
    ) {
      openSnackbar("Please fill session time correctly", "error");
      return true;
    }
    if (formData.sessionsummary === "") {
      openSnackbar("Please fill in the session summary", "error");
      return true;
    }
    if (formData.reinforcerusedduringsession === "") {
      openSnackbar(
        "Please fill in the reinforcer used during session",
        "error"
      );
      return true;
    }
    if (formData.primarytargetareasaddressed === "") {
      openSnackbar(
        "Please fill in the primary target areas addressed",
        "error"
      );
      return true;
    }
    if (formData.maladaptivebehaviors === "") {
      openSnackbar("Please fill in the maladaptive behaviors", "error");
      return true;
    }
    if (formData.clientmoodresponse === "") {
      openSnackbar("Please fill in the client mood response", "error");
      return true;
    }
    if (formData.planfornextvisit === "") {
      openSnackbar("Please fill in the plan for next visit", "error");
      return true;
    }
    if (
      canApprove &&
      !formData.supervisorImage &&
      !sigSupervisor.current.isEmpty() &&
      formData.supervisorsignaturedate == null
    ) {
      openSnackbar("Please fill in the supervisor signature date", "error");
      return true;
    }

    console.log("insdf");

    return false;
  };
  const handleSaveAsDraft = (vals) => {
    if (handleErrors(vals)) {
      return;
    }
    const payload = handleData(vals);
    if (payload == null) return;
    console.log("errorno", payload);
    payload.sessionstatus = "Draft";
    console.log(payload);
    handleFormSubmit(payload);
  };
  const handleSaveAsReview = (vals) => {
    if (
      (!vals.parentImage && sigParent.current.isEmpty()) ||
      (!vals.staffImage && sigStaff.current.isEmpty())
    ) {
      openSnackbar("Please fill in the parent and staff signature", "error");
      return;
    }

    if (handleErrors(vals)) {
      return;
    }
    const payload = handleData(vals);
    if (payload == null) return;
    payload.sessionstatus = "Pending Review";
    console.log(payload);
    handleFormSubmit(payload);
  };

  const handleApprove = (vals) => {
    if (
      (!vals.parentImage && sigParent.current.isEmpty()) ||
      (!vals.staffImage && sigStaff.current.isEmpty()) ||
      (canApprove && !vals.supervisorImage && sigSupervisor.current.isEmpty())
    ) {
      openSnackbar("Please fill in all the signatures", "error");
      return;
    }

    if (handleErrors(vals)) {
      return;
    }

    const payload = handleData(vals);
    if (payload == null) return;
    console.log(payload);
    payload.sessionstatus = "Approved";

    handleFormSubmit(payload);
  };

  const handleFormSubmit = (payload) => {
    openSnackbar("Saving...", "info");
    const data = {
      ...payload,
      staffid: payload.staffid.id,
      supervisorid: payload.supervisorid.id,
      facilityid: payload.facilityid.id,
      clientid: payload.clientid.id,
    };
    console.log("payload data", data);
    try {
      axiosInstance
        .post(sharedLink + "/updatesessionnote", data)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            openSnackbar("Session note updated successfully", "success");
          } else {
            openSnackbar("Failed to update session note", "error");
          }
        })
        .catch((err) => {
          openSnackbar(err, "error");
        });
    } catch (err) {
      openSnackbar(err, "error");

      console.log(err);
    }
  };
  if (isloading) {
    return <LoadingIndicator />;
  } else {
    return (
      <Box m="20px">
        <Header title=" Session Note (97153)" subtitle="" />
        <br />
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={vals}
          validationSchema={checkoutSchema}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            submitForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={facilities}
                    getOptionLabel={(option) => `${option.facilityName} `}
                    autoHighlight
                    value={values.facilityid || null}
                    disabled={true}
                    onChange={(event, newValue) => {
                      try {
                        setFieldValue("facilityid", newValue ? newValue : "");
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={clients}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} `
                    }
                    autoHighlight
                    value={values.clientid || null}
                    disabled={true}
                    onChange={(event, newValue) => {
                      try {
                        setFieldValue("clientid", newValue ? newValue : "");
                        setFieldValue(
                          "insurancename",
                          newValue ? newValue.insuranceName : ""
                        );
                        setFieldValue(
                          "insurancepolicynumber",
                          newValue ? newValue.policyNumber : ""
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={staffs}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} `
                    }
                    autoHighlight
                    value={values.staffid || null}
                    disabled={true}
                    onChange={async (event, newValue) => {
                      // Handle the change
                      if (newValue) {
                        try {
                          const approval = await checkApprover(newValue.id);
                          console.log("app", approval);
                          setCanApprove(approval);
                        } catch (error) {
                          console.error("Error checking approver:", error);
                          setCanApprove(false);
                        }
                      } else {
                        setCanApprove(false);
                      }
                      try {
                        setFieldValue("staffid", newValue ? newValue : "");
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Staff Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={supervisor}
                    getOptionLabel={(option) =>
                      ` ${option.name}` +
                      (option.role ? `[${option.role}]` : "")
                    }
                    autoHighlight
                    value={values.supervisorid || null}
                    disabled={true}
                    onChange={(event, newValue) => {
                      try {
                        setFieldValue("supervisorid", newValue ? newValue : "");
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supervisor Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Insurance Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={values.insurancename}
                  name="insurancename"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Insurance Policy Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={values.insurancepolicynumber}
                  name="insurancepolicynumber"
                  error={
                    !!touched.insurancepolicynumber &&
                    !!errors.insurancepolicynumber
                  }
                  helperText={
                    touched.insurancepolicynumber &&
                    errors.insurancepolicynumber
                  }
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  autoHighlight
                  options={parentOrGurdian} // Array of parent or guardian names
                  getOptionLabel={(option) => option} // Assuming each option has a 'name' property
                  value={values.parentorgurdianname || ""}
                  disabled={true}
                  onChange={(event, newValue) => {
                    setFieldValue(
                      "parentorgurdianname",
                      newValue ? newValue : ""
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent or Guardian"
                      variant="filled"
                      className="animated-input"
                      required
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={sessionType}
                    autoHighlight
                    getOptionLabel={(option) => option} // Since options are strings
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Session Type"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Session Type",
                        }}
                      />
                    )}
                    disabled={true}
                    value={values.sessiontype || null} // Fallback to null if no value is set
                    onChange={(event, newValue) => {
                      try {
                        setFieldValue("sessiontype", newValue);
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Session Date"
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    name="sessiondate"
                    required
                    value={values.sessiondate}
                    disabled={true}
                    onChange={(newValue) => {
                      handleChange({
                        target: {
                          name: "sessiondate",
                          value: newValue,
                        },
                      });
                    }}
                    onBlur={handleBlur}
                    shouldDisableDate={disableFutureDates}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MultiInputTimeRangeField
                    name="sessiontime"
                    fullWidth
                    value={values.sessiontime}
                    onChange={(newValue) => {
                      console.log(newValue);
                      handleChange({
                        target: {
                          name: "sessiontime",
                          value: newValue,
                        },
                      });
                    }}
                    disabled={true}
                    slotProps={{
                      textField: ({ position }) => ({
                        label:
                          position === "start"
                            ? "Session start time"
                            : "Session end time",
                      }),
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </LocalizationProvider>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={placeOfService} // Assuming 'placeOfService' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option.toString()} // Assuming 'option' is a string or object that can be converted to string
                    disabled={true}
                    value={values.placeofservicecode}
                    onChange={(event, newValue) => {
                      console.log(newValue);
                      // Create a synthetic event to pass to handleChange
                      if (placeOfService.includes(newValue)) {
                        // Update only if newValue is in the options
                        handleChange({
                          target: {
                            name: "placeofservicecode",
                            value: newValue,
                          },
                        });
                      } else {
                        // Optionally, clear the field if the value is not in the options
                        handleChange({
                          target: {
                            name: "placeofservicecode",
                            value: "",
                          },
                        });
                      }
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Place of service"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a place of service",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Other Place of Service (Maxlength: 30)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={values.placeofservicecode === "Other"}
                  value={values.otherplaceofservice}
                  name="otherplaceofservice"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 30,
                  }}
                  disabled={true}
                />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    disabled={true}
                    fullWidth
                    options={moDifier} // Assuming 'moDifier' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.modifier || ""} // Fallback to an empty string if values.modifier is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "modifier",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Modifier"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a modifier",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option} value={option}>
                        {option}
                      </MenuItem>
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={procedureCode} // Assuming 'procedureCode' is the array of options
                    autoHighlight
                    disabled={true}
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.procedurecode || ""} // Fallback to an empty string if values.procedurecode is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "procedurecode",
                          value: newValue || null, // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Procedure Code"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a procedure code",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option} value={option}>
                        {option}
                      </MenuItem>
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={diagnosisCode} // Assuming 'diagnosisCode' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.diagnosiscode1 || ""} // Fallback to an empty string if values.diagnosiscode1 is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "diagnosiscode1",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Diagnosis Code 1"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a diagnosis code",
                        }}
                      />
                    )}
                    disabled={true}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>
                <TextField
                  disabled={true}
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Other Diagnosis Code (Maxlength: 30)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="otherdiagnosiscode"
                  value={values.otherdiagnosiscode}
                  required={values.diagnosiscode1 === "Other"}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Session Participant</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.child}
                        disabled={isDisabled}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "child",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="child"
                        value="Child"
                      />
                    }
                    label="Child"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.parent}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "parent",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="parent"
                        value="Parent"
                      />
                    }
                    label="Parent"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.bcba}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "bcba",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="bcba"
                        value="BCBA"
                      />
                    }
                    label="BCBA"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.behaviourtechnician}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "behaviourtechnician",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="behaviourtechnician"
                        value="Behaviour Technician"
                      />
                    }
                    label="Behaviour Technician"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.other}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "other",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="other"
                        value="Other"
                      />
                    }
                    label="Other"
                  />
                  {values.other && (
                    <TextareaAutosize
                      fullWidth
                      disabled={isDisabled}
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here…  (Maxlength: 30)"
                      name="othersessionparticipants"
                      value={values.othersessionparticipants}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={2}
                      required={values.other}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>

                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Work Style</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.optiona}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "optiona",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="optiona"
                        value="RBT/BT worked on goals in the treatment plan"
                      />
                    }
                    label="RBT/BT worked on goals in the treatment plan"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.optionb}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "optionb",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="optionb"
                        value="RBT/BT worked on pairing with reinforcers"
                      />
                    }
                    label="RBT/BT worked on pairing with reinforcers"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.otherrbt}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "otherrbt",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="otherrbt"
                        value="Other"
                      />
                    }
                    label="Other"
                  />
                  {values.otherrbt && (
                    <TextareaAutosize
                      fullWidth
                      disabled={isDisabled}
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="otherrbtworkstyle"
                      value={values.otherrbtworkstyle}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={3}
                      required={values.otherrbt}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>

                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Skill Strategies</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.roleplaying}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "roleplaying",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="roleplaying"
                        value="Role playing or modeling"
                      />
                    }
                    label="Role playing or modeling"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.discretetrialtraining}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "discretetrialtraining",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="discretetrialtraining"
                        value="Discrete trial training"
                      />
                    }
                    label="Discrete trial training"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.behaviourmomentum}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "behaviourmomentum",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="behaviourmomentum"
                        value="Behavior Momentum"
                      />
                    }
                    label="Behaviour momentum"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.generalizationtraining}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "generalizationtraining",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="generalizationtraining"
                        value="Generalization training"
                      />
                    }
                    label="Generalization training"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.shapingchaining}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "shapingchaining",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="shapingchaining"
                        value="Shaping/Chaining"
                      />
                    }
                    label="Shaping/Chaining"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.naturalenvironmentteaching}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "naturalenvironmentteaching",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="naturalenvironmentteaching"
                        value="Natural environment teaching"
                      />
                    }
                    label="Natural environment teaching"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.providingsupport}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "providingsupport",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="providingsupport"
                        value="Providing support with prompt fading"
                      />
                    }
                    label="Providing support with prompt fading"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.na}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "na",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="na"
                        value="N/A"
                      />
                    }
                    label="N/A"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.otherskill}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "otherskill",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="otherskill"
                        value="Other"
                      />
                    }
                    label="Others"
                  />
                  {values.otherskill && (
                    <TextareaAutosize
                      fullWidth
                      disabled={isDisabled}
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="otherskillstrategiesapplied"
                      value={values.otherskillstrategiesapplied}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={4}
                      required={values.otherskill}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>

                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Behavioral Strategies
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.modeling}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "modeling",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="modeling"
                        value="Modeling"
                      />
                    }
                    label="Modeling"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.guidedcompliance}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "guidedcompliance",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="guidedcompliance"
                        value="Guided Compliance"
                      />
                    }
                    label="Guided Compliance"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.differntialreinforcement}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "differntialreinforcement",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="differntialreinforcement"
                        value="Differential Reinforcement"
                      />
                    }
                    label="Differential Reinforcement"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.verbalremindersprovided}
                        name="verbalremindersprovided"
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "verbalremindersprovided",
                              value: event.target.checked,
                            },
                          })
                        }
                        value="Verbal reminders provided"
                      />
                    }
                    label="Verbal reminders provided"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.firstthenstatements}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "firstthenstatements",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="firstthenstatements"
                        value="First/Then statements"
                      />
                    }
                    label="First/Then statements"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.contigentrewards}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "contigentrewards",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="contigentrewards"
                        value="Contingent rewards/reinforcer"
                      />
                    }
                    label="Contingent rewards/reinforcer"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.visualsupports}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "visualsupports",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="visualsupports"
                        value="Visual supports (timer, schedule, token board etc.)"
                      />
                    }
                    label="Visual supports (timer, schedule, token board etc.)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.nabehavior}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "nabehavior",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="nabehavior"
                        value="N/A"
                      />
                    }
                    label="N/A"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={values.otherbehavior}
                        onChange={(event) =>
                          handleChange({
                            target: {
                              name: "otherbehavior",
                              value: event.target.checked,
                            },
                          })
                        }
                        name="otherbehavior"
                        value="Other"
                      />
                    }
                    label="Others"
                  />

                  {values.otherbehavior && (
                    <TextareaAutosize
                      fullWidth
                      variant="filled"
                      disabled={isDisabled}
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="otherbehaviorstrategiesapplied"
                      value={values.otherbehaviorstrategiesapplied}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={4}
                      required={values.otherbehavior ? true : false}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  )}
                </FormGroup>
                <TextField
                  fullWidth
                  required
                  variant="filled"
                  disabled={isDisabled}
                  className="animated-input"
                  label="Reinforcer Used During Session (Maxlength: 1000)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.reinforcerusedduringsession}
                  name="reinforcerusedduringsession"
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                  rows={5}
                  inputProps={{ maxLength: 1000 }}
                  multiline
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={isDisabled}
                  className="animated-input"
                  label="Primary Target Areas Addressed (Maxlength: 1000)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.primarytargetareasaddressed}
                  name="primarytargetareasaddressed"
                  multiline
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                  rows={5}
                  inputProps={{ maxLength: 1000 }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={isDisabled}
                  className="animated-input"
                  label="Describe Maladaptive Behaviour (Maxlength: 1000)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  value={values.maladaptivebehaviors}
                  name="maladaptivebehaviors"
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                  inputProps={{ maxLength: 1000 }}
                  rows={5}
                  multiline
                  // inputProps={{ maxLength: 50 }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={isDisabled}
                  className="animated-input"
                  label="Session Summary (Maxlength: 2500)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.sessionsummary}
                  name="sessionsummary"
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                  rows={5}
                  multiline
                  inputProps={{ maxLength: 2500 }}
                  required
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={isDisabled}
                  className="animated-input"
                  label="Client Mood Response (Maxlength: 1000)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.clientmoodresponse}
                  required
                  name="clientmoodresponse"
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                  rows={5}
                  multiline
                  inputProps={{ maxLength: 1000 }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  disabled={isDisabled}
                  className="animated-input"
                  label="Plan for Next Visit (Maxlength: 1000)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.planfornextvisit}
                  name="planfornextvisit"
                  sx={{
                    gridColumn: "span 4",
                    ...themeStyles.textField,
                    ...themeStyles.textField,
                  }}
                  rows={5}
                  required
                  multiline
                  inputProps={{ maxLength: 1000 }}
                />
                {/* {(localStorage.getItem("role") === "supervisor" ||
                  localStorage.getItem("role") === "admin") &&
                  signatureAlreadyPresent && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Supervisor Signature Date"
                        sx={{ gridColumn: "span 2",...themeStyles.textField }}
                        fullWidth
                        variant="filled"
                        className="animated-input"
                        name="supervisorsignaturedate"
                        value={values.supervisorsignaturedate}
                        disabled
                        onBlur={handleBlur}
                      />
                    </LocalizationProvider>
                  )}

         
               
                <FormGroup sx={{ gridColumn: "span 2",...themeStyles.textField }}></FormGroup> */}
                {values.staffImage ? (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Staff Signature Date"
                        sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                        fullWidth
                        variant="filled"
                        disabled
                        className="animated-input"
                        name="staffsignaturedate"
                        value={values.staffsignaturedate}
                        onBlur={handleBlur}
                      />
                    </LocalizationProvider>
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        Staff's signature
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <img
                            name="staffImage"
                            src={values.staffImage}
                            alt="Staff Image"
                            height="100"
                            width="150"
                          />
                        }
                      />
                    </FormGroup>
                  </>
                ) : (
                  !isDisabled && (
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        Staff's signature
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <SignaturePad
                            ref={sigStaff}
                            disabled={isDisabled}
                            canvasProps={{
                              height: 200,
                              className: "sigCanvas",
                            }}
                          />
                        }
                      />
                      <FormControlLabel
                        control={
                          <div>
                            <Button
                              onClick={clearStaffSignature}
                              variant="contained"
                              color="primary"
                              sx={{ gridColumn: "span 1", ...buttonStyle }}>
                              Clear
                            </Button>
                          </div>
                        }
                      />
                    </FormGroup>
                  )
                )}
                {values.parentImage ? (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Parent or Guardian Signature Date"
                        sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                        fullWidth
                        variant="filled"
                        className="animated-input"
                        name="parentorgurdiansignaturedate"
                        value={values.parentorgurdiansignaturedate}
                        disabled
                        onBlur={handleBlur}
                      />
                    </LocalizationProvider>
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        Parent's signature
                      </FormLabel>

                      <FormControlLabel
                        control={
                          <img
                            name="parentImage"
                            src={values.parentImage}
                            alt="Bytecode Image"
                            height="100"
                            width="150"
                          />
                        }
                      />
                    </FormGroup>
                  </>
                ) : (
                  !isDisabled && (
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        Parent's signature
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <SignaturePad
                            disabled={isDisabled}
                            ref={sigParent}
                            canvasProps={{
                              height: 200,
                              className: "sigCanvas",
                            }}
                          />
                        }
                      />

                      <FormControlLabel
                        control={
                          <div>
                            <Button
                              onClick={clearParentSignature}
                              // fullWidth
                              variant="contained"
                              color="primary"
                              sx={{ gridColumn: "span 1", ...buttonStyle }}>
                              Clear
                            </Button>
                          </div>
                        }
                      />
                    </FormGroup>
                  )
                )}
                {values.supervisorImage ? (
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Supervisor Signature Date"
                        sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                        fullWidth
                        variant="filled"
                        className="animated-input"
                        name="supervisorsignaturedate"
                        value={values.supervisorsignaturedate}
                        disabled
                        onBlur={handleBlur}
                      />
                    </LocalizationProvider>
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        supervisor's signature
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <img
                            name="supervisorImage"
                            src={values.supervisorImage}
                            alt="Bytecode Image"
                            height="100"
                            width="150"
                          />
                        }
                      />
                    </FormGroup>
                  </>
                ) : (
                  !isDisabled &&
                  canApprove && (
                    <>
                      <FormGroup
                        sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                        <FormLabel component="legend">
                          supervisor's signature
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <SignaturePad
                              ref={sigSupervisor}
                              onEnd={handleSupervisorSignature}
                              canvasProps={{
                                height: 200,
                                className: "sigCanvas",
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          control={
                            <div>
                              <Button
                                onClick={clearSupervisorSignature}
                                // fullWidth
                                //  fullWidth
                                variant="contained"
                                color="primary"
                                margin="10px"
                                sx={{ gridColumn: "span 1", ...buttonStyle }}>
                                Clear
                              </Button>
                            </div>
                          }
                        />
                      </FormGroup>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Supervisor Signature Date"
                          sx={{
                            gridColumn: "span 2",
                            ...themeStyles.textField,
                          }}
                          fullWidth
                          variant="filled"
                          className="animated-input"
                          name="supervisorsignaturedate"
                          value={values.supervisorsignaturedate}
                          onChange={(newValue) => {
                            handleChange({
                              target: {
                                name: "supervisorsignaturedate",
                                value: newValue,
                              },
                            });
                          }}
                          slotProps={{
                            textField: {
                              required: isSignaturePresent, // Dynamic required attribute

                              // Dynamic disabled attribute
                            },
                          }}
                          disabled={!isSignaturePresent || isDisabled}
                          onBlur={handleBlur}
                          //shouldDisableDate={disableFutureDates}
                        />
                      </LocalizationProvider>
                    </>
                  )
                )}
              </Box>

              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  onClick={() => {
                    console.log(from);
                    // When navigating to the "viewSessionNotes" route
                    // from === "dashboard"
                    //   ? navigate("/dashboard")
                    //   : navigate("/viewSessionNotes", {
                    //       state: { from: "viewsessionnote" },
                    //     });
                    // navigate("/viewSessionNotes", {
                    //   state: { from: "viewsessionnote" },
                    // });
                    const stateToSave = {
                      formValues: previouspagevalues?.formValues,
                      rows: previouspagevalues?.rows,
                      staffs: previouspagevalues?.staffs,
                      clients: previouspagevalues?.clients,
                    };
                    console.log(from + "from");
                    console.log(stateToSave, "edit");
                    navigate(from ? "/" + from : -1, {
                      state: { previouspagevalues: stateToSave },
                    });
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{ ...buttonStyle }}
                  style={{ marginRight: "10px" }}>
                  Go Back
                </Button>

                <div>
                  <Button
                    type="button"
                    color="secondary"
                    sx={{ ...buttonStyle }}
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    disabled={isDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveAsDraft(values);
                    }}>
                    Save as draft
                  </Button>

                  <Button
                    type="button"
                    color="secondary"
                    variant="contained"
                    sx={{ ...buttonStyle }}
                    style={{ marginRight: "10px" }}
                    disabled={isDisabled}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSaveAsReview(values);
                    }} // pass the values directly
                  >
                    Save as review
                  </Button>
                </div>

                <Button
                  type="button"
                  color="secondary"
                  sx={{ ...buttonStyle }}
                  variant="contained"
                  disabled={isDisabled || !canApprove}
                  onClick={(e) => {
                    e.preventDefault();
                    handleApprove(values);
                  }} // pass the values directly
                >
                  Approve
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  }
};

// const phoneRegExp =
//   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  // facilityid: yup.string().required("required"),
  // clientid: yup.string().required("required"),
  // staffid: yup.string().required("required"),
  // insurancename: yup.string().required("required"),
  // insurancepolicynumber: yup.string().required("required"),
  // supervisorid: yup.string().required("required"),
  // sessiondate: yup.string().required("required"),
  // sessionstarttime: yup.string().required("required"),
  // sessionendtime: yup.string().required("required"),
  // sessiontype: yup.string().required("required"),
  // placeofservicecode: yup.string().required("required"),
  // otherplaceofservice: yup.string().required("required"),
  // modifier: yup.string().required("required"),
  // diagnosiscode1: yup.string().required("required"),
  // otherdiagnosiscode: yup.string().required("required"),
  // procedurecode: yup.string().required("required"),
  // sessionparticipants: yup.string().required("required"),
  // othersessionparticipants: yup.string().required("required"),
  // rbtworkstyle: yup.string().required("required"),
  // otherrbtworkstyle: yup.string().required("required"),
  // skillstrategiesapplied: yup.string().required("required"),
  // otherskillstrategiesapplied: yup.string().required("required"),
  // behaviorstrategiesapplied: yup.string().required("required"),
  // otherbehaviorstrategiesapplied: yup.string().required("required"),
  // maladaptivebehaviors: yup.string().required("required"),
  // reinforcerusedduringsession: yup.string().required("required"),
  // primarytargetareasaddressed: yup.string().required("required"),
  // sessionsummary: yup.string().required("required"),
  // clientmoodresponse: yup.string().required("required"),
  // planfornextvisit: yup.string().required("required"),
  // parentorgurdianname: yup.string().required("required"),
  // parentorgurdiansignaturefilename: yup.string().required("required"),
  // parentorgurdiansignaturedate: yup.string().required("required"),
  // staffsignaturefilename: yup.string().required("required"),
  // staffsignaturedate: yup.string().required("required"),
  // supervisorsignaturefilename: yup.string().required("required"),
  // supervisorsignaturedate: yup.string().required("required"),
  // email: yup.string().email("invalid email").required("required"),
});
const initialValues = {
  facilityid: "",
  clientid: "",
  staffid: "",
  insurancename: "",
  insurancepolicynumber: "",
  supervisorid: "",
  sessiondate: null,
  sessionstarttime: null,
  sessionendtime: "",
  sessiontime: "",
  sessiontype: "",
  placeofservicecode: "",
  otherplaceofservice: "",
  modifier: "",
  diagnosiscode1: "",
  otherdiagnosiscode: "",
  procedurecode: "",
  sessionparticipants: "",
  othersessionparticipants: "",
  rbtworkstyle: "",
  otherrbtworkstyle: "",
  skillstrategiesapplied: "",
  otherskillstrategiesapplied: "",
  behaviorstrategiesapplied: "",
  otherbehaviorstrategiesapplied: "",
  maladaptivebehaviors: "",
  reinforcerusedduringsession: "",
  primarytargetareasaddressed: "",
  sessionsummary: "",
  clientmoodresponse: "",
  planfornextvisit: "",
  othersessionparticipants: "",
  otherrbtworkstyle: "",
  parentorgurdianname: "",
  parentorgurdiansignaturefilename: "",
  parentorgurdiansignaturedate: null,
  verbalremindersprovided: false,
  staffsignaturefilename: "",
  staffsignaturedate: null,
  differntialreinforcement: false,
  supervisorsignaturefilename: "",
  supervisorsignaturedate: null,
  sessionstatus: "Pending",
};
export default SessionNote;
