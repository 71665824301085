import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../../components/SnackBarProvider";
import { useDispatch } from "react-redux";
import { shiftFolder } from "../../../../Redux/ActionCreators/FileFolderActionCreator/filefoldersActionCreators";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoveFolderDialog from "../FolderShiftingDialog";

const ListViewPage = ({
  allFolders,

  userFolders,
  handleDelete,
  handleEdit,
  handleMoveTo,
}) => {
  const [anchorEl, setAnchorEl] = useState(true);
  const [currentFolder, setCurrentFolder] = useState(null);
  const navigate = useNavigate();
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const dispatch = useDispatch();
  const [docId, setDocId] = useState(1);
  const [data, setData] = useState(null);
  const { openSnackbar } = useSnackbar();
  const handleOpenMoveDialog = (docid, data) => {
    setOpenMoveDialog(true);
    setDocId(docid);
    setData(data);
  };
  const handleCloseMoveDialog = () => setOpenMoveDialog(false);

  // Pass both source and destination to handleMoveTo
  const handleMove = (destination) => {
    console.log("called", { data, docId }, " est", destination);
    if (destination) {
      dispatch(shiftFolder({ data, docId }, destination, openSnackbar));
      // handleMoveTo(docId, selectedDestination); // Source: docId, Destination: selectedDestination
      handleCloseMoveDialog();
      handleClose();
    }
  };
  const handleClick = (event, folder) => {
    setAnchorEl(event.currentTarget);
    setCurrentFolder(folder);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoubleClick = (folderId) => {
    navigate(`/manageGoal/folder/${folderId}`); // Navigate to the folder's page on double click
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "auto", // Adjust width as needed
          margin: "0 16px", // Adds margin to the left and right
          border: "1px solid black", // Dark black border
          backgroundColor: "transparent", // Transparent background
        }}>
        <Table aria-label="simple table">
          <TableHead sx={{ backgroundColor: "#3A57E8" }}>
            <TableRow>
              <TableCell sx={{ borderBottom: "2px solid " }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Name
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "2px solid " }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Description
                </Typography>
              </TableCell>
              <TableCell sx={{ borderBottom: "2px solid " }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Status
                </Typography>
              </TableCell>
              <TableCell align="right" sx={{ borderBottom: "2px solid " }}>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Action
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userFolders.map((folder) => (
              <TableRow
                key={folder.name}
                onDoubleClick={() => handleDoubleClick(folder.docId)} // Add double-click event handler
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  borderBottom: "2px solid black",
                }} // Bold lines for separation
              >
                <TableCell component="th" scope="row">
                  {folder.data.name}
                </TableCell>
                <TableCell>{folder.data.description}</TableCell>
                <TableCell>{folder.data.status}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={(e) => handleClick(e, folder)}>
                    <MoreVertIcon />
                  </IconButton>

                  <IconButton onClick={() => handleEdit(folder.docId)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(folder.docId)}>
                    <DeleteIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && currentFolder === folder}
                    onClose={handleClose}>
                    <MenuItem
                      onClick={() =>
                        handleOpenMoveDialog(folder.docId, folder.data)
                      }>
                      Move To
                    </MenuItem>
                    <MenuItem onClick={() => handleDelete(folder.docId)}>
                      Delete
                    </MenuItem>
                    <MenuItem onClick={() => handleEdit(folder.docId)}>
                      Edit
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <MoveFolderDialog
        open={openMoveDialog}
        onClose={handleCloseMoveDialog}
        sourceFolderName={data?.name}
        allFolders={allFolders}
        sourceFolderId={docId}
        handleMoveTo={handleMove}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
      />
    </>
  );
};

export default ListViewPage;
