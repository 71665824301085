import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import {
  Box,
  TextField,
  IconButton,
  Menu,
  Checkbox,
  Snackbar,
  Alert,
  FormControlLabel,
  Switch,
} from "@mui/material";

import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  getFolders,
  updateFolderName,
  deleteFolder,
  shiftFolder,
  clearErrorMessage,
} from "../../../../Redux/ActionCreators/FileFolderActionCreator/filefoldersActionCreators.js";
import SubNav from "../SubNav.js";
import DraggableDroppableFolder from "../DragNDrop/index.jsx";
import LoadingIndicator from "../../../../scenes/Loader/index.jsx";
import ViewGoal from "../viewGoalData/index.jsx";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GoalAreaModal from "../GoalAreaModal/index.jsx";
import ListViewPage from "../ListViewPage/ListViewPage.jsx";
import { useSnackbar } from "../../../../components/SnackBarProvider.jsx";
const FolderComponent = () => {
  const { folderId } = useParams();
  const { openSnackbar } = useSnackbar();
  console.log("folderid", folderId);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [folderDescription, setFolderDescription] = useState("");
  const [viewMode, setViewMode] = useState("grid"); // 'grid' or 'list'

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [editingFolderId, setEditingFolderId] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [isGoalAreaModalOpen, setIsGoalAreaModalOpen] = useState(false);
  const [includeInactive, setIncludeInactive] = useState(false);
  const [userFolders, setUserFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [folderName, setFolderName] = useState("");

  const { allFolders, folders, Data, errorMessage } = useSelector(
    (state) => ({
      allFolders: state.filefolders.allFolders,
      folders: state.filefolders.folders,
      Data: state.filefolders.data,
      errorMessage: state.filefolders.error,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getFolders(folderId, openSnackbar));
  }, [folderId]);
  useEffect(() => {
    // dispatch(getFolders(folderId));

    const uf =
      folders &&
      folders.filter(
        (file) =>
          parseInt(file.data.parent) === parseInt(folderId) &&
          file.data.status.toLowerCase() === "active"
      );
    setUserFolders(uf);
    const cf =
      folders &&
      folders.find((folder) => {
        console.log("folder", folder.docId, folderId);
        if (folder.docId == folderId) {
          console.log("folder match", folder);
          return folder;
        }
        return folder.docId == folderId;
      });
    setCurrentFolder(cf);
    console.log("currentFolder foldrcomp", currentFolder);
    setIsLoading(false);
  }, [folders]);
  useEffect(() => {
    if (errorMessage != null) {
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    }
  }, [errorMessage]);
  const handleMenuOpen = (event, docId) => {
    setMenuAnchorEl(event.currentTarget);
    setCurrentFolderId(docId);
  };
  const handleCloseModal = () => {
    setIsGoalAreaModalOpen(false);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);

    setCurrentFolderId(null);
  };

  const handleRename = (id, name) => {
    // Logic to rename the folder
    //handleMenuClose();
  };

  const handleDelete = (id) => {
    // Logic to delete the folder
    if (window.confirm("Are you sure you want to delete this folder?")) {
      dispatch(deleteFolder(id, openSnackbar));
      handleMenuClose();
    }
  };
  const handleEdit = (docId) => {
    const payload = userFolders.find((folder) => folder.docId === docId);
    console.log("payload for edit", payload);
    setEditingFolderId(docId); // Set the current editing ID
    setIsGoalAreaModalOpen(true); // Open the modal
    handleMenuClose();
  };

  const handleMoveTo = () => {
    setShowModal(true);
    // Logic to move the folder
    handleMenuClose();
  };

  const toggleEdit = (docId, currentName) => {
    setEditingFolderId(editingFolderId === docId ? null : docId);
    setFolderName(currentName);
  };

  const handleDrop = (draggedFolder, targetFolder) => {
    dispatch(shiftFolder(draggedFolder, targetFolder, openSnackbar));

    console.log("draggedFolder", draggedFolder);
    console.log("targetFolder", targetFolder);
    // Implement your logic to update the folder structure
  };

  const handleNameChange = (e) => {
    setFolderName(e.target.value);
  };

  const saveName = (docId) => {
    setEditingFolderId(null);
  };
  const toggleViewMode = () => {
    setViewMode(viewMode === "grid" ? "list" : "grid");
  };
  const handleFolderSubmit = (e) => {
    e.preventDefault();
    console.log("folderName", folderName);
    console.log("folderDescription", folderDescription);
    setShowModal(false);
  };
  const handleIncludeInactiveChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (!event.target.checked) {
      const uf =
        folders &&
        folders.filter(
          (file) =>
            parseInt(file.data.parent) === parseInt(folderId) &&
            file.data.status.toLowerCase() === "active"
        );
      setUserFolders(uf);
    } else {
      const uf =
        folders &&
        folders.filter(
          (file) => parseInt(file.data.parent) === parseInt(folderId)
        );
      setUserFolders(uf);
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }
  console.log("userFolders length", userFolders.length);
  if (!currentFolder) {
    return (
      <div className="staff-background">
        <>
          <SubNav currentFolder={currentFolder} />
          <Row>
            <Col md="12">
              <p className="text-center small text-center my-5">Empty Folder</p>
            </Col>
          </Row>
        </>
      </div>
    );
  }
  return (
    <div className="staff-background">
      <>
        {isGoalAreaModalOpen && (
          <GoalAreaModal
            isOpen={isGoalAreaModalOpen}
            onClose={handleCloseModal}
            folderDetails={userFolders.find(
              (folder) => folder.docId === editingFolderId
            )} // Pass the current editing ID to the modal
          />
        )}
        <DndProvider backend={HTML5Backend}>
          <SubNav currentFolder={currentFolder} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: 2,
            }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeInactive}
                  onChange={handleIncludeInactiveChange}
                  name="includeInactiveFolders"
                  color="primary"
                />
              }
              label="Include Inactive Folders"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={viewMode === "grid"}
                  onChange={toggleViewMode}
                />
              }
              label={
                viewMode === "grid" ? <ViewModuleIcon /> : <ViewListIcon />
              }
              labelPlacement="start"
            />
          </Box>
          {userFolders && userFolders.length > 0 && (
            <>
              <Box
                sx={{
                  marginTop: "20px",

                  display: viewMode === "grid" ? "grid" : "block", // Adjust display style based on view mode
                  gridTemplateColumns:
                    viewMode === "grid" ? "repeat(4, minmax(0, 1fr))" : "none", // Only apply grid styling for grid view
                  width: "98%", // Ensure the container takes full width
                }}>
                {!folders ? (
                  <LoadingIndicator />
                ) : viewMode === "grid" ? (
                  userFolders.map(
                    ({ data, docId }) =>
                      docId != folderId && (
                        <DraggableDroppableFolder
                          allFolders={allFolders}
                          key={docId}
                          data={data}
                          docId={docId}
                          onDrop={handleDrop}
                          handleRename={handleRename}
                          handleDelete={handleDelete}
                          toggleEdit={toggleEdit}
                          editingFolderId={editingFolderId}
                          folderName={folderName}
                          handleNameChange={handleNameChange}
                          saveName={saveName}
                          handleMenuOpen={handleMenuOpen}
                          handleMenuClose={handleMenuClose}
                          menuAnchorEl={menuAnchorEl}
                          currentFolderId={currentFolderId}
                          handleMoveTo={handleMoveTo}
                          handleEdit={handleEdit}
                        />
                      )
                  )
                ) : (
                  <ListViewPage
                    allFolders={allFolders}
                    userFolders={userFolders}
                    handleMoveTo={handleMoveTo}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                )}
              </Box>
            </>
          )}
        </DndProvider>
        <DndProvider backend={HTML5Backend}>
          <ViewGoal parent={currentFolder.docId} />
        </DndProvider>
      </>
    </div>
  );
};

export default FolderComponent;
