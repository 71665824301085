export const SET_LOADING = "SET_LOADING";

export const SET_FOLDERS = "SET_FOLDERS";
export const ADD_FOLDER = "ADD_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const UPDATE_FOLDER_NAME = "UPDATE_FOLDER_NAME";
export const SHIFT_FOLDER = "SHIFT_FOLDER";

export const RESET_FOLDERS_FILES = "RESET_FOLDERS_FILES";

export const SET_DATA = "SET_DATA";
export const ADD_DATA = "ADD_DATA";
export const UPDATE_DATA = "UPDATE_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const SHIFT_DATA = "SHIFT_DATA";

//permission of screen
export const FETCH_PERMISSIONS = "FETCH_PERMISSIONS";
//staff
export const FETCH_ROLES = "FETCH_ROLES";
export const FETCH_STAFF = "FETCH_STAFF";
export const ADD_STAFF = "ADD_STAFF";
export const UPDATE_STAFF = "UPDATE_STAFF";
export const FETCH_SUPERVISOR_BY_ROLE = "FETCH_SUPERVISOR_BY_ROLE";
export const FETCH_BCBA_AND_ABOVE = "FETCH_BCBA_AND_ABOVE";
export const DELETE_STAFF = "DELETE_STAFF";

//client
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const ADD_CLIENT = "ADD_CLIENT";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const FETCH_CLIENTS_FOR_STAFF = "FETCH_CLIENTS_FOR_STAFF";

export const FETCH_EVENTS = "FETCH_EVENTS";
export const ADD_EVENT = "ADD_EVENT";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";
export const FETCH_EVENT_BY_ID = "FETCH_EVENT_BY_ID";
export const EVENT_FAILURE = "EVENT_FAILURE";

//client acccess
export const FETCH_MAIN_CLIENTS = "FETCH_MAIN_CLIENTS";
// OTP Actions
export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILURE = "SEND_OTP_FAILURE";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

// Signup Actions
export const SIGNUP_CLIENT_REQUEST = "SIGNUP_CLIENT_REQUEST";
export const SIGNUP_CLIENT_SUCCESS = "SIGNUP_CLIENT_SUCCESS";
export const SIGNUP_CLIENT_FAILURE = "SIGNUP_CLIENT_FAILURE";

// Consent Form Actions
export const GET_CONSENT_FORM_REQUEST = "GET_CONSENT_FORM_REQUEST";
export const GET_CONSENT_FORM_SUCCESS = "GET_CONSENT_FORM_SUCCESS";
export const GET_CONSENT_FORM_FAILURE = "GET_CONSENT_FORM_FAILURE";

export const SAVE_CONSENT_FORM_REQUEST = "SAVE_CONSENT_FORM_REQUEST";
export const SAVE_CONSENT_FORM_SUCCESS = "SAVE_CONSENT_FORM_SUCCESS";
export const SAVE_CONSENT_FORM_FAILURE = "SAVE_CONSENT_FORM_FAILURE";

export const DELETE_CONSENT_FORM_REQUEST = "DELETE_CONSENT_FORM_REQUEST";
export const DELETE_CONSENT_FORM_SUCCESS = "DELETE_CONSENT_FORM_SUCCESS";
export const DELETE_CONSENT_FORM_FAILURE = "DELETE_CONSENT_FORM_FAILURE";

export const RETRIEVE_CONSENT_FORM_REQUEST = "RETRIEVE_CONSENT_FORM_REQUEST";
export const RETRIEVE_CONSENT_FORM_SUCCESS = "RETRIEVE_CONSENT_FORM_SUCCESS";
export const RETRIEVE_CONSENT_FORM_FAILURE = "RETRIEVE_CONSENT_FORM_FAILURE";

export const UPDATE_CONSENT_FORM_REQUEST = "UPDATE_CONSENT_FORM_REQUEST";
export const UPDATE_CONSENT_FORM_SUCCESS = "UPDATE_CONSENT_FORM_SUCCESS";
export const UPDATE_CONSENT_FORM_FAILURE = "UPDATE_CONSENT_FORM_FAILURE";

// Fetch Goals
export const FETCH_GOALS_REQUEST = "FETCH_GOALS_REQUEST";

// Add Goal
export const ADD_GOAL_REQUEST = "ADD_GOAL_REQUEST";

// Delete Goal
export const DELETE_GOAL_REQUEST = "DELETE_GOAL_REQUEST";

// Update Goal
export const UPDATE_GOAL_REQUEST = "UPDATE_GOAL_REQUEST";

//Set all folders
export const SET_ALLFOLDERS = "SET_ALLFOLDERS";
