// src/redux/actions/clientActions.js
import {
  SEND_OTP_REQUEST,
  SEND_OTP_SUCCESS,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILURE,
  SIGNUP_CLIENT_REQUEST,
  SIGNUP_CLIENT_SUCCESS,
  SIGNUP_CLIENT_FAILURE,
  GET_CONSENT_FORM_REQUEST,
  GET_CONSENT_FORM_SUCCESS,
  GET_CONSENT_FORM_FAILURE,
  SAVE_CONSENT_FORM_REQUEST,
  SAVE_CONSENT_FORM_SUCCESS,
  SAVE_CONSENT_FORM_FAILURE,
  DELETE_CONSENT_FORM_REQUEST,
  DELETE_CONSENT_FORM_SUCCESS,
  DELETE_CONSENT_FORM_FAILURE,
  RETRIEVE_CONSENT_FORM_REQUEST,
  RETRIEVE_CONSENT_FORM_SUCCESS,
  RETRIEVE_CONSENT_FORM_FAILURE,
  UPDATE_CONSENT_FORM_REQUEST,
  UPDATE_CONSENT_FORM_SUCCESS,
  UPDATE_CONSENT_FORM_FAILURE,
  FETCH_CLIENTS,
  FETCH_MAIN_CLIENTS,
} from "../../Actions/actionTypes";
import { API_BASE_URL, MANAGE_CONSENT_PAGE, READ } from "../../../constants";
import axiosInstance from "../../../components/AxiosInstance";

// Send OTP Action Creator
export const sendOtp = (email, openSnackbar) => async (dispatch) => {
  console.log("sending");
  try {
    const res = await axiosInstance.post(
      `${API_BASE_URL}/api/sendotptoclient`,
      { email }
    );
    if (res.status === 200) {
      openSnackbar("OTP sent successfully to your email.", "success");
    }
  } catch (error) {
    openSnackbar(error || "Error sending OTP", "error");
  }
};

// Verify OTP Action Creator
export const verifyOtp = (email, otp, openSnackbar) => async (dispatch) => {
  dispatch({ type: VERIFY_OTP_REQUEST });
  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/api/verifyotp`, {
      email,
      otp,
    });
    if (res.status === 200) {
      dispatch({ type: VERIFY_OTP_SUCCESS });
      openSnackbar("OTP verified successfully.", "success");
    }
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_FAILURE,
      payload: error.response?.data?.message || "Invalid OTP",
    });
    openSnackbar(error, "error");
  }
};

// Signup Action Creator
export const signupClient =
  (payload, openSnackbar, navigate) => async (dispatch) => {
    dispatch({ type: SIGNUP_CLIENT_REQUEST });
    console.log("payload", payload);
    try {
      const res = await axiosInstance.post(
        `${API_BASE_URL}/api/signupclient`,
        payload
      );
      if (res.status === 200) {
        dispatch({ type: SIGNUP_CLIENT_SUCCESS });
        openSnackbar("Signed Up successfully.", "success");
        navigate("/");
      }
    } catch (error) {
      dispatch({
        type: SIGNUP_CLIENT_FAILURE,
        payload: error.response?.data?.message || "User already exists",
      });
      openSnackbar(error, "error");
    }
  };

// Get Consent Form Details Action Creator
export const getConsents = (checkAllClientPermission) => async (dispatch) => {
  let endpoint = "";
  dispatch({ type: GET_CONSENT_FORM_REQUEST });
  if (checkAllClientPermission(MANAGE_CONSENT_PAGE, READ)) {
    endpoint = "getallconsentformdetails";
  } else endpoint = "getconsentformdetails";
  try {
    const res = await axiosInstance.post(`${API_BASE_URL}/api/${endpoint}`, {
      email: localStorage.getItem("username"),
    });
    dispatch({
      type: GET_CONSENT_FORM_SUCCESS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: GET_CONSENT_FORM_SUCCESS,
      payload: [],
    });
    // openSnackbar(
    //   error.response?.data?.message || "Error fetching  details",
    //   "error"
    // );
  }
};
export const getMainClients = () => async (dispatch) => {
  try {
    const res = await axiosInstance.post(
      `${API_BASE_URL}/api/viewclientbyemailorguardianemail`,
      { email: localStorage.getItem("username") }
    );
    dispatch({
      type: FETCH_MAIN_CLIENTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_MAIN_CLIENTS,
      payload: [],
    });
    // openSnackbar(
    //   error.response?.data?.message || "Error fetching  details",
    //   "error"
    // );
  }
};
// Save Consent Forms Action Creator
export const saveConsentFormsAction =
  (data, openSnackbar) => async (dispatch) => {
    dispatch({ type: SAVE_CONSENT_FORM_REQUEST });
    delete data["id"];
    try {
      const res = await axiosInstance.post(
        `${API_BASE_URL}/api/saveconsentforms`,
        [
          {
            ...data,
            client: {
              id: localStorage.getItem("userId"),
            },
          },
        ]
      );
      dispatch({
        type: SAVE_CONSENT_FORM_SUCCESS,
        payload: res.data[0],
      });
      openSnackbar("Consent forms saved successfully.", "success");
    } catch (error) {
      dispatch({
        type: SAVE_CONSENT_FORM_FAILURE,
        payload: error.response?.data?.message || "Error saving consent form",
      });
      openSnackbar(
        error.response?.data?.message || "Error saving consent form",
        "error"
      );
    }
  };

// Delete Consent Form Action Creator
export const deleteConsentFormAction =
  (id, openSnackbar) => async (dispatch) => {
    dispatch({ type: DELETE_CONSENT_FORM_REQUEST });
    try {
      const res = await axiosInstance.post(
        `${API_BASE_URL}/api/deactivateconsentform`,
        id
      );
      if (res.status === 200) {
        dispatch({ type: DELETE_CONSENT_FORM_SUCCESS, payload: id });
        openSnackbar("Consent form deleted successfully.", "success");
      }
    } catch (error) {
      dispatch({
        type: DELETE_CONSENT_FORM_FAILURE,
        payload: error.response?.data?.message || "Error deleting consent form",
      });
      openSnackbar(
        error.response?.data?.message || "Error deleting consent form",
        "error"
      );
    }
  };

export const reactivateConsentFormAction =
  (data, openSnackbar) => async (dispatch) => {
    try {
      const res = await axiosInstance.post(
        `${API_BASE_URL}/api/reactivateconsentform`,
        { id: data.id }
      );
      if (res.status === 200) {
        dispatch({ type: UPDATE_CONSENT_FORM_SUCCESS, payload: data });
        openSnackbar("Activated  successfully.", "success");
      }
    } catch (error) {
      // dispatch({
      //   type: DELETE_CONSENT_FORM_FAILURE,
      //   payload: error.response?.data?.message || "Error deleting consent form",
      // });
      openSnackbar(error, "error");
    }
  };
// Retrieve Consent Form Action Creator
export const retrieveConsentFormAction =
  (criteria, openSnackbar) => async (dispatch) => {
    dispatch({ type: RETRIEVE_CONSENT_FORM_REQUEST });
    try {
      const res = await axiosInstance.post(
        `${API_BASE_URL}/api/getconsentform`,
        criteria
      );
      dispatch({
        type: RETRIEVE_CONSENT_FORM_SUCCESS,
        payload: res.data,
      });
      openSnackbar("Consent form retrieved successfully.", "success");
    } catch (error) {
      dispatch({
        type: RETRIEVE_CONSENT_FORM_FAILURE,
        payload:
          error.response?.data?.message || "Error retrieving consent form",
      });
      openSnackbar(
        error.response?.data?.message || "Error retrieving consent form",
        "error"
      );
    }
  };

// Update Consent Form Action Creator
export const updateConsentFormAction =
  (data, openSnackbar) => async (dispatch) => {
    dispatch({ type: UPDATE_CONSENT_FORM_REQUEST });
    try {
      const res = await axiosInstance.post(
        `${API_BASE_URL}/api/updateconsentform`,
        { ...data, lastUpdatedBy: localStorage.getItem("username") }
      );
      console.log(res, "res");
      dispatch({
        type: UPDATE_CONSENT_FORM_SUCCESS,
        payload: res.data,
      });
      openSnackbar("Consent form updated successfully.", "success");
    } catch (error) {
      dispatch({
        type: UPDATE_CONSENT_FORM_FAILURE,
        payload: error.response?.data?.message || "Error updating consent form",
      });
      openSnackbar(error, "error");
    }
  };
