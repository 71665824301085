import React, { useState, useEffect, useRef } from "react";
import { LineChart } from "@mui/x-charts";
import axiosInstance from "../../components/AxiosInstance";
import useSharedLink from "../../scenes/SharedLink";
import LoadingIndicator from "../../scenes/Loader";
import {
  Button,
  ButtonGroup,
  TextField,
  Select,
  MenuItem,
  Box,
  Autocomplete,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyle, collectionTypeColors } from "../../constants";
import utc from "dayjs/plugin/utc";

import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Margin } from "@mui/icons-material";
import { useSnackbar } from "../../components/SnackBarProvider";
import { themeStyles, buttonStyle } from "../../constants";
import MyLineChart from "../../components/LineGraph";

import html2canvas from "html2canvas";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

export default function GoalLineGraph({
  allGoals,
  clientId,
  type,
  onClose,
  showDowload = true,
  showClose = true,
}) {
  dayjs.extend(utc);
  const chartRef = useRef(null); // Create a ref

  const [loading, setLoading] = useState(true);
  const sharedLink = useSharedLink();
  const { openSnackbar } = useSnackbar();
  const [chartData, setChartData] = useState({
    xAxis: [{ name: "Trial", data: [] }],
    series: [{ name: "Result", data: [] }],
  });
  const [download, setDownload] = useState(false);
  const [goalValues, setGoalValues] = useState([]);
  const [showGraph, setShowGraph] = useState("whole");
  const [xlabels, setXLabels] = useState([]);
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [frequency, setFrequency] = useState("");
  const [frequencyChange, setFrequencyChange] = useState("Daily");
  const [customRange, setCustomRange] = useState([
    dayjs().format("YYYY-MM-DD"),
    dayjs().format("YYYY-MM-DD"),
  ]);
  // const [chartData, setChartData] = useState({
  //   xAxis: [{ data: [1, 2, 3, 5, 8, 10] }], // Assuming this is correct
  //   series: [{ data: [2, 5.5, 2, 8.5, 1.5, 5] }], // Corrected format
  // });

  useEffect(() => {}, [customRange]);
  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
    // You would then call your API with this new frequency to update the chart data
  };
  const fetchData = async (selectedGoal) => {
    console.log(selectedGoal);
    const type =
      selectedGoal.programType.toLowerCase() === "task analysis"
        ? "taskanalysis"
        : selectedGoal.programType.toLowerCase();

    const endpoint = `/getfinal${type}data`;
    const payload = {
      clientid: clientId,
      goalid: selectedGoal.goalId,
    };
    if (selectedGoal.start) {
      payload.sessionstartdate = dayjs(selectedGoal.start).format("YYYY-MM-DD");
      payload.sessionenddate = dayjs(selectedGoal.end).format("YYYY-MM-DD");
    }
    console.log(payload, "payload");
    try {
      const response = await axiosInstance.post(
        sharedLink + endpoint,
        payload,
        {
          withCredentials: true,
        }
      );
      console.log("response", response);
      const data = response.data;
      const combinedData = data.map((item) => ({
        sessionDate: item.goalDataCommonFields.sessionDate,
        result: item.result,
        formattedDate: dayjs
          .utc(item.goalDataCommonFields.sessionDate)
          .format("YYYY-MM-DD"), // For labels
        type: item.goalDataCommonFields.dataCollectionType,
        lastUpdatedBy: item.goalDataCommonFields.lastUpdatedBy,
      }));

      // Sort by sessionDate
      combinedData.sort((a, b) => a.sessionDate - b.sessionDate);
      setGoalValues(combinedData);
      console.log("combined", combinedData);

      // Extract sorted xAxisData, seriesData, and xlabels
      const xAxisData = combinedData.map((item) => item.sessionDate);
      const seriesData = combinedData.map((item) => item.result);
      const xlabels = combinedData.map((item) => item.formattedDate);

      setXLabels(xlabels);
      setChartData({
        xAxis: [{ name: "Session Date", data: xAxisData }],
        series: [{ name: "Result Value", data: seriesData }],
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching chart data:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log(selectedGoal);

    const fetchGoals = async () => {
      const goalsEndpoint = `${sharedLink}/getprogrambooks`;
      try {
        const response = await axiosInstance.post(
          goalsEndpoint,
          {
            clientId: clientId,
          },
          { withCredentials: true }
        );
        console.log(response, "response");
        let formattedGoals = [];

        const item = response?.data[0]?.dataStrategyList;
        if (item && Array.isArray(item)) {
          item.forEach((development) => {
            if (
              development.developmentalAreaList &&
              Array.isArray(development.developmentalAreaList)
            ) {
              development.developmentalAreaList.forEach((strategy) => {
                if (
                  strategy.treatmentPlanObjectiveList &&
                  Array.isArray(strategy.treatmentPlanObjectiveList)
                ) {
                  strategy.treatmentPlanObjectiveList.forEach((obj) => {
                    if (
                      obj.shortTermObjective &&
                      Array.isArray(obj.shortTermObjective)
                    ) {
                      console.log("hey", obj);
                      obj.shortTermObjective.forEach((sto) => {
                        sto.goals.forEach((goal) => {
                          formattedGoals.push({
                            goalId: goal.id,
                            goalName: goal.goalName,
                            goalAreaName: goal.goalAreaName,
                            programType: goal.programType, // Ensure this field exists or adjust accordingly
                            goalDescription: goal.goalDescription,
                          });
                        });
                      });
                    }
                  });
                }
              });
            }
          });
        }

        console.log(formattedGoals, "formatted");
        setGoals(formattedGoals);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching goals:", error);
      }
    };
    console.log("goals", allGoals);
    if (allGoals) {
      setGoals(allGoals);
      setLoading(false);
    } else fetchGoals();
  }, [type, clientId]);
  const handleGoalChange = (newValue) => {
    setSelectedGoal(newValue);
    if (newValue) fetchData(newValue);
  };
  const handleDateRange = (value) => {
    const end = dayjs();
    let start;

    switch (value) {
      case "1D":
        start = dayjs().subtract(1, "day");
        setFrequency("");
        break;
      case "5D":
        start = dayjs().subtract(5, "day");
        setFrequency("");
        break;
      case "1M":
        start = dayjs().subtract(1, "month");
        setFrequency("");
        break;
      case "3M":
        start = dayjs().subtract(3, "month");
        setFrequency("");
        break;
      case "6M":
        start = dayjs().subtract(6, "month");
        setFrequency("");
        break;

      case "1Y":
        start = dayjs().subtract(1, "year");
        setFrequency("");
        break;
      case "5Y":
        start = dayjs().subtract(5, "year");
        setFrequency("");
        break;
      default:
        setFrequency("custom");
        start = dayjs().subtract(4, "year");
    }
    if (value !== "CUSTOM") {
      fetchData({ ...selectedGoal, start: start, end: dayjs() });
    }
  };
  const handleGo = () => {
    const [start, end] = customRange;
    if (!start || !end) {
      openSnackbar("Please select both start and end date", "error");
      return;
    }
    fetchData({ ...selectedGoal, start: start, end: end });
  };
  const truncateDescription = (description) => {
    if (!description) return "";
    return description.length > 50
      ? description.substring(0, 47) + "..."
      : description;
  };

  const handleDownload = async () => {
    setDownload(true);
    setTimeout(async () => {
      try {
        const canvas = await html2canvas(chartRef.current);
        const image = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.download = "chart.png";
        link.href = image;
        link.click();
      } catch (error) {
        console.error("Error downloading the chart:", error);
      }
      setDownload(false); // Reset back to false after download
    }, 100); // Delay in milliseconds, adjust if needed
    // setDownload(false);
  };

  if (loading) return <LoadingIndicator />;
  return (
    <>
      <Box sx={{ position: "relative", p: 2 }}>
        {showClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", top: -20, right: -10, zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
        )}
        <div ref={chartRef} style={{ background: "white", padding: "20px" }}>
          {/* <Box sx={{ position: "relative", p: 2, padding: '20px' }}> /</Box> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              marginBottom: 2,
            }}>
            {!download ? (
              <Autocomplete
                id="goal-selector"
                options={goals}
                getOptionLabel={(option) =>
                  `${option.goalName} (${option.goalAreaName})`
                }
                renderInput={(params) => (
                  <TextField {...params} label="Select Goal" variant="filled" />
                )}
                onChange={(event, newValue) => handleGoalChange(newValue)}
                value={selectedGoal}
                sx={{ boxShadow: "none", ...themeStyles.textField }}
              />
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", marginRight: 1 }}>
                  Goal:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flexGrow: 1, textAlign: "left" }}>
                  {selectedGoal?.goalName}
                </Typography>
              </Box>
            )}
            {selectedGoal && (
              <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", marginRight: 1 }}>
                  Description:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ flexGrow: 1, textAlign: "left" }}>
                  {selectedGoal.goalDescription}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              border: "2px solid black",
              borderRadius: "4px",

              padding: 2,
              marginBottom: 2,
            }}>
            {chartData.series[0].data.length > 0 ? (
              <>
                <MyLineChart goalValues={goalValues} />
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 400, // Match chart height for consistency
                }}>
                No data found
              </Box>
            )}
          </Box>
        </div>
        {showDowload && (
          <IconButton
            aria-label="download"
            onClick={() => {
              setDownload(true);
              handleDownload();
            }}
            color="primary"
            sx={{
              position: "absolute",
              left: -10,
              top: -10,
              borderRadius: "50%", // Make it circular
              zIndex: 1,
            }}>
            <FileDownloadIcon />
          </IconButton>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 2,
            padding: "0px",
            border: "2px solid black",
            borderRadius: "4px",
          }}>
          <ButtonGroup
            variant="text"
            aria-label="text button group"
            disabled={!selectedGoal}>
            <Button onClick={() => handleDateRange("1D")}>1D</Button>
            <Button onClick={() => handleDateRange("5D")}>5D</Button>
            <Button onClick={() => handleDateRange("1M")}>1M</Button>
            <Button onClick={() => handleDateRange("3M")}>3M</Button>
            <Button onClick={() => handleDateRange("6M")}>6M</Button>
            <Button onClick={() => handleDateRange("1Y")}>1Y</Button>
            <Button onClick={() => handleDateRange("5Y")}>5Y</Button>
            <Button onClick={() => handleDateRange("CUSTOM")}>CUSTOM</Button>
          </ButtonGroup>
          <Select
            value={frequencyChange}
            onChange={handleFrequencyChange}
            disabled={!selectedGoal}>
            <MenuItem value="Daily">Daily</MenuItem>
            <MenuItem value="Weekly">Weekly</MenuItem>
            <MenuItem value="Monthly">Monthly</MenuItem>
            {/* Add more options as needed */}
          </Select>
        </Box>

        {frequency === "custom" && (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                marginBottom: 2,
                marginTop: 2,
              }}>
              <TextField
                type="date"
                label="Start Date"
                value={customRange[0]}
                onChange={(e) =>
                  setCustomRange([e.target.value, customRange[1]])
                }
                renderInput={(params) => <TextField {...params} />}
                variant="filled"
                sx={{ width: "auto", flexGrow: 1, ...themeStyles.textField }}
              />
              <TextField
                type="date"
                label="End Date"
                value={customRange[1]}
                onChange={(e) =>
                  setCustomRange([customRange[0], e.target.value])
                }
                renderInput={(params) => <TextField {...params} />}
                variant="filled"
                sx={{ width: "auto", flexGrow: 1, ...themeStyles.textField }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleGo}
                sx={{ ...buttonStyle }}>
                GO
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
