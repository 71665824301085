import React, { useState, useContext } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Switch,
} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { ColorModeContext, tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import axiosInstance from "../../components/AxiosInstance";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined"; // Import Menu icon
import Ssidebar from "./Ssidebar"; // Import sidebar
import { API_BASE_URL } from "../../constants";

const Topbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current path
  const colorMode = useContext(ColorModeContext);
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const [schedulerStatus, setSchedulerStatus] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null); // State for the sidebar menu

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleManageProfile = () => {
    navigate("/passwordChange");
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleOpenLogoutDialog = () => {
    setOpenLogoutDialog(true);
  };

  const handleToggleScheduler = () => {
    setOpenConfirmationDialog(true); // Open confirmation dialog
  };

  const handleConfirmToggle = () => {
    setSchedulerStatus(!schedulerStatus); // Confirm toggle action
    setOpenConfirmationDialog(false); // Close confirmation dialog
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false); // Close confirmation dialog without changing the scheduler status
  };

  const handleLogout = async () => {
    setOpenLogoutDialog(false);
    setLogoutInProgress(true); // Start showing loading indicator
    try {
      localStorage.clear();
      navigate("/");
    } catch (e) {
      console.log(e);
    } finally {
      setLogoutInProgress(false); // Stop showing loading indicator
    }
  };

  const handleNavigateToDashboard = () => {
    if (location.pathname !== "/dashboard") {
      navigate("/dashboard");
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      bgcolor={colors.primary[400]}>
      <Box display="flex" alignItems="center">
        <Ssidebar />
        <Header
          title={
            <span
              onClick={handleNavigateToDashboard}
              style={{ cursor: "pointer" }}>
              <span
                style={{ color: "#007FFF", textShadow: "2px 2px 4px #D3D3D3" }}>
                Q U A L I T Y{" - "}
              </span>
              {/* <span
                style={{ color: "#34A853", textShadow: "2px 2px 4px #D3D3D3" }}>
                I T Y{" - "}
              </span> */}
              <span
                style={{ color: "#34A853", textShadow: "2px 2px 4px #D3D3D3" }}>
                A B A {" - "}
              </span>
              <span
                style={{ color: "#007FFF", textShadow: "2px 2px 4px #D3D3D3" }}>
                N O T E S{" "}
              </span>
            </span>
          }
          subtitle=""
        />
      </Box>

      <Box display="flex" alignItems="center">
        {/* Profile Icon */}
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleProfileMenuOpen}
            size="small"
            sx={{ ml: 2 }}>
            <Avatar src="../../assets/user.png" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleProfileMenuClose}
          onClick={handleProfileMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
          <MenuItem onClick={handleManageProfile}>Manage Profile</MenuItem>
          <MenuItem onClick={handleOpenLogoutDialog}>Logout</MenuItem>
        </Menu>
      </Box>
      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}></Dialog>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{"Confirm Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {schedulerStatus
              ? "Turn off the scheduler?"
              : "Turn on the scheduler?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog}>Cancel</Button>
          <Button onClick={handleConfirmToggle} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseLogoutDialog}>No</Button>
          <Button onClick={handleLogout} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Sidebar Menu */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.12))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}>
        <Ssidebar />
      </Menu>
    </Box>
  );
};

export default Topbar;
