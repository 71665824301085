// File: DataGridPage.js
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import useSharedLink from "../../scenes/SharedLink";
import { useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { DataGridPro } from "@mui/x-data-grid-pro";
import LoadingIndicator from "../../scenes/Loader";
import { useSnackbar } from "../../components/SnackBarProvider"; // Import SnackbarProvider

import { Edit, Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DialogForm from "./AddDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteConsentFormAction,
  reactivateConsentFormAction,
  saveConsentFormsAction,
  updateConsentFormAction,
} from "../../Redux/ActionCreators/ClientAccessActionCreator";
import { usePermissions } from "../../Phase3/CheckPermission";
import { getConsents } from "../../Redux/ActionCreators/ClientAccessActionCreator";
const initialRows = [
  {
    id: 1,
    name: "John Doe",
    email: "john@example.com",
    status: "Active",
    relation: "Father",
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "jane@example.com",
    status: "Inactive",
    relation: "Mother",
  },
];

export default function Consent() {
  const theme = useTheme();
  const { openSnackbar } = useSnackbar();
  const { checkAllClientPermission } = usePermissions();
  const sharedLink = useSharedLink();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [includeInactive, setIncludeInactive] = useState(false);

  const dispatch = useDispatch();
  const allusers =
    useSelector((state) => state?.clientAccess?.consentDetails) || [];
  console.log("allusers in consent", allusers);
  useEffect(() => {
    dispatch(getConsents(checkAllClientPermission));
  }, []);
  useEffect(() => {
    if (includeInactive) {
      setRows(allusers); // Include all users (active and inactive)
    } else {
      setRows(allusers.filter((user) => user.status === "Active")); // Include only active users
    }
  }, [allusers, includeInactive]);

  const handleAddClick = () => {
    setSelectedRow(null);
    setIsEditMode(false);
    setOpenDialog(true);
  };

  const handleEditClick = (row) => {
    setSelectedRow(row);
    setIsEditMode(true);
    setOpenDialog(true);
  };

  const handleDeleteClick = (row) => {
    setSelectedRow(row);
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirm = () => {
    dispatch(deleteConsentFormAction({ id: selectedRow.id }, openSnackbar));
    // setRows(rows.filter((row) => row.id !== selectedRow.id));
    setOpenDeleteConfirm(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedRow(null);
  };

  const handleFormSubmit = (formData, type = null) => {
    if (type && type == "reactivate") {
      dispatch(reactivateConsentFormAction(formData, openSnackbar));
      return;
    }
    if (isEditMode) {
      dispatch(updateConsentFormAction({ ...formData }, openSnackbar));
      //   setRows(rows.map((row) => (row.id === formData.id ? formData : row)));
    } else {
      dispatch(saveConsentFormsAction({ ...formData }, openSnackbar));

      //   setRows([...rows, { id: rows.length + 1, ...formData }]);
    }
    setOpenDialog(false);
  };
  const handleCheckboxChange = (event) => {
    setIncludeInactive(event.target.checked);
    if (event.target.checked) {
      setRows(allusers);
    } else {
      setRows(allusers.filter((row) => row.status === "Active"));
    }
  };
  const columns = [
    { field: "firstName", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "relationship", headerName: "Relation", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => handleEditClick(params.row)}
            color="primary">
            <Edit />
          </IconButton>
          <IconButton
            disabled={params.row.status == "Inactive"}
            onClick={() => handleDeleteClick(params.row)}
            color="danger">
            <Delete />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Box display="flex" justifyContent="flex-end" m={2}>
        <Button
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: "blue",
            color: "white",
          }}
          startIcon={<AddIcon />}
          onClick={handleAddClick}>
          Add
        </Button>
      </Box>
      <Box m="20px">
        <Header title="Consent Authorities" subtitle="" />
        <Box
          m="40px 0 0 0"
          //   height="60vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.grey[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3A57E8",
              borderBottom: "white",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: "#3A57E8",
            },
            "& .MuiCheckbox-root": {
              color: `${colors.grey[200]} !important`,
            },
          }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeInactive}
                onChange={handleCheckboxChange}
                name="includeInactive"
              />
            }
            label="Include Inactive"
          />

          <DataGrid
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              columns: {
                columnVisibilityModel: {
                  id: false,
                  facilityId: false,
                  lastUpdatedBy: false,
                  lastUpdatedTime: false,
                  clientId: false,
                  enteredTime: false,
                  enteredBy: false,
                },
              },
            }}
            sx={{
              boxShadow: 5,
              border: 5,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "white",
                fontWeight: "bold !important",
              },
              "& .MuiDataGrid-footerContainer": {
                color: "white",
                fontWeight: "bold !important",
              },
              height: "60vh",
            }}
            pageSizeOptions={[25, 50, 100]}
            rows={rows}
            columns={columns}
            editMode="row"
            rowModesModel={rowModesModel}
            // onRowModesModelChange={handleRowModesModelChange}
            // onRowEditStop={handleRowEditStop}
            // processRowUpdate={processRowUpdate}
            slotProps={{
              toolbar: { setRows, setRowModesModel },
            }}
            isCellEditable={false}
            onRowDoubleClick={(params) => {
              handleEditClick(params.row.id)();
            }}
          />
        </Box>
      </Box>

      <DialogForm
        open={openDialog}
        handleClose={handleDialogClose}
        handleSubmit={handleFormSubmit}
        isEditMode={isEditMode}
        rowData={selectedRow}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this record?
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteConfirm(false)}
            sx={{
              backgroundColor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "darkred",
              },
            }}>
            Cancel
          </Button>

          <Button
            sx={{
              backgroundColor: "blue",
              color: "white",
              "&:hover": {
                backgroundColor: "darkblue",
              },
            }}
            onClick={handleDeleteConfirm}>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
