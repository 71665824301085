import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Snackbar,
  Stack,
  Typography,
  Grid,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import CommonDialog from "../../../components/CommonDialog";
import {
  themeStyles,
  buttonStyle,
  PROGRAM_BOOK,
  CREATE,
} from "../../../constants";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Domain from "../../UpdateTreeComponent/Level1";
import Header from "../../../components/Header";
import useSharedLink from "../../../scenes/SharedLink";
import { useMediaQuery } from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import { useLocation } from "react-router-dom";
import LoadingIndicator from "../../../scenes/Loader";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../../../components/SnackBarProvider";
import ScrollToBottomButton from "../../../components/ScrollToBottom";
import ScrollToTopButton from "../../../components/ScrollToTopButton";
import StyledTextArea from "../../../components/StyledTextArea";
import isEqual from "lodash/isEqual";
import { usePermissions } from "../../../Phase3/CheckPermission";
import dayjs from "dayjs";
const UpdateProgramBook = () => {
  const { checkPermission, giveClients, giveStaff } = usePermissions();
  const sharedLink = useSharedLink();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [domains, setDomains] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { openSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [openGoBackDialog, setOpenGoBackDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [programBookName, setProgramBookName] = useState("");
  const [programBookDescription, setProgramBookDescription] = useState("");
  const [clientid, setClientid] = useState("");
  const [facilityid, setFacilityid] = useState("");
  const clients = giveClients(PROGRAM_BOOK, CREATE);
  const [facilityName, setFacilityName] = useState("");
  const [status, setStatus] = useState();
  const [activeAreas, setActiveAreas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientName, setClientName] = useState("");
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [initialState, setInitialState] = useState(null);
  const checkForUnsavedChanges = useCallback(() => {
    const currentState = {
      programBookName,
      programBookDescription,
      domains,
    };
    if (initialState && !isEqual(initialState, currentState)) {
      setUnsavedChanges(true);
    } else {
      setUnsavedChanges(false);
    }
  }, [initialState, programBookName, programBookDescription, domains]);

  useEffect(() => {
    checkForUnsavedChanges();
  }, [
    programBookName,
    programBookDescription,
    domains,
    checkForUnsavedChanges,
  ]);

  const keyMap = {
    developmentalAreaList: "developmentalAreaTemplates",
    treatmentPlanObjectiveList: "treatmentPlanObjectiveTemplates",
    shortTermObjective: "shortTermObjectiveTemplates",
    goals: "goalTemplates",
    dataStrategyList: "dataStrategyTemplates",
    trialDataList: "trialList",
  };

  function renameKeys(obj, keyMap) {
    if (!obj || typeof obj !== "object") {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => renameKeys(item, keyMap));
    }

    const newObj = {};
    Object.keys(obj).forEach((key) => {
      const newKey = keyMap[key] || key;
      newObj[newKey] = renameKeys(obj[key], keyMap);
    });

    return newObj;
  }

  function reverseRenameKeys(obj, keyMap) {
    const invertedKeyMap = Object.keys(keyMap).reduce((acc, key) => {
      acc[keyMap[key]] = key;
      return acc;
    }, {});

    return renameKeys(obj, invertedKeyMap);
  }

  const transformData = (inputData) => {
    const processItem = (item) => {
      if (Array.isArray(item)) {
        item.forEach((child) => processItem(child));
      } else if (typeof item === "object" && item !== null) {
        delete item["iid"];
        delete item["visible"];
        delete item["modified"];
        delete item["enteredBy"];
        delete item["lastUpdatedBy"];
        delete item["lastUpdatedTime"];
        delete item["enteredTime"];
        delete item["type"];
        if ("goaltype" in item) {
          if (
            typeof item["goaltype"] === "object" &&
            item["goaltype"] !== null
          ) {
            item["goalAreaName"] = item["goaltype"].goalAreaName;
            item["goalAreaId"] = item["goaltype"].goalAreaId;
          }
          delete item["goaltype"];
        }
        if ("trialList" in item) {
          item["trialDataList"] = item["trialList"];
          delete item["trialList"];
        }
        if ("masteryTargetDate" in item) {
          item["masteryTargetDate"] = dayjs(item["masteryTargetDate"]).format(
            "YYYY-MM-DD"
          );
        }

        Object.keys(item).forEach((key) => processItem(item[key]));
      }
    };

    processItem(inputData);
    return inputData;
  };

  const transformDataToaddIndex = (inputData) => {
    const processItem = (item, parentKey = null, index = null) => {
      if (Array.isArray(item)) {
        item.forEach((child, i) => {
          processItem(child, parentKey, i);
        });
      } else if (typeof item === "object" && item !== null) {
        item["iid"] = index;
        item["visible"] = true;

        Object.keys(item).forEach((key) => {
          processItem(item[key], key);
        });
      }
    };

    const dataCopy = JSON.parse(JSON.stringify(inputData));
    dataCopy.forEach((item, index) => processItem(item, null, index));
    return dataCopy;
  };

  useEffect(() => {
    async function fetchAllData() {
      try {
        const userId = localStorage.getItem("userId");
        const isStaff = userId && true;

        const programBookPromise = axiosInstance.post(
          `${sharedLink}/getprogrambooks`,
          {
            id: id,
          },
          { withCredentials: true }
        );
        console.log(programBookPromise, "b response");
        let clientPromise;

        if (isStaff) {
          clientPromise = axiosInstance.post(
            `${sharedLink}/viewclient`,
            {
              staffid: userId,
            },
            { withCredentials: true }
          );
        } else {
          clientPromise = axiosInstance.post(`${sharedLink}/viewallclient`, {
            withCredentials: true,
          });
        }

        const [programBookResponse] = await Promise.all([programBookPromise]);

        console.log(programBookResponse);

        const facilityId = programBookResponse.data[0].facilityId;
        const clientId = programBookResponse.data[0].clientId;

        setClientName(programBookResponse.data[0].clientName);
        setClientid(clientId);
        setFacilityid(facilityId);
        setFacilityName(programBookResponse.data[0].facilityName);

        const renamedData = renameKeys(programBookResponse.data[0], keyMap);
        console.log("renamed data", renamedData);

        setProgramBookName(programBookResponse.data[0].programBookName);
        setProgramBookDescription(
          programBookResponse.data[0].programBookDescription
        );
        setStatus(programBookResponse.data[0].status);

        const transformedData = transformDataToaddIndex(
          renamedData.dataStrategyTemplates
        );
        setDomains(transformedData);
        setInitialState({
          programBookName: programBookResponse.data[0].programBookName,
          programBookDescription:
            programBookResponse.data[0].programBookDescription,
          domains: transformedData,
        });
        setActiveAreas(
          transformedData.filter((val) => val.strategyStatus === "Active")
        );
        setLoading(false);
      } catch (error) {
        console.error("Error during the combined request:", error);
        setLoading(false);
      }
    }
    fetchAllData();
  }, []);

  function cleanPayload(data) {
    function cleanObject(obj) {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (!["id", "visible", "goaltype"].includes(key)) {
          if (Array.isArray(value)) {
            newObj[key] = value.map((item) => cleanObject(item));
          } else if (typeof value === "object" && value !== null) {
            newObj[key] = cleanObject(value);
          } else {
            newObj[key] = value;
          }
        }

        if (key === "goaltype" && typeof value === "object" && value !== null) {
          newObj["goalAreaId"] = value.goalAreaId;
          newObj["goalAreaName"] = value.goalAreaName;
        }
      });
      return newObj;
    }

    return Array.isArray(data)
      ? data.map((item) => cleanObject(item))
      : cleanObject(data);
  }

  const handleSubmit = (data) => {
    function isNotEvent(data) {
      return !("_reactName" in data || "type" in data || "nativeEvent" in data);
    }
    setLoading(true);
    let domainCopy;
    console.log(data);
    if (data === undefined || data === null || !isNotEvent(data)) {
      domainCopy = JSON.parse(JSON.stringify(domains));
    } else {
      domainCopy = JSON.parse(JSON.stringify(data));
    }

    console.log("clientid", clientid);
    console.log("facilityid", facilityid);
    const payload = {
      programBookName: programBookName,
      programBookDescription: programBookDescription,
      dataStrategyList: reverseRenameKeys(domainCopy, keyMap),
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
      clientId: clientid,
      facilityId: facilityid,
      clientName: clientName,
      facilityName: facilityName,
      status: status,
    };

    console.log("payload", payload);
    const cleanedPayload = transformData(payload);
    cleanedPayload["id"] = id;
    console.log("cleanedPayload", cleanedPayload);
    const strinifiedpayload = {
      id: id,
      programBookName: programBookName,
      dataStrategyList: JSON.stringify(cleanedPayload.dataStrategyList),
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
      status: "Active",
      programBookDescription: programBookDescription,
      clientId: clientid,
      clientName: clientName,
      facilityId: facilityid,
      facilityName: facilityName,
    };
    console.log(strinifiedpayload);
    try {
      axiosInstance
        .post(sharedLink + "/updateprogrambook", strinifiedpayload)
        .then((res) => {
          if (res.status === 200) {
            if (Array.isArray(res.data)) {
              const facilityId = res.data[0].facilityId;
              const clientId = res.data[0].clientId;

              setProgramBookName(res.data[0].programBookName);
              setProgramBookDescription(res.data[0].programBookDescription);
              setStatus(res.data[0].status);

              const renamedData = renameKeys(res.data[0], keyMap);

              const transformedData = transformDataToaddIndex(
                renamedData.dataStrategyTemplates
              );
              setInitialState({
                programBookName: res.data[0].programBookName,
                programBookDescription: res.data[0].programBookDescription,
                domains: transformedData,
              });
              setDomains(transformedData);
              setActiveAreas(
                transformedData.filter((val) => val.strategyStatus === "Active")
              );
              setLoading(false);
            }
            setSnackbar({
              open: true,
              message: "Program Book Updated Successfully",
              severity: "success",
            });
          } else {
            setSnackbar({
              open: true,
              message: "Failed to Update Program Book",
              severity: "error",
            });
          }
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: "Failed to Update Program Book",
            severity: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onActivate = () => {
    setStatus("Active");
    handleSubmit(null);
  };

  const onShowInactiveAreasChange = (isChecked) => {
    console.log(isChecked);
    if (isChecked) {
      setActiveAreas(domains);
    } else {
      setActiveAreas(domains?.filter((val) => val.strategyStatus === "Active"));
    }
  };

  const addDomain = () => {
    const newId = domains.length;
    setDomains([
      ...domains,
      {
        iid: newId,
        visible: true,
        strategyName: "",
        strategyDescription: "",
        strategyStatus: "Active",
        developmentalAreaTemplates: [],
      },
    ]);
    setActiveAreas([
      ...activeAreas,
      {
        iid: newId,
        visible: true,
        strategyName: "",
        strategyDescription: "",
        strategyStatus: "Active",
        developmentalAreaTemplates: [],
      },
    ]);
  };

  const updateDomain = (iid, domainName, domainDescription, longtermgoals) => {
    setDomains(
      domains.map((domain) => {
        if (domain.iid === iid) {
          return {
            ...domain,
            strategyName: domainName,
            strategyDescription: domainDescription,
            strategyStatus: "Active",
            developmentalAreaTemplates: longtermgoals,
          };
        }
        return domain;
      })
    );
    setActiveAreas(
      activeAreas.map((domain) => {
        if (domain.iid === iid) {
          return {
            ...domain,
            strategyName: domainName,
            strategyDescription: domainDescription,
            strategyStatus: "Active",
            developmentalAreaTemplates: longtermgoals,
          };
        }
        return domain;
      })
    );
  };

  const removeDomain = (iid) => {
    setDomains(domains.filter((domain) => domain.iid !== iid));
    setActiveAreas(activeAreas.filter((domain) => domain.iid !== iid));
  };

  const updateStatus = (iid, status, data) => {
    const res = domains.map((domain) => {
      if (domain.iid === iid) {
        return {
          ...domain,
          strategyStatus: status,
          developmentalAreaTemplates: data,
        };
      }
      return domain;
    });
    handleSubmit(res);
  };

  const toggleVisibility = (iid) => {
    setDomains(
      domains.map((domain) => {
        if (domain.iid === iid) {
          return { ...domain, visible: !domain.visible };
        }
        return domain;
      })
    );
    setActiveAreas(
      activeAreas.map((domain) => {
        if (domain.iid === iid) {
          return { ...domain, visible: !domain.visible };
        }
        return domain;
      })
    );
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleOpenGoBackDialog = () => {
    setOpenGoBackDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenGoBackDialog(false);
  };

  const handleClientChange = (event, newValue) => {
    setSelectedClient(newValue);
  };

  const handleCopySubmit = () => {
    const payload = {
      id: parseInt(id),
      clientid: selectedClient.id + "",
      clientname: selectedClient.firstName + " " + selectedClient.lastName,
      facilityname: selectedClient.facilityName,
      facilityid: selectedClient.facilityId,
    };
    try {
      axiosInstance
        .post(sharedLink + "/copyprogrambook", payload)
        .then((res) => {
          if (res.status === 200) {
            openSnackbar("Copied Successfully!", "success");
            handleCloseDialog();
          }
        })
        .catch((err) => {
          openSnackbar(err?.response?.data, "error");
        });
    } catch (err) {
      openSnackbar(err?.response?.data, "error");
    }
  };
  const handleGoBack = () => {
    if (unsavedChanges) {
      setOpenGoBackDialog(true);
    } else {
      navigate("/manageProgramBook");
    }
  };

  const handleDialogClose = (confirm) => {
    setOpenDialog(false);
    setOpenGoBackDialog(false);
    if (confirm) {
      navigate("/manageProgramBook");
    }
  };
  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <>
      <Box m="20px">
        <Header title="Manage Program Book " subenteredby="" />

        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            margin="20px">
            <Button
              variant="contained"
              color="primary"
              onClick={onActivate}
              sx={{ borderRadius: 50, ...buttonStyle }}
              disabled={status === "Active"}>
              Activate
            </Button>
            <Button
              onClick={handleOpenDialog}
              variant="contained"
              sx={{ borderRadius: 50, ...buttonStyle }}>
              Copy Program Book
            </Button>
          </Box>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            sx={{
              "& .MuiDialog-paper": {
                width: "50%",
                maxWidth: "none",
                height: "auto",
                maxHeight: "90%",
              },
            }}>
            <DialogTitle>Select a Client to Copy To</DialogTitle>
            <DialogContent>
              <Autocomplete
                fullWidth
                label="Select Client"
                options={clients}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName} `
                }
                renderInput={(params) => (
                  <TextField {...params} label="Client" variant="filled" />
                )}
                sx={{ ...themeStyles.textField }}
                value={selectedClient}
                onChange={handleClientChange}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseDialog}
                variant="contained"
                sx={{ ...buttonStyle }}>
                Cancel
              </Button>
              <Button
                onClick={handleCopySubmit}
                variant="contained"
                color="primary"
                sx={{ ...buttonStyle }}>
                Copy
              </Button>
            </DialogActions>
          </Dialog>
        </>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              options={clients}
              name="clientid"
              autoHighlight
              onChange={(event, newValue) => {
                setClientid(newValue);
              }}
              value={clientName}
              className="animated-input"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Client Name"
                  variant="filled"
                  required
                  inputProps={{
                    ...params.inputProps,
                    "aria-label": "Select Client Name",
                  }}
                  disabled
                />
              )}
              sx={{
                ...themeStyles.textField,
                gridColumn: "span 4",
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="filled"
              label="Program Book Name"
              value={programBookName}
              onChange={(e) => setProgramBookName(e.target.value)}
              sx={{ ...themeStyles.textField }}
              name="programBookName"
              required
              inputProps={{
                maxLength: 120,
              }}
              disabled={status == "Inactive"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTextArea
              fullWidth
              variant="filled"
              label="Program Book Description"
              multiline
              value={programBookDescription}
              onChange={(e) => setProgramBookDescription(e.target.value)}
              rows={3}
              name="programBookDescription"
              sx={{ ...themeStyles.textField }}
              maxLength={512}
              disabled={status == "Inactive"}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            color="primary"
            sx={{ ...buttonStyle }}
            onClick={addDomain}>
            Data Strategy
          </Button>

          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) =>
                  onShowInactiveAreasChange(event.target.checked)
                }
                name="showInactiveAreas"
                color="primary"
              />
            }
            label={
              <Typography variant="body1" sx={{ marginRight: 3.3 }}>
                Show Inactive
              </Typography>
            }
            style={{ marginBottom: 0, marginRight: 5 }}
          />
        </Box>
        <Box key={activeAreas}>
          {activeAreas?.map((domain) => (
            <Domain
              key={domain.iid}
              iid={domain.iid}
              parentStatus={status === "Active"}
              id={domain.id}
              InitialDomainDescription={domain.strategyDescription}
              InitialDomainName={domain.strategyName}
              InitialLongTermGoals={domain.developmentalAreaTemplates}
              onRemove={removeDomain}
              visible={domain.visible}
              toggleVisibility={toggleVisibility}
              updateDomain={updateDomain}
              updateStatus={updateStatus}
              status={domain.strategyStatus}
              type="book"
            />
          ))}
        </Box>
      </Box>
      <Box
        mt="20px"
        display="flex"
        justifyContent="end"
        sx={{
          marginTop: "20px",
        }}>
        <Button
          onClick={() => {
            handleGoBack();
          }}
          color="secondary"
          variant="contained"
          style={{ marginRight: "10px" }}
          sx={{ ...buttonStyle }}>
          Go Back
        </Button>

        <Button
          onClick={handleSubmit}
          color="secondary"
          variant="contained"
          sx={{ ...buttonStyle }}
          disabled={id ? status !== "Active" : false}>
          Save
        </Button>
      </Box>
      <ScrollToTopButton />
      <ScrollToBottomButton />
      <CommonDialog
        open={openGoBackDialog}
        heading="Unsaved Changes"
        message="There are unsaved changes, are you sure you want to go back?"
        onClose={handleDialogClose}
        onConfirm={() => handleDialogClose(true)}
      />
    </>
  );
};

export default UpdateProgramBook;
