import React from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const PasswordStrengthChecker = ({ password }) => {
  // Define separate regex patterns for each password validation
  const lengthCheck = password.length >= 10; // At least 10 characters
  const uppercaseCheck = /[A-Z]/.test(password); // At least one uppercase letter
  const lowercaseCheck = /[a-z]/.test(password); // At least one lowercase letter
  const numberCheck = /\d/.test(password); // At least one number
  const specialCharCheck = /\W/.test(password); // At least one special character

  // Password validation items
  const validationItems = [
    { label: "At least 10 characters", isValid: lengthCheck },
    { label: "Contains one uppercase letter", isValid: uppercaseCheck },
    { label: "Contains one lowercase letter", isValid: lowercaseCheck },
    { label: "Contains one number", isValid: numberCheck },
    { label: "Contains one special character (@, #, $, %, etc.)", isValid: specialCharCheck },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      {validationItems.map((item, index) => (
        <Typography
          key={index}
          variant="body2"
          sx={{
            fontSize: "16px",
            color: item.isValid ? "green" : "red",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}>
          {item.isValid ? (
            <CheckCircleOutlineIcon fontSize="small" />
          ) : (
            <HighlightOffIcon fontSize="small" />
          )}
          {item.label}
        </Typography>
      ))}
    </Box>
  );
};

export default PasswordStrengthChecker;
