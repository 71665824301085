import { useSelector } from "react-redux";
import React, { createContext, useContext, useState } from "react";
import axiosInstance from "../../components/AxiosInstance";
import {
  ALL_SCREENS,
  API_BASE_URL,
  CLINICAL_ASSISTANT,
  CREATE,
  DATA_COLLECTION_PAGE,
  DATA_ENTRY_PAGE,
  DELETE,
  EXECUTIVE_OFFICER,
  GOAL,
  LEAD_RBT,
  PROGRAM_BOOK,
  PROGRAM_BOOK_TEMPLATE,
  SESSION_NOTE_97151,
  SESSION_NOTE_97153,
  SESSION_NOTE_97155,
  SESSION_NOTE_97156,
  SESSION_NOTE_H0032,
  SESSION_NOTE_PAGE,
  SYSTEM_ADMIN,
  UPDATE,
} from "../../constants";
import { useCheckbox } from "../../components/InactiveCheckbox";

export const usePermissions = () => {
  const role = localStorage.getItem("role");
  const userId = parseInt(localStorage.getItem("userId"));
  const { showInactive } = useCheckbox();

  const userPermissions = useSelector((state) => state.permissions.permissions);
  const clients = useSelector((state) => state.clients);
  const staff = useSelector((state) => state.staffs);
  console.log(staff, "staff");

  const checkPermission = (screen, action, owner = null) => {
    console.log(screen, action, owner, " ", role);

    if (role === SYSTEM_ADMIN || role == EXECUTIVE_OFFICER) {
      console.log("hye");
      return true;
    }
    if (screen == SESSION_NOTE_PAGE)
      return (
        checkPermission(SESSION_NOTE_97153, action, owner) ||
        checkPermission(SESSION_NOTE_97151, action, owner) ||
        checkPermission(SESSION_NOTE_97155, action, owner) ||
        checkPermission(SESSION_NOTE_97156, action, owner) ||
        checkPermission(SESSION_NOTE_H0032, action, owner)
      );

    if (screen == DATA_COLLECTION_PAGE) {
      return (
        checkPermission(PROGRAM_BOOK, action, owner) ||
        checkPermission(PROGRAM_BOOK_TEMPLATE, action, owner) ||
        checkPermission(GOAL, action, owner)
      );
    }
    if (owner && owner == "Other") {
      return (userPermissions || []).some(
        (permission) =>
          permission.screen === screen &&
          permission?.action?.AssignedClients?.toLowerCase().includes(
            action.toLowerCase()
          )
      );
    }
    return (
      (userPermissions || []).some(
        (permission) =>
          permission.screen === screen &&
          permission?.action?.OwnClients?.toLowerCase().includes(
            action.toLowerCase()
          )
      ) ||
      (userPermissions || []).some(
        (permission) =>
          permission.screen === screen &&
          permission?.action?.Own?.toLowerCase().includes(action.toLowerCase())
      )
    );
  };
  const checkAllClientPermission = (screen, action, owner = null) => {
    console.log(screen, " ", action, " permission", userPermissions);
    return (userPermissions || []).some(
      (permission) =>
        permission.screen === screen &&
        permission?.action?.Alllients?.toLowerCase().includes(
          action.toLowerCase()
        )
    );
  };
  const checkStaffPermission = (screen, action) => {
    if (role === SYSTEM_ADMIN) return true;
    return (userPermissions || []).some(
      (permission) =>
        permission.screen === screen &&
        permission?.action?.AssignedStaff?.toLowerCase().includes(
          action.toLowerCase()
        )
    );
  };
  const giveClients = (screen, action) => {
    let filteredClients = [];
    console.log(action);
    if (role == SYSTEM_ADMIN || role == EXECUTIVE_OFFICER) {
      return clients?.allClients?.filter(
        (client) => showInactive || client.status === "Active"
      );
    }

    const screenPermissions = (userPermissions || []).find(
      (permission) =>
        permission.screen === screen || permission.screen == ALL_SCREENS
    );

    console.log(clients, " ", screenPermissions);
    if (!screenPermissions)
      return clients?.assignedClients?.filter(
        (client) => showInactive || client.status === "Active"
      );

    if (screenPermissions.action.AllClients?.includes(action)) {
      return clients?.allClients?.filter(
        (client) => showInactive || client.status === "Active"
      );
    } else if (screenPermissions.action.AssignedClients?.includes(action)) {
      return clients?.assignedClients?.filter(
        (client) => showInactive || client.status === "Active"
      );
    } else if (screenPermissions.action.OwnClients?.includes(action)) {
      return clients?.ownClients?.filter(
        (client) => showInactive || client.status === "Active"
      );
    }

    return filteredClients?.filter(
      (client) => showInactive || client.status === "Active"
    );
  };

  const giveStaff = (screen, action) => {
    if (role == SYSTEM_ADMIN) {
      if (
        screen === SESSION_NOTE_97151 ||
        screen === SESSION_NOTE_97156 ||
        screen === SESSION_NOTE_97155 ||
        screen === SESSION_NOTE_H0032
      )
        return staff?.bcbaAndAbove?.filter(
          (staff) => showInactive || staff.status === "Active"
        );
      return staff.allStaff.filter(
        (staff) => showInactive || staff.status === "Active"
      );
    }
    const screenPermissions = (userPermissions || []).find(
      (permission) =>
        permission.screen === screen || permission.screen == ALL_SCREENS
    );

    if (!screenPermissions)
      return staff.assignedStaff.filter(
        (staff) => showInactive || staff.status === "Active"
      );

    if (screenPermissions.action.AllStaff.includes(action)) {
      return staff.allStaff.filter(
        (staff) => showInactive || staff.status === "Active"
      );
    } else if (screenPermissions.action.AssignedStaff.includes(action)) {
      return staff.assignedStaff.filter(
        (staff) => showInactive || staff.status === "Active"
      );
    } else if (screenPermissions.action.Own.includes(action)) {
      return [
        (staff.allStaff || []).find(
          (staff) => staff.id === parseInt(localStorage.getItem("userId"))
        ),
      ];
    }
  };
  const checkApprover = async (staffId) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/getsupervisorsforapproval",
        { id: staffId },
        { withCredentials: true }
      );
      const approvers = response.data;

      console.log("User ID:", userId);
      console.log("Approvers List:", approvers);

      // Ensure approvers is an array
      if (!Array.isArray(approvers) || approvers.length === 0) {
        return false;
      }

      // Check if the userId is in the approvers list
      const isApprover = approvers.some((approver) => approver.id === userId);
      console.log("Is Approver:", isApprover);
      return isApprover;
    } catch (error) {
      console.error("Error fetching approver list:", error);
      return false;
    }
  };

  const fetchSupervisor = async (staffId) => {
    try {
      const response = await axiosInstance.post(
        API_BASE_URL + "/api/getsupervisorsforapproval",
        { id: staffId },
        { withCredentials: true }
      );
      const approvers = response.data;

      console.log("User ID:", userId);
      console.log("Approvers List:", approvers);

      // Ensure approvers is an array
      if (!Array.isArray(approvers) || approvers.length === 0) {
        return null;
      }
      return approvers;
    } catch (error) {
      console.error("Error fetching approver list:", error);
      return null;
    }
  };
  return {
    checkPermission,
    giveClients,
    giveStaff,
    checkApprover,
    fetchSupervisor,
    checkStaffPermission,
    checkAllClientPermission,
  };
};
