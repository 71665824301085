// src/redux/reducers/goalReducer.js
import {
  FETCH_GOALS_REQUEST,
  ADD_GOAL_REQUEST,
  DELETE_GOAL_REQUEST,
  UPDATE_GOAL_REQUEST,
} from "../../Actions/actionTypes";

const initialState = {
  loading: false,
  goals: [],
  error: null,
};

export const goalReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch Goals
    case FETCH_GOALS_REQUEST:
      return {
        ...state,
        goals: action.payload.goals || state.goals,
        error: action.payload.error || null,
        loading: false,
      };

    // Add Goal
    case ADD_GOAL_REQUEST:
      return {
        ...state,
        goals: action.payload.goal
          ? [...state.goals, action.payload.goal]
          : state.goals,
        error: action.payload.error || null,
        loading: false,
      };

    // Delete Goal
    case DELETE_GOAL_REQUEST:
      return {
        ...state,
        goals: action.payload.goalId
          ? state.goals.filter((goal) => goal.id !== action.payload.goalId)
          : state.goals,
        error: action.payload.error || null,
        loading: false,
      };

    // Update Goal
    case UPDATE_GOAL_REQUEST:
      return {
        ...state,
        goals: action.payload.goal
          ? state.goals.map((goal) =>
              goal.id === action.payload.goal.id ? action.payload.goal : goal
            )
          : state.goals,
        error: action.payload.error || null,
        loading: false,
      };

    default:
      return state;
  }
};
