import { Box, TextField, IconButton, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faEllipsisV,
  faEdit,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { useDrag, useDrop } from "react-dnd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MoveFolderDialog from "../FolderShiftingDialog";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../../../../components/SnackBarProvider";
import { shiftFolder } from "../../../../Redux/ActionCreators/FileFolderActionCreator/filefoldersActionCreators";
const DraggableDroppableFolder = ({
  allFolders,
  data,
  docId,
  onDrop,
  handleRename,
  toggleEdit,
  editingFolderId,
  saveName,
  currentFolderId,
  handleDelete,
  handleMoveTo,
  folderName,
  menuAnchorEl,
  handleMenuOpen,
  handleMenuClose,
  handleNameChange,
  handleEdit,
}) => {
  const [openMoveDialog, setOpenMoveDialog] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const handleOpenMoveDialog = () => setOpenMoveDialog(true);
  const handleCloseMoveDialog = () => setOpenMoveDialog(false);

  // Pass both source and destination to handleMoveTo
  const handleMove = (destination) => {
    console.log("called", { data, docId }, " est", destination);
    if (destination) {
      dispatch(shiftFolder({ data, docId }, destination, openSnackbar));
      // handleMoveTo(docId, selectedDestination); // Source: docId, Destination: selectedDestination
      handleCloseMoveDialog();
      handleMenuClose();
    }
  };

  const [{ isDragging }, dragRef] = useDrag(() => ({
    type: "folder",
    item: { data, docId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [{ isOver }, dropRef] = useDrop({
    accept: "folder",
    drop: (item, monitor) => {
      if (item.docId !== docId) {
        onDrop(item, { data, docId });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const navigate = useNavigate();

  const opacity = isDragging ? 0.4 : 1;

  return (
    <>
      <div
        ref={(node) => dragRef(dropRef(node))}
        style={{
          opacity,

          textAlign: "center",
          position: "relative",
        }}>
        <Box
          key={docId}
          sx={{
            gridColumn: "span 1",
            textAlign: "center",
            position: "relative",
          }}
          onDoubleClick={() => navigate(`/manageGoal/folder/${docId}`)}>
          <FontAwesomeIcon
            icon={faFolder}
            className="mt-3"
            style={{ fontSize: "3rem" }}
          />
          {/* <Box display="flex" alignItems="center"> */}
          {/* <TextField
            value={editingFolderId === docId ? folderName : data.name}
            disabled={editingFolderId !== docId}
            onChange={handleNameChange}
            size="small"
            variant="outlined"
            margin="normal"
            fullWidth
          /> */}
          <div>{data.name}</div> {/* Folder name displayed as plain text */}
          {/* </Box> */}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={(e) => handleMenuOpen(e, docId)}
            style={{ position: "absolute", top: 5, right: 5 }}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </IconButton>
          <Menu
            id="folder-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl) && currentFolderId === docId}
            onClose={handleMenuClose}>
            <MenuItem onClick={() => handleDelete(docId)}>Delete</MenuItem>
            <MenuItem onClick={() => handleEdit(docId)}>Edit</MenuItem>
            <MenuItem
              onClick={() => {
                setOpenMoveDialog(true);
              }}>
              Move To
            </MenuItem>
          </Menu>
        </Box>
      </div>
      <MoveFolderDialog
        open={openMoveDialog}
        onClose={handleCloseMoveDialog}
        sourceFolderName={data.name}
        allFolders={allFolders}
        sourceFolderId={docId}
        handleMoveTo={handleMove}
        selectedDestination={selectedDestination}
        setSelectedDestination={setSelectedDestination}
      />
    </>
  );
};

export default DraggableDroppableFolder;
