import {
  Box,
  Button,
  TextField,
  Snackbar,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import axiosInstance from "../../components/AxiosInstance";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingIndicator from "../Loader";
import useSharedLink from "../SharedLink";
import { themeStyles, buttonStyle, STATES } from "../../constants";
import { useSnackbar } from "../../components/SnackBarProvider";
import { cleanText } from "../../utils/textUtils";

const FacilityForm = ({ mode }) => {
  const sharedLink = useSharedLink();
  const { openSnackbar } = useSnackbar();
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const type = ["Home Based", "School Based", "Center Based"];

  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [loading, setLoading] = useState(mode === "update");
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("Id");

  useEffect(() => {
    setInitialValues(defaultInitialValues);
    if (mode === "update" && id) {
      const fetchData = async () => {
        try {
          const res = await axiosInstance.post(
            `${sharedLink}/viewfacility`,
            { id },
            { withCredentials: true }
          );
          if (res.status === 200) {
            const data = res.data[0];
            const formattedStartDate = data.startDate
              ? dayjs(data.startDate)
              : null;
            const formattedLicExpDate = data.licExpDate
              ? dayjs(data.licExpDate)
              : null;
            const formattedExpDate = data.expirationDate
              ? dayjs(data.expirationDate)
              : null;
            const payload = {
              id: data.id,
              address: data.address,
              city: data.city,
              state: data.state,
              country: data.country,
              zipcode: data.zipCode,
              phonenumber: data.phoneNumber,
              facilitytype: data.facilityType,
              facilityname: data.facilityName,
              facilitynpi: data.facilityNPI,
              facilitytin: data.facilityTIN,
              licenseno: data.licenseNo,
              lastupdatedby: data.lastUpdatedBy,
              enteredby: data.enteredBy,
              lastupdatedtime: data.lastUpdatedTime,
              enteredtime: data.enteredTime,
              startdate: formattedStartDate,
              licexpdate: formattedLicExpDate,
              expirationdate: formattedExpDate,
            };
            setInitialValues(payload);
            setLoading(false);
          } else {
            throw new Error("Failed to load facility");
          }
        } catch (error) {
          setLoading(false);
          openSnackbar(error, "error");
        }
      };
      fetchData();
    } else {
      setLoading(false);
    }
  }, [sharedLink, mode, id]);

  const handleFormSubmit = async (values, { resetForm, setErrors }) => {
    const formattedStartDate = values.startdate
      ? dayjs(values.startdate).format("YYYY-MM-DD")
      : null;
    const formattedLicExpDate = values.licexpdate
      ? dayjs(values.licexpdate).format("YYYY-MM-DD")
      : null;
    const formattedExpDate = values.expirationdate
      ? dayjs(values.expirationdate).format("YYYY-MM-DD")
      : null;
    const startdate = dayjs(values.startdate);
    const expirationdate = dayjs(values.expirationdate);

    if (expirationdate.isBefore(startdate, "day")) {
      openSnackbar("Expiration date must be after Start date", "error");
      return;
    }

    const payload = {
      ...values,
      startdate: formattedStartDate,
      licexpdate: formattedLicExpDate,
      expirationdate: formattedExpDate,
      lastupdatedby: localStorage.getItem("username"),
      lastupdatedtime: new Date().toLocaleTimeString(),
      enteredtime: new Date().toLocaleTimeString(),
      enteredby: localStorage.getItem("username"),
    };

    openSnackbar("Processing...", "info");

    try {
      const url =
        mode === "add"
          ? `${sharedLink}/addfacility`
          : `${sharedLink}/updatefacility`;
      const res = await axiosInstance.post(url, payload, {
        withCredentials: true,
      });
      console.log(res, "response");
      if (res.status === 200) {
        setSubmissionSuccess(true);
        openSnackbar(
          `Facility ${mode === "add" ? "created" : "updated"} successfully`,
          "success"
        );
        if (mode === "add") resetForm();
      } else {
        throw new Error("Failed to process request");
      }
    } catch (error) {
      openSnackbar(
        `Error in ${mode === "add" ? "creating" : "updating"} facility`,
        "error"
      );
    }
  };

  const handleReset = (resetForm) => {
    resetForm();
  };

  if (loading) return <LoadingIndicator />;
  
  const handleInputChange = (event, setFieldValue) => {
    const cleanValue = cleanText(event.target.value); // Allows specific special characters
    setFieldValue(event.target.name, cleanValue); // Update Formik field with cleaned value
  };

  return (
    <div className="fac-background">
      <Box m="20px">
        <Header
          title={`${mode === "add" ? "Add" : "Update"} Facility`}
          subtitle=""
        />

        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
          enableReinitialize>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}>
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  type="text"
                  className="animated-input"
                  label="Facility Name"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.facilityname}
                  name="facilityname"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Facility NPI"
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.facilitynpi}
                  name="facilitynpi"
                  required
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  required
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Facility TIN"
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.facilitytin}
                  name="facilitytin"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <Autocomplete
                  fullWidth
                  className="animated-input"
                  options={type}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  value={values.facilitytype}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "facilitytype",
                        value: newValue || "",
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Facility Type"
                      variant="filled"
                      required
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select a facility type",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  type="tel"
                  label="Contact Number"
                  onBlur={handleBlur}
                  required
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.phonenumber}
                  name="phonenumber"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                  error={touched.phonenumber && Boolean(errors.phonenumber)}
                  helperText={touched.phonenumber && errors.phonenumber}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  type="text"
                  label="Country"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.country}
                  name="country"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Street Address"
                  onBlur={handleBlur}
                  required
                  className="animated-input"
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.address}
                  name="address"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  options={STATES}
                  className="animated-input"
                  autoHighlight
                  value={values.state}
                  onChange={(event, newValue) => {
                    handleChange({
                      target: {
                        name: "state",
                        value: newValue,
                      },
                    });
                  }}
                  onBlur={handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      variant="filled"
                      required
                      value={values.state}
                      inputProps={{
                        ...params.inputProps,
                        "aria-label": "Select state",
                      }}
                    />
                  )}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  className="animated-input"
                  label="City"
                  required
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.city}
                  name="city"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Zip code"
                  required
                  className="animated-input"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.zipcode}
                  name="zipcode"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <DatePicker
                  fullWidth
                  label="Start Date"
                  onBlur={handleBlur}
                  required
                  onChange={(value) => setFieldValue("startdate", value)}
                  value={values.startdate || null}
                  name="startdate"
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      error: touched.startdate && Boolean(errors.startdate),
                      helperText: touched.startdate && errors.startdate,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                />

                <DatePicker
                  fullWidth
                  label="Expiration Date"
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue("expirationdate", value)}
                  value={values.expirationdate || null}
                  name="expirationdate"
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      error:
                        touched.expirationdate &&
                        Boolean(errors.expirationdate),
                      helperText:
                        touched.expirationdate && errors.expirationdate,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                />

                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  className="animated-input"
                  label="Facility License Number"
                  onBlur={handleBlur}
                  onChange={(event) => handleInputChange(event, setFieldValue)}
                  value={values.licenseno}
                  name="licenseno"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />

                <DatePicker
                  fullWidth
                  label="License Expiration Date"
                  onBlur={handleBlur}
                  disabled={values.licenseno === ""}
                  onChange={(value) => setFieldValue("licexpdate", value)}
                  value={values.licexpdate || null}
                  name="licexpdate"
                  InputLabelProps={{ shrink: true }}
                  slotProps={{
                    textField: {
                      error: touched.licexpdate && Boolean(errors.licexpdate),
                      helperText: touched.licexpdate && errors.licexpdate,
                      sx: { gridColumn: "span 2", ...themeStyles.textField },
                    },
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                {mode == "update" ? (
                  <Button
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{ ...buttonStyle }}>
                    Go Back
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      handleReset(resetForm);
                    }}
                    sx={{ ...buttonStyle }}>
                    Clear
                  </Button>
                )}
                <Button type="submit" sx={{ ...buttonStyle }}>
                  {mode === "add" ? "Add Facility" : "Update Facility"}
                </Button>
                {
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/managefacility")}
                    sx={{ ...buttonStyle }}>
                    See in Manage Facility
                  </Button>
                }
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  phonenumber: yup
    .string()
    .required("Contact number is required")
    .matches(/^[0-9]{10}$/, "Contact number must be 10 digits"),
  facilityname: yup.string().required("Facility Name is required"),
  facilitynpi: yup.string().required("Facility NPI is required"),
  facilitytin: yup.string().required("Facility TIN is required"),
  facilitytype: yup.string().required("Facility Type is required"),
  address: yup.string().required("Street Address is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipcode: yup.string().required("Zip code is required"),
  startdate: yup.date().required("Start Date is required"),
});

const defaultInitialValues = {
  facilityname: "",
  facilitynpi: "",
  facilitytin: "",
  city: "",
  state: "",
  zipcode: "",
  country: "United States",
  phonenumber: "",
  facilitytype: "",
  startdate: null,
  licexpdate: null,
  expirationdate: null,
  licenseno: "",
  address: "",
  lastupdatedby: "",
  lastupdatedtime: "",
  enteredtime: "",
  enteredby: "",
};

export default FacilityForm;
