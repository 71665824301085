import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "../../components/SnackBarProvider";
import PasswordStrengthChecker from "../../components/PasswordStrengthChecker";
import {
  sendOtp,
  signupClient,
  verifyOtp,
} from "../../Redux/ActionCreators/ClientAccessActionCreator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AuthenTication = () => {
  const [isSignUp, setIsSignUp] = useState(false); // To toggle between Login and Sign Up
  const [isOTPVisible, setIsOTPVisible] = useState(false); // To toggle OTP field visibility
  const [passwordVisible, setPasswordVisible] = useState(false); // To toggle password visibility
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar for showing validation
  const [snackbarMessage, setSnackbarMessage] = useState(""); // To hold the snackbar message
  const [loading, setLoading] = useState(false); // For loading indicator
  const [email, setEmail] = useState(""); // To track email input
  const [password, setPassword] = useState(""); // To track password input
  const [otp, setOtp] = useState(""); // To track OTP input
  const [emailDisabled, setEmailDisabled] = useState(false); // To disable the email field after Verify Email
  const [isPasswordReset, setIsPasswordReset] = useState(false); // To toggle password reset form after OTP verification
  const [newPassword, setNewPassword] = useState(""); // To track new password input
  const [confirmPassword, setConfirmPassword] = useState(""); // To track confirm password input
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

    // Function to handle Enter key press based on the stage
    const handleKeyPress = (event, stage) => {
      if (event.key === "Enter") {
        event.preventDefault(); // Prevent default submission
        if (stage === "verifyEmail") {
          handleVerifyEmailClick();
        } else if (stage === "verifyOtp") {
          handleVerifyOtpClick();
        } else if (stage === "signUp") {
          handleValidation("signup");
        }
      }
    };


  // Handle Resend OTP button click
  const handleResendOtpClick = () => {
    if (email) {
      // Dispatch sendOtp which already handles snackbar on success
      dispatch(sendOtp(email, openSnackbar)); // Resend OTP logic (same as verify)
    } else {
      // Show error if no email is provided
      openSnackbar("Email is required to resend OTP", "error");
    }
  };

  // Handle Sign Up button click - No validation, just loading spinner and move to Sign Up page
  const handleSignUpClick = () => {
    setIsSignUp(true);
    setTimeout(() => {
      navigate("/Login"); // Navigate to the login page after 4 seconds
    }, 3000); // 4000 milliseconds = 4 seconds
  };

  // Handle Verify Email button click
  const handleVerifyEmailClick = () => {
    console.log("handle verfiy email");
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex for email validation
    if (!email) {
      openSnackbar("Email is required to verify", "error"); // Show snackbar if email is empty
    } else if (!emailRegex.test(email)) {
      openSnackbar("Please enter a valid email address", "error"); // Show snackbar if email is invalid
    } else {
      setIsOTPVisible(true);
      dispatch(sendOtp(email, openSnackbar));
    }
  };

  // Handle OTP verification
  const handleVerifyOtpClick = () => {
    if (!otp) {
      openSnackbar("OTP is required", "error"); // Show snackbar if OTP is empty
    } else {
      // Perform OTP verification logic here
      dispatch(
        verifyOtp(email, otp, (success) => {
          if (success) {
            // On OTP verification success
            openSnackbar("OTP verified successfully!", "success");

            // Hide OTP field and show password reset fields
            setIsPasswordReset(true); // This will show the new password fields
            setEmailDisabled(true); // Disable the email field
            setIsOTPVisible(false); // Hide the OTP field
          } else {
            openSnackbar("OTP verification failed", "error");
          }
        })
      );
    }
  };

  // Toggle password visibility
  const handlePasswordToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Close snackbar notification
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleValidation = (val) => {
    // Define separate regex patterns for each password validation
    const lengthCheck = /.{10,}/; // At least 10 characters
    const uppercaseCheck = /[A-Z]/; // At least one uppercase letter
    const lowercaseCheck = /[a-z]/; // At least one lowercase letter
    const numberCheck = /\d/; // At least one number
    const specialCharCheck = /\W/; // At least one special character

    if (!email) {
      setSnackbarMessage("Email is required");
      setSnackbarOpen(true);
    } else if (!newPassword || !confirmPassword) {
      setSnackbarMessage("Password is required");
      setSnackbarOpen(true);
    } else if (!lengthCheck.test(newPassword)) {
      openSnackbar("Password must be at least 10 characters long.", "error");
    } else if (!uppercaseCheck.test(newPassword)) {
      openSnackbar(
        "Password must contain at least one uppercase letter.",
        "error"
      );
    } else if (!lowercaseCheck.test(newPassword)) {
      openSnackbar(
        "Password must contain at least one lowercase letter.",
        "error"
      );
    } else if (!numberCheck.test(newPassword)) {
      openSnackbar("Password must contain at least one number.", "error");
    } else if (!specialCharCheck.test(newPassword)) {
      openSnackbar(
        "Password must contain at least one special character.",
        "error"
      );
    } else if (newPassword !== confirmPassword) {
      openSnackbar(
        "New Password and Confirm Password must be the same.",
        "error"
      );
    } else if (val === "signup") {
      // Proceed with signup if all validations pass
      dispatch(
        signupClient(
          {
            username: email,
            password: newPassword,
          },
          openSnackbar,
          () => {
            setTimeout(() => {
              navigate("/Login"); // Navigate to login after 4 seconds delay
            }, 4000);
          }
        )
      );
    }
  };

  const renderSignUpPage = () => (
    <Box
      sx={{
        marginTop: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#8294C4",
        padding: 4,
        borderRadius: 4,
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
      component="form" // Adding form handling here
    onSubmit={(e) => {
      e.preventDefault(); // Prevent default form submission
      handleVerifyEmailClick(); // Trigger the logic for verifying email
    }}
      
      >
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        sx={{ color: "#000" }}>
        Sign Up
      </Typography>

      <Box component="div" noValidate sx={{ mt: 1 }}>
        {/* Show the email field */}
        <Typography
          variant="body1"
          sx={{ color: "#000", marginBottom: -1.5, letterSpacing: "0.1em" }}>
          Email <span style={{ color: "red" }}>*</span>
        </Typography>

        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email-signup"
          placeholder="Enter your email"
          name="email-signup"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Update email state
          onKeyDown={(e) => handleKeyPress(e, "verifyEmail")}
          autoFocus
          disabled={emailDisabled} // Disable the email field after Verify Email is clicked
          sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
        />

        {/* If OTP is visible, show the OTP field */}
        {isOTPVisible && (
          <>
            <Typography
              variant="body1"
              sx={{ color: "#000", marginBottom: 1, letterSpacing: "0.1em" }}>
              Enter OTP
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="otp"
              placeholder="Enter the OTP"
              name="otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)} // Update OTP state
              onKeyDown={(e) => handleKeyPress(e, "verifyOtp")}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
            />
          </>
        )}

        {/* Conditionally render the password reset fields if OTP is verified */}
        {isPasswordReset && (
          <>
            <Typography
              variant="body1"
              sx={{ color: "#000", marginBottom: 1, letterSpacing: "0.1em" }}>
              Enter New Password
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type={passwordVisible ? "text" : "password"}
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)} // Update new password state
              onKeyDown={(e) => handleKeyPress(e, "signUp")}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordToggle}>
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}{" "}
                      {/* Toggle Icon */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 10 }} // Restrict input length to 10 characters
            />
            {/* Password Strength Checker Component */}
            <PasswordStrengthChecker password={newPassword} />{" "}
            {/* Integrate the PasswordStrengthChecker component */}
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                fontSize: "16px",
                color: newPassword === confirmPassword ? "green" : "red",
              }}>
              {newPassword && confirmPassword
                ? newPassword === confirmPassword
                  ? "✔ Passwords match"
                  : "✘ Passwords do not match"
                : ""}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type={passwordVisible ? "text" : "password"}
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)} // Update confirm password state
              onKeyDown={(e) => handleKeyPress(e, "signUp")}
              sx={{ backgroundColor: "#fff", borderRadius: "4px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handlePasswordToggle}>
                      {passwordVisible ? <Visibility /> : <VisibilityOff />}{" "}
                      {/* Toggle Icon */}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 10 }} // Restrict input length to 10 characters
            />
            {/* Render the Sign Up button after OTP is verified */}
            {isPasswordReset && (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleValidation("signup");
                  console.log("Sign Up clicked");
                }}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#007bff",
                  "&:hover": {
                    backgroundColor: "#0056b3",
                  },
                }}>
                Sign Up
              </Button>
            )}
          </>
        )}

        {/* Conditionally render the Verify Email button only when OTP field is not visible */}
        {!isOTPVisible && !isPasswordReset && (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleVerifyEmailClick} // Trigger OTP and disable email
            sx={{
              mt: 3,
              mb: 2,
              backgroundColor: "#007bff",
              "&:hover": {
                backgroundColor: "#0056b3",
              },
            }}>
            Verify Email
          </Button>
        )}

        {/* Show the Verify OTP button only when the OTP field is visible */}
        {isOTPVisible && (
          <>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleVerifyOtpClick} // Verify OTP on click
              sx={{
                mt: 2,
                backgroundColor: "#007bff",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}>
              Verify OTP
            </Button>

            {/* Resend OTP button */}
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleResendOtpClick} // Verify OTP on click
              sx={{
                mt: 2,
                backgroundColor: "#007bff",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}>
              Resend OTP
            </Button>
          </>
        )}
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="sm">
      {/* Show loading spinner if loading is true */}
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
            marginTop: "20px",
          }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Conditionally render the login or signup page */}
          {renderSignUpPage()}
        </>
      )}

      {/* Snackbar for mandatory field validation */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000} // Keep the Snackbar for 3 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ mt: 3 }}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarMessage.includes("OTP") ? "success" : "error"} // Dynamically set 'success' for OTP, 'error' for others
          sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AuthenTication;
