import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getFolders,
  shiftFolder,
} from "../../../Redux/ActionCreators/FileFolderActionCreator/filefoldersActionCreators.js";
import { Snackbar, Alert } from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance.jsx";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SubNav from "../ManageGoal/SubNav.js";
import LoadingIndicator from "../../../scenes/Loader/index.jsx";
import { Box, TextField, IconButton, Menu, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableDroppableFolder from "./DragNDrop/index.jsx";
import useSharedLink from "../../../scenes/SharedLink/index.jsx";

import GoalAreaModal from "./GoalAreaModal/index.jsx";
import { useSnackbar } from "../../../components/SnackBarProvider.jsx";
const ManageGoal = () => {
  const { openSnackbar } = useSnackbar();
  const sharedLink = useSharedLink();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(true);
  // const [rootFolders, setRootFolders] = useState([]);
  const [editingFolderId, setEditingFolderId] = useState(null);
  const [isGoalAreaModalOpen, setIsGoalAreaModalOpen] = useState(false);
  const [currentEditingId, setCurrentEditingId] = useState(null);

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentFolderId, setCurrentFolderId] = useState(null);
  const [folderName, setFolderName] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const { allFolders, rootFolders, loading, error, errorcount } = useSelector(
    (state) => ({
      allFolders: state.filefolders.allFolders,
      rootFolders: state.filefolders.folders,
      loading: state.filefolders.isLoading,
      error: state.filefolders.error,
      errorcount: state.filefolders.errorcount,
    }),
    shallowEqual
  );

  useEffect(() => {
    console.log("error", error);

    // setRootFolders([
    //   { data: { name: "Social" }, docId: "1" },
    //   { data: { name: "Behavioral" }, docId: "2" },
    //   { data: { name: "Communication" }, docId: "3" },
    // ]);
    console.log("came in manage goal", typeof openSnackbar);
    dispatch(getFolders("", openSnackbar));

    console.log("root", rootFolders);
    setIsLoading(false);
    // dispatch(s)
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (error) {
      setSnackbar({
        open: true,
        message: error,
        severity: "error",
      });
    }
    console.log("errorcount", errorcount);
  }, [errorcount]);

  const userFolders =
    rootFolders && rootFolders.filter((file) => file.data.parent === "");
  console.log("root after op", rootFolders);
  console.log("error out", error);

  const handleDrop = (draggedFolder, targetFolder) => {
    dispatch(shiftFolder(draggedFolder, targetFolder, openSnackbar));

    console.log("draggedFolder", draggedFolder);
    console.log("targetFolder", targetFolder);
    // Implement your logic to update the folder structure
  };

  const handleMenuOpen = (event, docId) => {
    setMenuAnchorEl(event.currentTarget);
    setCurrentFolderId(docId);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setCurrentFolderId(null);
  };

  const handleRename = (id, name) => {
    // Logic to rename the folder
    //handleMenuClose();
  };

  const handleDelete = (id) => {
    // Logic to delete the folder
    // if (window.confirm("Are you sure you want to delete this folder?")) {
    //   dispatch(deleteFolder(id, openSnackbar));
    //   handleMenuClose();
    // }
    openSnackbar("Deletion of Root folder is not allowed", "error");
  };
  const handleEdit = (docId) => {
    const payload = userFolders.find((folder) => folder.docId === docId);
    console.log("payload for edit", payload);
    setCurrentEditingId(docId); // Set the current editing ID
    setIsGoalAreaModalOpen(true); // Open the modal
    handleMenuClose();
  };

  const handleCloseModal = () => {
    setIsGoalAreaModalOpen(false); // Close the modal
  };

  const handleMoveTo = () => {
    // Logic to move the folder
    handleMenuClose();
  };
  const toggleEdit = (docId, currentName) => {
    setEditingFolderId(editingFolderId === docId ? null : docId);
    setFolderName(currentName);

    console.log("here");
  };

  const handleNameChange = (e) => {
    setFolderName(e.target.value);
    console.log(e, "e");
  };

  const saveName = (docId) => {
    setEditingFolderId(null);
    console.log("docId", docId);
    console.log("folderName", folderName);
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="staff-background">
      <>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        {isGoalAreaModalOpen && (
          <GoalAreaModal
            isOpen={isGoalAreaModalOpen}
            onClose={handleCloseModal}
            folderDetails={userFolders.find(
              (folder) => folder.docId === currentEditingId
            )} // Pass the current editing ID to the modal
          />
        )}
        <DndProvider backend={HTML5Backend}>
          {/* <SubNav currentFolder="root folder" /> */}
          {userFolders && userFolders.length > 0 && (
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                marginTop: "20px",
              }}>
              {userFolders.map(({ data, docId }) => (
                <DraggableDroppableFolder
                  allFolders={allFolders}
                  key={docId}
                  data={data}
                  docId={docId}
                  onDrop={handleDrop}
                  handleRename={handleRename}
                  handleDelete={handleDelete}
                  toggleEdit={toggleEdit}
                  editingFolderId={editingFolderId}
                  folderName={folderName}
                  handleNameChange={handleNameChange}
                  saveName={saveName}
                  handleMenuOpen={handleMenuOpen}
                  handleMenuClose={handleMenuClose}
                  menuAnchorEl={menuAnchorEl}
                  currentFolderId={currentFolderId}
                  handleMoveTo={handleMoveTo}
                  handleEdit={handleEdit}
                />
              ))}
            </Box>
          )}
        </DndProvider>
      </>
    </div>
  );
};

export default ManageGoal;
