import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  Link,
  TextField,
  Button,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Delete as DeleteIcon } from "@mui/icons-material";
import axiosInstance from "../../../components/AxiosInstance";
import useSharedLink from "../../../scenes/SharedLink";
import { themeStyles } from "../../../constants";
import { useSnackbar } from "../../../components/SnackBarProvider";
import { useNavigate } from "react-router-dom"; // Add this import at the top

const fileCategoryOptions = [
  { label: "InitialAssessment" },
  { label: "ConcurrentAssessment" },
  { label: "TreatmentPlan" },
  { label: "InsuranceCardFront" },
  { label: "InsuranceCardBack" },
  { label: "NeuropsychologyDocument" },
  { label: "Others" },
];

const insuranceNameOptions = [
  { label: "Aetna" },
  { label: "Horizon" },
  { label: "Anthem" },
  { label: "NJMedicare" },
  { label: "NJMedicaid" },
  { label: "Cigna" },
  { label: "UnitedHealthCare" },
  { label: "EmbelemHealth" },
  { label: "WellPoint" },
];

const fileExtensionOptions = [
  { label: "pdf" },
  { label: "doc" },
  { label: "docx" },
  { label: "xls" },
  { label: "xlsx" },
  { label: "jpg" },
  { label: "png" },
];

const ManageFiles = () => {
  const sharedLink = useSharedLink();
  const [rows, setRows] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const { openSnackbar } = useSnackbar();
  const [allRows, setAllRows] = useState([]); // State to keep all rows
  const [showInactive, setShowInactive] = useState(false); // State to control checkbox for showing inactive rows
  const [formData, setFormData] = useState({
    clientName: null,
    insuranceName: null,
    fileCategory: null,
    fileExtension: null,
  });
  const navigate = useNavigate(); // Initialize useNavigate hook at the beginning of the component

  const [clients, setClients] = useState([]);
  const [alertDanger, setAlertDanger] = useState(false); // State for showing alert
  const [alertContentDanger, setAlertContentDanger] = useState(""); // State for alert content
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientResponse = await axiosInstance.post(
          sharedLink + "/viewallclient",
          { withCredentials: true }
        );
        const clientsData = clientResponse.data.map((client) => ({
          label: client.firstName + " " + client.lastName,
          ...client,
        }));
        setClients(clientsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchFiles(); // Fetch all files initially
  }, [sharedLink]);

  useEffect(() => {
    if (showInactive) {
      setRows(allRows);
    } else {
      setRows(allRows.filter((row) => row.status === "Active"));
    }
  }, [showInactive, allRows]); // Filter rows based on showInactive checkbox

  const handleClientChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      clientName: newValue,
    }));
  };

  const handleInputChange = (field) => (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: newValue !== undefined ? newValue : event.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.clientName) {
      openSnackbar("Client  Name is required", "error");
      return;
    }

    // Construct the request body based on available criteria
    const requestBody = {};
    if (formData.clientName) requestBody.clientId = formData.clientName.id;
    if (formData.insuranceName)
      requestBody.insuranceName = formData.insuranceName.label;
    if (formData.fileCategory)
      requestBody.fileCategory = formData.fileCategory.label;
    if (formData.fileExtension)
      requestBody.fileExtension = formData.fileExtension.label;

    try {
      const response = await axiosInstance.post(
        sharedLink + "/getdocuments",
        requestBody,
        { withCredentials: true }
      );
      setRows(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
      setErrorMessage("Error fetching documents: " + error.message);
    }
  };

  const fetchFiles = () => {
    axiosInstance
      .post(sharedLink + "/getalldocuments", {})
      .then((response) => {
        setAllRows(response.data);
        setRows(response.data.filter((row) => row.status === "Active")); // Initially set active rows
      })
      .catch((error) => {
        console.error("Error fetching files:", error);
        setErrorMessage("Error fetching files: " + error.message);
      });
  };

  const handleDelete = (row) => {
    if (
      window.confirm(
        "Do you want to delete this file? Once deleted, it cannot be retrieved."
      )
    ) {
      const id = row.id;
      axiosInstance
        .post(sharedLink + "/deletedocument", { id })
        .then((response) => {
          console.log(response);
          // Update row status to Inactive
          setRows((prevRows) =>
            prevRows.map((r) =>
              r.id === id ? { ...r, status: "Inactive" } : r
            )
          );
          //comment it if you want to show the inactive row on deletion before reloading and without checkbox
          setAllRows((prevRows) =>
            prevRows.map((r) =>
              r.id === id ? { ...r, status: "Inactive" } : r
            )
          );
        })
        .catch((error) => {
          console.error("Error deleting file:", error);
          setErrorMessage("Error deleting file: " + error.message);
        });
    }
  };

  const handleDownload = (fileName) => {
    axiosInstance
      .post(
        sharedLink + "/downloadfile",
        { fileName },
        { responseType: "blob" }
      ) // Pass fileName in the request body
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
        setErrorMessage("Error downloading file: " + error.message);
      });
  };

  const handleShowInactiveChange = (event) => {
    setShowInactive(event.target.checked);
  };

  const handleClose = () => {
    setAlertDanger(false);
  };

  const columns = [
    // { field: "clientId", headerName: "Client ID", width: 150 },
    { field: "clientName", headerName: "Client Name", width: 150 },
    { field: "dateOfBirth", headerName: "Date of birth", width: 150 },

    { field: "insuranceName", headerName: "Insurance Name", width: 200 },
    { field: "policyNumber", headerName: "Policy Number", width: 200 },
    { field: "fileCategory", headerName: "File Category", width: 200 },
    { field: "status", headerName: "Status", width: 150 },
    { field: "fileDescription", headerName: "File Description", width: 150 },
    {
      field: "fileName",
      headerName: "File Name",
      width: 500,
      //making the link disable
      renderCell: (params) =>
        params.row.status === "Inactive" ? (
          <Typography
            variant="body2"
            sx={{
              color: "grey",
              textDecoration: "none",
            }}
          >
            {params.value}
          </Typography>
        ) : (
          <Link
            component="button"
            variant="body2"
            onClick={() => handleDownload(params.value)}
            sx={{
              cursor: "pointer",
              color: "#3A57E8",
              textDecoration: "underline",
            }}
          >
            {params.value}
          </Link>
        ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => handleDelete(params.row)}
            sx={{ color: "#000000" }} // Set color to black
            disabled={params.row.status === "Inactive"} // Disable delete button if status is Inactive
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box sx={{ width: "80%", mt: 2, ml: "auto", mr: "auto" }}>
      <Typography
        variant="h4"
        sx={{ mb: 10, textAlign: "center", fontWeight: "bold", fontSize: 35 }}
      >
        Manage Files
      </Typography>

      <form style={{ margin: "20px" }} onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          <Autocomplete
            fullWidth
            options={clients}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    Client Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.clientName}
            onChange={handleClientChange}
            sx={{ flex: "100%", ...themeStyles.textField }}
          />

          <Autocomplete
            fullWidth
            options={insuranceNameOptions}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    Insurance Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.insuranceName}
            onChange={handleInputChange("insuranceName")}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
          />

          <Autocomplete
            fullWidth
            freeSolo
            options={fileCategoryOptions}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="File Category"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.fileCategory}
            onChange={handleInputChange("fileCategory")}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
          />

          <Autocomplete
            fullWidth
            options={fileExtensionOptions}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="File Extension"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.fileExtension}
            onChange={handleInputChange("fileExtension")}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
          />

          <Button
            type="submit"
            variant="contained"
            className="animated-input"
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              mt: 2,
              ml: 2,
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}
          >
            Search
          </Button>
        </Box>
      </form>

      {errorMessage && (
        <Typography variant="body1" sx={{ color: "red", textAlign: "center" }}>
          {errorMessage}
        </Typography>
      )}

      {alertDanger && (
        <Alert severity="error" onClose={handleClose}>
          {alertContentDanger}
        </Alert>
      )}

      <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showInactive}
              onChange={handleShowInactiveChange}
              className="animated-input"
            />
          }
          label="Show Inactive Files"
          className="animated-input"
        />
        {/* Add File Upload Button */}
        <Button
          type="button"
          variant="contained"
          className="animated-input"
          onClick={() => navigate("/UploadFile")} // Navigate to UploadFile page
          sx={{
            backgroundColor: "#3A57E8",
            color: "white",
            fontWeight: "bold",
            borderRadius: "20px",
            padding: "8px 16px",
            ml: 2, // Add margin to the left for alignment
            "&:hover": {
              backgroundColor: "#0059b2",
            },
          }}
        >
          File Upload
        </Button>
      </Box>
      <Box sx={{ height: "calc(100vh - 300px)", width: "100%" }}>
        <DataGridPro
          rows={rows}
          columns={columns}
          pageSize={5}
          // checkboxSelection
          className="animated-input"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#3A57E8",
              color: "white",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "nowrap",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: "auto",
            },
            "& .MuiDataGrid-footerContainer": {
              display: "none",
            },
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          backgroundColor: "#3A57E8",
          color: "white",
          borderTop: "1px solid #fff",
        }}
      >
        <Typography variant="subtitle1">Total Rows: {rows.length}</Typography>
      </Box>
    </Box>
  );
};

export default ManageFiles;
