import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import "./index.css"; // Import the CSS file for animations

const MoveFolderDialog = ({
  open,
  onClose,
  sourceFolderName,
  allFolders,
  sourceFolderId,
  handleMoveTo,
}) => {
  const [selectedDestination, setSelectedDestination] = useState(null);

  const handleMove = () => {
    if (selectedDestination) {
      console.log("calling move to", selectedDestination);
      handleMoveTo(selectedDestination);
      onClose(); // Close dialog after move
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      {" "}
      {/* Set maxWidth to control the dialog size */}
      <DialogTitle>Move Folder</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} alignItems="center">
          {/* Source folder name on the left */}
          <Grid item xs={5}>
            <TextField
              label="Source Folder"
              value={sourceFolderName} // source folder name
              fullWidth
              disabled
              sx={{ minHeight: "50px", width: "100%", marginTop: "10px" }} // Fix height and width to avoid shrinking
            />
          </Grid>

          {/* Animated Arrow and Dot in the middle */}
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <Box className="arrow-container slide-arrow">
              <div className="animated-arrow">→</div>
            </Box>
          </Grid>
          {/* Searchable Dropdown for destination */}
          <Grid item xs={5}>
            <Autocomplete
              options={allFolders.filter(
                (folder) => folder.docId !== sourceFolderId
              )} // Exclude the current folder from options
              getOptionLabel={(option) => option.data.name}
              onChange={(event, newValue) =>
                setSelectedDestination(newValue ? newValue : null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Destination Folder"
                  variant="outlined"
                  sx={{ minHeight: "50px", width: "100%", marginTop: "10px" }} // Fix height and width to avoid shrinking
                />
              )}
            />
          </Grid>
        </Grid>

        {/* Save and Cancel buttons */}
        <Box mt={2} display="flex" justifyContent="space-between">
          <Button
            onClick={onClose}
            color="error"
            variant="contained"
            className="animated-input"
            sx={{
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
            }}>
            Cancel
          </Button>
          <Button
            onClick={handleMove}
            variant="contained"
            className="animated-input"
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}
            disabled={!selectedDestination}>
            Save
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default MoveFolderDialog;
