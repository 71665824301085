import { toast } from "react-toastify";

import {
  SET_LOADING,
  SET_DATA,
  ADD_DATA,
  UPDATE_DATA,
  SET_FOLDERS,
  ADD_FOLDER,
  RESET_FOLDERS_FILES,
  DELETE_DATA,
  SHIFT_FOLDER,
  SHIFT_DATA,
  DELETE_FOLDER,
  UPDATE_FOLDER,
  UPDATE_FOLDER_NAME,
  SET_ALLFOLDERS,
} from "../../Actions/actionTypes";

import useSharedLink from "../../../scenes/SharedLink";
import axiosInstance from "../../../components/AxiosInstance";

const setLoading = (data) => ({
  type: SET_LOADING,
  payload: data,
});
const SetData = (data) => ({
  type: SET_DATA,
  payload: data,
});
const setFolders = (data) => ({
  type: SET_FOLDERS,
  payload: data,
});
const setAllFolders = (data) => ({
  type: SET_ALLFOLDERS,
  payload: data,
});
function extractFolders(folder, parent = "1", foldersArray = []) {
  // Add the current folder to the array
  foldersArray.push({
    data: {
      name: folder.goalBankAreaName,
      parent: parent,
      path: folder.goalBankAreaPath,
      description: folder.goalBankAreaDescription,
      status: folder.goalBankAreaStatus,
      enteredBy: folder.enteredBy,
    },
    docId: folder.id,
  });

  // If the folder has children, recursively add them to the same array
  if (folder.children && folder.children.length > 0) {
    folder.children.forEach((child) =>
      extractFolders(child, folder.id + "", foldersArray)
    );
  }

  return foldersArray; // Return the accumulated array
}
export const getFolders = (id, openSnackbar) => async (dispatch) => {
  console.log("Sending ", {
    id: id === "" || id === "root folder" ? 1 : parseInt(id),
  });
  try {
    axiosInstance
      .post(useSharedLink() + "/getgoalbankarea", {
        id: id === "" || id === "root folder" ? "1" : id,
      })
      .then((res) => {
        console.log("getFolders response", res);
        if (res.status === 200) {
          const folders =
            id !== "" || id !== "root folder"
              ? res.data.data.children.map((folder) => {
                  return {
                    data: {
                      name: folder.goalBankAreaName,
                      parent: res.data.data.id,
                      path: folder.goalBankAreaPath,
                      description: folder.goalBankAreaDescription,
                      status: folder.goalBankAreaStatus,
                      enteredBy: folder.enteredBy,
                    },

                    docId: folder.id,
                  };
                })
              : [];
          folders.push({
            data: {
              name: res.data.data.goalBankAreaName,
              parent: "",
              path: res.data.data.goalBankAreaPath,
              description: res.data.data.goalBankAreaDescription,
              status: res.data.data.goalBankAreaStatus,
              enteredBy: res.data.data.enteredBy,
            },
            docId: res.data.data.id,
          });

          if (id === "" || id === "root folder") {
            const allFolders = extractFolders(res.data.data);

            dispatch(setAllFolders(allFolders));
            console.log("allfolders", allFolders);
          }
          console.log("folders", folders);
          dispatch(setFolders(folders));
        }
      })
      .catch((err) => {
        if (openSnackbar) openSnackbar(err, "error");
      });
  } catch (err) {
    openSnackbar(err, "error");
  }
};

const Folderadd = (data) => ({
  type: ADD_FOLDER,
  payload: data,
});

export const addFolder =
  (name, description, parent, path, openSnackbar) => (dispatch) => {
    console.log("addFolderUser", name, parent, path);

    try {
      const sharedLink = useSharedLink();
      const tosend = {
        goalBankAreaName: name,
        parent: {
          id: parent,
        },
        lastUpdatedBy: localStorage.getItem("username"),
        enteredBy: localStorage.getItem("username"),
        goalBankAreaStatus: "Active",
        goalBankAreaPath: path,
        goalBankAreaDescription: description,
      };
      console.log("sending for creation", tosend);
      axiosInstance
        .post(sharedLink + "/addgoalbankarea", tosend)
        .then((res) => {
          console.log("addFolder response", res);
          if (res.status === 200) {
            console.log("addFolder", res);

            console.log("addFolder", {
              data: { name: name, parent: parent, path: path },
              docId: res.data[0].id,
            });
            dispatch(
              Folderadd({
                data: {
                  name: name,
                  parent: parent,
                  path: path,
                  status: "Active",
                  description: description,
                  enteredBy: localStorage.getItem("username"),
                },
                docId: res.data[0].id,
              })
            );
            openSnackbar("Successfully added!", "success");
          }
        })
        .catch((err) => {
          console.log("addFolder", err);
          openSnackbar(err, "error");
        });
    } catch (err) {
      openSnackbar(err, "error");
    }
  };

const folderUpdate = (data) => ({
  type: UPDATE_FOLDER,
  payload: data,
});
export const updateFolder =
  (docId, name, description, status, enteredBy, parent, path, openSnackbar) =>
  (dispatch) => {
    console.log("updating folder");
    try {
      const sharedLink = useSharedLink();
      const tosend = {
        id: docId,
        goalBankAreaName: name,
        goalBankAreaDescription: description,
        goalBankAreaStatus: status,
        enteredBy: enteredBy,
        lastUpdatedBy: localStorage.getItem("username"),
        parent: {
          id: parent,
        },
        goalBankAreaPath: path,
      };
      console.log("sending for updation", tosend);
      axiosInstance
        .post(sharedLink + "/updategoalbankarea", tosend)
        .then((res) => {
          console.log("updateFolder response", res);
          if (res.status === 200) {
            console.log("updateFolder", res);
            dispatch(
              folderUpdate({
                data: { name, description, status, parent, enteredBy, path },
                docId,
              })
            );
            openSnackbar("Updated Successfully!", "success");
          }
        })
        .catch((err) => {
          console.log("updateFolder", err);
          openSnackbar(err, "error");
        });
    } catch (err) {
      openSnackbar(err, "error");
    }
  };
const folderDelete = (data) => ({
  type: DELETE_FOLDER,
  payload: data,
});
export const deleteFolder = (docId, openSnackbar) => (dispatch) => {
  try {
    const sharedLink = useSharedLink();
    console.log("deleteFolder", docId);
    const tosend = {
      id: docId,
    };
    console.log("sending for deletion", tosend);
    axiosInstance
      .post(sharedLink + "/removegoalbankarea", tosend)
      .then((res) => {
        console.log("deleteFolder response", res);
        if (res.status === 200) {
          console.log("deleteFolder", res);
          dispatch(folderDelete(docId));
          openSnackbar("Deleted Successfully!", "success");
        }
      })
      .catch((err) => {
        console.log(err);
        openSnackbar(err, "error");
      });
  } catch (err) {
    openSnackbar(err, "error");
    console.log(err);
  }
};

export const shiftFolder =
  (draggedFolder, TargetFolder, openSnackbar) => (dispatch) => {
    try {
      console.log("inside");
      console.log(draggedFolder, " ", TargetFolder);

      // Create a new folder object with updated parent
      const updatedFolder = {
        ...draggedFolder,
        data: {
          ...draggedFolder.data,
          parent: TargetFolder.docId,
          path:
            TargetFolder.data.path +
            "/" +
            TargetFolder.data.name +
            ":" +
            TargetFolder.docId,
        },
      };

      dispatch(
        updateFolder(
          updatedFolder.docId,
          updatedFolder.data.name,
          updatedFolder.data.description,
          updatedFolder.data.status,
          updatedFolder.data.enteredBy,
          updatedFolder.data.parent,
          updatedFolder.data.path,
          openSnackbar
        )
      );
    } catch (err) {
      console.log("error", err);
    }
  };
