import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  TextField,
  Typography,
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axiosInstance from "../../../components/AxiosInstance";
import useSharedLink from "../../../scenes/SharedLink"; // Assume this hook is available
import { themeStyles } from "../../../constants";
import { useSnackbar } from "../../../components/SnackBarProvider"; // Import useSnackbar
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import isEqual from "lodash.isequal"; // Import lodash.isequalx`
import { cleanText } from "../../../utils/textUtils";

const statusOptions = [{ label: "Active" }];

const fileCategoryOptions = [
  { label: "InitialAssessment" },
  { label: "ConcurrentAssessment" },
  { label: "TreatmentPlan" },
  { label: "InsuranceCardFront" },
  { label: "InsuranceCardBack" },
  { label: "NeuropsychologyDocument" },
  { label: "Others" },
];

const insuranceNameOptions = [
  { label: "Aetna" },
  { label: "Horizon" },
  { label: "Anthem" },
  { label: "NJMedicare" },
  { label: "NJMedicaid" },
  { label: "Cigna" },
  { label: "UnitedHealthCare" },
  { label: "EmbelemHealth" },
  { label: "WellPoint" },
];

const UploadFile = () => {
  const sharedLink = useSharedLink();
  const [formData, setFormData] = useState({
    clientName: null,
    insuranceName: null,
    startDate: null,
    policyNumber: "",
    status: { label: "Active" }, // Default status to Active
    fileCategory: null,
    fileDescription: "",
    fileName: "", // New field for uploaded file name
    file: null, // New field for the uploaded file object
  });
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const [initialState, setInitialState] = useState(null); // State to hold initial form state
  const [unsavedChanges, setUnsavedChanges] = useState(false); // State for tracking unsaved changes
  const [unsavedChangesDialogOpen, setUnsavedChangesDialogOpen] =
    useState(false); // State for controlling the unsaved changes dialog

  const { openSnackbar } = useSnackbar(); // Use useSnackbar for notifications
  const [showManageFileButton, setShowManageFileButton] = useState(false); // New state to control visibility of Manage File button

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientResponse = await axiosInstance.post(
          sharedLink + "/viewallclient",
          {
            withCredentials: true,
          }
        );
        const clientsData = clientResponse.data.map((client) => ({
          label: client.firstName + " " + client.lastName,
          ...client,
        }));
        setClients(clientsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [sharedLink]);

  useEffect(() => {
    setInitialState(JSON.parse(JSON.stringify(formData))); // Set initial form state
  }, []);

  const handleClientChange = (event, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      clientName: newValue,
      policyNumber: newValue ? newValue.policyNumber : "",
      startDate: newValue ? dayjs(newValue.dateOfBirth) : null,
      insuranceName: newValue ? { label: newValue.insuranceName } : null, // Auto-populate Insurance Name
      fileCategory: null, // Reset File Category
      fileDescription: "", // Reset File Description
      fileName: "", // Reset uploaded file name
      file: null, // Reset the actual uploaded file
    }));
  };

  const handleInputChange = (field) => (event) => {
    const cleanValue = cleanText(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      [field]: cleanValue,
    }));
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        fileName: file.name,
        file: file, // Store the file object
      }));
    }
  };

  const handleFileRemove = () => {
    setFormData((prevData) => ({
      ...prevData,
      fileName: "",
      file: null, // Remove the file object
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation for required fields
    if (!formData.clientName || !formData.fileCategory || !formData.file) {
      if (!formData.clientName) {
        openSnackbar("Client Name is required", "error"); // Show error notification
        return;
      }
      
      if (!formData.fileCategory) {
        openSnackbar("File Category is required", "error"); // Show error notification
        return;
      }
    
      if (!formData.file) {
        openSnackbar("File is required", "error"); // Show error notification
        return;
      }
    }

    if (formData.fileCategory === "Others" && !formData.fileDescription) {
      openSnackbar("File Description is required when 'Others' is selected as File Category", "error");
      return;
    }
  
    

    // Convert file to base64 string
    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    };

    let base64File = "";
    if (formData.file) {
      base64File = await toBase64(formData.file);
    }

    const clientInfo = {
      clientId: formData.clientName?.id || null, // Pass the clientId
      clientName: formData.clientName?.label || "", // Include clientName in the payload
      dateOfBirth: formData.startDate?.format("YYYY-MM-DD") || "", // Include dateOfBirth in the payload
      insuranceName: formData.insuranceName?.label || "",
      policyNumber: formData.policyNumber,
      status: formData.status?.label || "",
      fileCategory: formData.fileCategory || "", // Directly use fileCategory without .label
      fileDescription: formData.fileDescription,
      fileName: formData.fileName,
      data: base64File, // Add base64 encoded file data
      fileExtension: formData.fileName.split(".").pop(), // Extract file extension from file name
      lastUpdatedBy: localStorage.getItem("username"),
      enteredBy: localStorage.getItem("username"),
    };

    try {
      await axiosInstance.post(sharedLink + "/adddocument", clientInfo, {
        withCredentials: true,
      }); // Updated API endpoint
      console.log("File Added:", clientInfo);

      openSnackbar("File Added Successfully", "success"); // Show success notification
      // Set the new state to show the Manage File button
      setShowManageFileButton(true);

      setFormData({
        clientName: null,
        startDate: null,
        insuranceName: null,
        policyNumber: "",
        status: { label: "Active" },
        fileCategory: "", // Reset fileCategory to an empty string
        fileDescription: "",
        fileName: "",
        file: null, // Reset the file object
      });
    } catch (error) {
      console.error("Error submitting data:", error);
      openSnackbar("Error in Creating Client Info", "error"); // Show error notification
    }
  };

  const checkForUnsavedChanges = useCallback(() => {
    if (initialState) {
      const currentState = JSON.parse(JSON.stringify(formData));
      setUnsavedChanges(!isEqual(initialState, currentState));
    }
  }, [formData, initialState]);

  useEffect(() => {
    checkForUnsavedChanges();
  }, [formData, checkForUnsavedChanges]);

  const handleGoBack = () => {
    if (unsavedChanges) {
      setUnsavedChangesDialogOpen(true);
    } else {
      navigate("/dashboard"); // Navigate to dashboard if no unsaved changes
    }
  };

  const handleCloseUnsavedChangesDialog = (confirm) => {
    setUnsavedChangesDialogOpen(false);
    if (confirm) {
      navigate("/dashboard");
    }
  };

  return (
    <div>
      <Typography
        variant="h4"
        sx={{
          mb: 10,
          textAlign: "center",
          fontWeight: "bold",
          fontSize: 35,
          ...themeStyles.textField,
        }}
      >
        File Upload
      </Typography>
      <form style={{ margin: "20px" }} onSubmit={handleSubmit}>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
          <Autocomplete
            fullWidth
            options={clients}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    Client Name<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.clientName}
            onChange={handleClientChange}
            sx={{ flex: "100%", ...themeStyles.textField }}
          />

          <DatePicker
            label="Date of Birth"
            value={formData.startDate}
            onChange={(newValue) =>
              setFormData((prevData) => ({ ...prevData, startDate: newValue }))
            }
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                margin="normal"
                disabled={Boolean(formData.clientName)} // Ensures the field is disabled when a client is selected
              />
            )}
            sx={{ flex: "1 1 48%", ...themeStyles.textField, mt: 2 }}
            disabled={Boolean(formData.clientName)}
          />

          <TextField
            fullWidth
            label={
              <span>
                Insurance Name<span style={{}}></span>
              </span>
            }
            variant="outlined"
            margin="normal"
            value={formData.insuranceName?.label || ""} // Display the auto-populated insurance name
            disabled={Boolean(formData.clientName)} // Ensure the field is disabled when a client is selected
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
          />

          <TextField
            label={
              <span>
                Policy Number<span style={{}}></span>
              </span>
            }
            variant="outlined"
            value={formData.policyNumber}
            className="animated-input"
            onChange={handleInputChange("policyNumber")}
            fullWidth
            margin="normal"
            disabled={Boolean(formData.clientName)} // Ensures the field is disabled when a client is selected
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
          />
          <Autocomplete
            fullWidth
            options={statusOptions}
            getOptionLabel={(option) => option.label}
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Status"
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.status}
            onChange={handleInputChange("status")}
            sx={{ ...themeStyles.textField, flex: "1 1 48%" }}
          />

          <Autocomplete
            fullWidth
            freeSolo
            options={fileCategoryOptions}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.label
            }
            className="animated-input"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={
                  <span>
                    File Category<span style={{ color: "red" }}>*</span>
                  </span>
                }
                variant="outlined"
                margin="normal"
              />
            )}
            value={formData.fileCategory || ""}
            onChange={(event, newValue) => {
              const selectedCategory = typeof newValue === "string" ? newValue : newValue?.label || "";
              
              setFormData((prevData) => ({
                ...prevData,
                fileCategory: selectedCategory,
                // Clear fileDescription if changing from "Others" to something else
                fileDescription: selectedCategory === "Others" ? prevData.fileDescription : "",
              }));
            }}
            sx={{ flex: "1 1 48%", ...themeStyles.textField }}
          />

          <TextField
            label={
              <span>
                File Description
                {formData.fileCategory === "Others" && (
                  <span style={{ color: "red"}}> *</span> // Conditionally render red *
                )}
              </span>
            }
            variant="outlined"
            value={formData.fileDescription}
            className="animated-input"
            onChange={handleInputChange("fileDescription")}
            fullWidth
            margin="normal"
            inputProps={{ maxLength: 30 }}
            InputLabelProps={{ shrink: true }} // Ensures the label stays up when there's input
            sx={{
              ...themeStyles.textField,
              flex: "1 1 48%",
            }}
          />

          {/* File upload field and button */}
          <Box
            sx={{
              display: "flex",
              gap: 1,
              width: "57%",
            }}
          >
            <TextField
              className="animated-input"
              label={
                <span>
                  Upload File<span style={{ color: "red" }}>*</span>
                </span>
              }
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
                startAdornment: formData.fileName && (
                  <InputAdornment position="start">
                    <Chip
                      className="animated-input"
                      label={formData.fileName}
                      onDelete={handleFileRemove}
                      color="primary"
                      variant="outlined"
                      sx={{ marginRight: "800px" }}
                    />
                  </InputAdornment>
                ),
              }}
              sx={{
                ...themeStyles.textField,
              }}
            />
            <Button
              variant="contained"
              component="label"
              className="animated-input"
              sx={{
                backgroundColor: "#3A57E8",
                color: "white",
                fontWeight: "bold",
                borderRadius: "20px",
                padding: "12px 24px",
                fontSize: "16px",
                height: "48px",
                mt: 2.5,
                ml: 4,
                "&:hover": {
                  backgroundColor: "#0059b2",
                },
                alignSelf: "flex-start",
              }}
            >
              Upload
              <input
                type="file"
                hidden
                onChange={handleFileUpload}
                accept="application/pdf"
              />
            </Button>
          </Box>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "flex-end", mt: 10, gap: 2 }}
        >
          {/* <Button
            type="button"
            variant="contained"
            className="animated-input"
            onClick={handleGoBack} // Handle Go Back button click
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}>
            GO BACK
          </Button> */}

          <Button
            type="submit"
            variant="contained"
            className="animated-input"
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}
          >
            Submit
          </Button>
          {/* Conditionally render Manage File button */}

          <Button
            type="button"
            variant="contained"
            // className="animated-input"
            onClick={() => navigate("/ManageFile")} // Navigate to ManageFile path
            sx={{
              backgroundColor: "#3A57E8",
              color: "white",
              fontWeight: "bold",
              borderRadius: "20px",
              padding: "12px 24px",
              fontSize: "16px",
              height: "48px",
              "&:hover": {
                backgroundColor: "#0059b2",
              },
            }}
          >
            Manage File
          </Button>
        </Box>
      </form>

      {/* Unsaved changes dialog */}
      <Dialog
        open={unsavedChangesDialogOpen}
        onClose={() => handleCloseUnsavedChangesDialog(false)}
      >
        <DialogTitle>Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have unsaved changes. Are you sure you want to leave this page?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseUnsavedChangesDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleCloseUnsavedChangesDialog(true)}
            color="primary"
          >
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadFile;
