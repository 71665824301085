import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Snackbar,
  Divider,
  FormControl,
  InputLabel,
  Autocomplete,
  Typography,
  autocompleteClasses,
  InputAdornment,
  Tooltip,
  Dialog,
  DialogContent,
  TextareaAutosize,
  Menu,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {
  themeStyles,
  buttonStyle,
  SESSION_NOTE_97155,
  UPDATE,
} from "../../constants";

import moment from "moment";
import "./index.css";

import { Alert } from "@mui/material";
import { tokens } from "../../theme";
import SignaturePad from "react-signature-canvas";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../../components/AxiosInstance";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import { useState, useEffect, useRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Field, Form } from "formik";
import SampleModal from "../SamplePdFModal/index";
import dayjs from "dayjs";
import * as yup from "yup";
import { useContext } from "react";
import { AuthContext } from "../SessionManagement/authContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import LoadingIndicator from "../Loader";
import { formatDate } from "@fullcalendar/core";
import { useTheme } from "@emotion/react";
import { useLocation, useNavigate } from "react-router-dom";
import useSharedLink from "../SharedLink";
import { usePermissions } from "../../Phase3/CheckPermission";
const UpdateSessionNote97155 = () => {
  const theme = useTheme();
  const sharedLink = useSharedLink();
  const { checkPermission, giveStaff, giveClients } = usePermissions();
  const isDisabled = checkPermission(SESSION_NOTE_97155, "U") == false;
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const from = location.state?.from || "Unknown";
  const [previouspagevalues, setPreviousPageValues] = useState(
    location.state?.previouspagevalues
  );
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("sessionId");
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState("");

  const [facilities, setFacilities] = useState([]);
  const clients = giveClients(SESSION_NOTE_97155, UPDATE);
  const staffs = giveStaff(SESSION_NOTE_97155, UPDATE);
  const [supervisor, setSupervisor] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [alertDanger, setAlertDanger] = useState(false);
  const [alertContentDanger, setAlertContentDanger] = useState("");
  const [info, setInfo] = useState(false);
  const [infoContent, setInfoContent] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const sessionType = [
    "Adaptive Behaviour Treatment",
    "Supervision",
    "Parent training",
    "Initial assessment",
    "Concurrent assessment",
    "Other",
  ];
  const placeOfService = ["Home", "School", "Other"];
  const diagnosisCode = ["F84.0", "Other"];
  const procedureCode = ["97151", "97153", "97156", "97155", "Other"];
  const moDifier = ["HM", "HN", "HO", "Other"];
  const parentOrGurdian = ["Parent", "Gurdian", "Other"];
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [modalSize, setModalSize] = useState("small");

  const handleImageModal = (pdf, size = "small") => {
    setSelectedPdf(pdf);
    setModalSize(size);
    setIsImageModalOpen(true);
  };

  const [sessionparticipantsState, setSessionParticipants] = useState({
    child: false,
    parent: false,
    bcba: false,
    behaviourtechnician: false,
    other: false,
  });

  const [moreInfoState, setMoreInfoState] = useState({
    optiona: false,
    otherinfocheckbox: false,
  });
  const sessionParticipantsLabels = {
    child: "Child",
    parent: "Parent",
    bcba: "BCBA",
    behaviourtechnician: "Behaviour Technician",
    other: "Other",
  };

  const moreInfoLabels = {
    optiona: "Direct Supervision of RBT/BT",
    otherinfocheckbox: "Other",
  };

  const updateStateFromArray = (array, labels, setState) => {
    const stateUpdates = {};
    for (const item of array) {
      const key = Object.keys(labels).find((key) => labels[key] === item);
      console.log(labels);
      console.log(key);
      if (key) {
        stateUpdates[key] = true;
      }
    }
    setState((prevState) => ({ ...prevState, ...stateUpdates }));
    console.log(moreInfoState);
  };
  const handleMoreInformation = (event) => {
    setMoreInfoState({
      ...moreInfoState,
      [event.target.name]: event.target.checked,
    });
  };

  const disableFutureDates = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Disable dates that are before today
    return date > today;
  };

  const sigSupervisor = useRef(null);

  const clearSupervisorSignature = () => {
    setIsSignaturePresent(false);
    sigSupervisor.current.clear();
  };

  const handleChange1 = (event) => {
    setSessionParticipants({
      ...sessionparticipantsState,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSupervisorSignature = (event) => {
    console.log("hye");
    console.log(event.target.value);
    console.log(sigSupervisor.current.isEmpty());
    if (sigSupervisor.current.isEmpty()) {
      setIsSignaturePresent(false);
    } else {
      setIsSignaturePresent(true);
    }
  };

  useEffect(() => {
    console.log(localStorage.getItem("role"));
    async function fetchData(retryCount = 0) {
      try {
        const userRole = localStorage.getItem("role");
        const [supervisorResponse, facilitiesResponse, sessionNoteResponse] =
          await Promise.all([
            axiosInstance.post(
              sharedLink + "/roles/supervisorofrole",
              { role: userRole },
              { withCredentials: true }
            ),
            axiosInstance.post(sharedLink + "/viewallfacility", {
              withCredentials: true,
            }),

            axiosInstance.post(
              sharedLink + "/viewasessionnote",
              { id: sessionId, procedurecode: "97155" },
              { withCredentials: true }
            ),
          ]);

        setSupervisor(supervisorResponse.data);
        setFacilities(facilitiesResponse.data);

        console.log("sesion data", sessionNoteResponse.data[0]);
        const initialValues = {
          facilityid: {
            id: sessionNoteResponse.data[0].facilityid,
            facilityName: sessionNoteResponse.data[0].facilityName,
          },
          clientid: {
            id: sessionNoteResponse.data[0].clientId,
            firstName: sessionNoteResponse.data[0].clientFirstName,
            lastName: sessionNoteResponse.data[0].clientLastName,
          },
          staffid: {
            id: sessionNoteResponse.data[0].staffId,
            firstName: sessionNoteResponse.data[0].staffFirstName,
            lastName: sessionNoteResponse.data[0].staffLastName,
          },
          supervisorid: {
            id: sessionNoteResponse.data[0].supervisorId,
            name:
              sessionNoteResponse.data[0].supervisorFirstName +
              " " +
              sessionNoteResponse.data[0].supervisorLastName,
          },
          insurancename: sessionNoteResponse.data[0].insuranceName,
          insurancepolicynumber:
            sessionNoteResponse.data[0].insurancePolicyNumber,

          sessiondate: dayjs(sessionNoteResponse.data[0].sessionDate),
          sessiontime: [
            dayjs(sessionNoteResponse.data[0].sessionStartTime),
            dayjs(sessionNoteResponse.data[0].sessionEndTime),
          ],

          sessiontype: sessionNoteResponse.data[0].sessionType,
          placeofservicecode: sessionNoteResponse.data[0].placeOfServiceCode,
          otherplaceofservice: sessionNoteResponse.data[0].otherPlaceOfService,
          modifier: sessionNoteResponse.data[0].modifier,
          diagnosiscode1: sessionNoteResponse.data[0].diagnosisCode1,
          otherdiagnosiscode: sessionNoteResponse.data[0].otherDiagnosisCode,
          procedurecode: "97155",
          sessionparticipants: "",
          othersessionparticipants:
            sessionNoteResponse.data[0].otherSessionParticipants,

          sessionsummary: sessionNoteResponse.data[0].sessionSummary,

          parentorgurdianname: sessionNoteResponse.data[0].parentOrGurdianName,

          supervisorsignaturedate: null,
          sessionstatus: sessionNoteResponse.data[0].sessionStatus,

          supervisorsignatureexist:
            sessionNoteResponse.data[0].supervisorSignatureExist,
          feedbackrbt: sessionNoteResponse.data[0].feedbackRBT,
          supervisionprovided: "",
          othersupervisionprovided:
            sessionNoteResponse.data[0].otherSupervisionProvided,
          primarytargetareaaddressed:
            sessionNoteResponse.data[0].primaryTargetAreas,
          nextsessionplan: sessionNoteResponse.data[0].nextSessionPlan,

          supervisorImage: null,
        };
        if (initialValues.supervisorsignatureexist) {
          const dataURLsupervisor = `data:image/png;base64,${sessionNoteResponse.data[0].supervisorSignatureFileName}`;
          initialValues.supervisorImage = dataURLsupervisor;
          initialValues.supervisorsignaturedate = dayjs(
            sessionNoteResponse.data[0].supervisorSignatureDate
          );
        }

        const convert = (time12Hour) => {
          const [hour, minutePart] = time12Hour.split(":");
          const [minute, period] = minutePart.split(" ");

          let hour24 = parseInt(hour, 10);
          if (period === "PM" && hour24 !== 12) {
            hour24 += 12;
          } else if (period === "AM" && hour24 === 12) {
            hour24 = 0;
          }

          return `${String(hour24).padStart(2, "0")}:${minute}`;
        };

        const start = convert(sessionNoteResponse.data[0].sessionStartTime);
        const end = convert(sessionNoteResponse.data[0].sessionEndTime);

        // Construct the full datetime string without specifying a time zone
        const initialStartTime = moment(
          `Mon Oct 30 2023 ${start}:00`,
          "ddd MMM DD YYYY HH:mm:ss"
        );
        const initialEndTime = moment(
          `Mon Oct 30 2023 ${end}:00`,
          "ddd MMM DD YYYY HH:mm:ss"
        );
        initialValues.sessiontime = [initialStartTime, initialEndTime];
        updateStateFromArray(
          sessionNoteResponse.data[0].sessionParticipants,
          sessionParticipantsLabels,
          setSessionParticipants
        );
        updateStateFromArray(
          sessionNoteResponse.data[0].supervisionProvided,
          moreInfoLabels,
          setMoreInfoState
        );

        setInitialValues(initialValues);
        setIsloading(false);
      } catch (error) {
        console.error("Error fetching data:", error);

        setSnackbar({
          open: true,
          message: "Failed to fetch data",
          severity: "error",
        });
      }
    }

    fetchData();
  }, []);
  useEffect(() => {}, [isloading]);
  const handleApprove = (formData, { resetForm }) => {
    console.log("approve");
    if (handleErrors(formData)) {
      return;
    }
    const payload = handleData(formData);
    console.log("payload", payload);
    if (payload === null) {
      return;
    }
    if (
      !formData.supervisorsignatureexist &&
      !payload.supervisorsignaturefilename
    ) {
      setSnackbar({
        open: true,
        message: "Supervisor signature is required",
        severity: "error",
      });
      return;
    }
    payload.sessionstatus = "Approved";
    setSnackbar({
      open: true,
      message: "Approving...",
      severity: "info",
    });
    handleFormSubmit(payload, { resetForm });
    console.log(payload, "sent");
  };
  const handleData = (formData) => {
    console.log("inside handle data");
    console.log("formData before processing: ", formData);

    const formattedSessionDate = dayjs(formData.sessiondate).format(
      "YYYY-MM-DD"
    );

    let sessionpart =
      (sessionparticipantsState.child ? "Child;" : "") +
      (sessionparticipantsState.parent ? "Parent;" : "") +
      (sessionparticipantsState.bcba ? "BCBA;" : "") +
      (sessionparticipantsState.behaviourtechnician
        ? "Behaviour Technician;"
        : "") +
      (sessionparticipantsState.other ? "Other;" : "");

    console.log("sessionparticipants ", sessionpart);

    if (sessionpart === "") {
      setSnackbar({
        open: true,
        message: "Please select Session Participants",
        severity: "error",
      });
      return null;
    }

    let moreinfo =
      (moreInfoState.optiona ? "Direct Supervision of RBT/BT;" : "") +
      (moreInfoState.otherinfocheckbox ? "Other;" : "");

    console.log("moreinfo ", moreinfo);

    if (moreinfo === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the Supervision Provided",
        severity: "error",
      });
      return null;
    }

    const tempo = formData.clientid;
    console.log(
      "clientid from formData before payload creation: ",
      formData.clientid
    );

    const payload = {
      ...formData,
      sessiondate: formattedSessionDate,
      id: sessionId,
      sessionparticipants: sessionpart,
      otherdiagnosiscode:
        formData.diagnosiscode1 === "Other" ? formData.otherdiagnosiscode : "",
      sessionendtime: formData.sessiontime[1].format("HH:mm:a"),
      sessionstarttime: formData.sessiontime[0].format("HH:mm:a"),
      supervisionprovided: moreinfo,
      clientid: formData.clientid, // Directly assigning from formData
    };
    delete payload.clientid;
    delete payload.sessiontime;
    payload.clientid = tempo;
    console.log("payload after creation: ", payload);
    //return null;
    if (!formData.supervisorsignatureexist) {
      if (sigSupervisor.current.isEmpty()) {
        payload.supervisorsignatureexist = false;
        payload.supervisorsignaturefilename = null;
        payload.supervisorsignaturedate = null;
      } else {
        payload.supervisorsignatureexist = false;
        payload.supervisorsignaturefilename = sigSupervisor.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        payload.supervisorsignaturedate = dayjs(
          formData.supervisorsignaturedate
        ).format("YYYY-MM-DD");
      }
    }
    return payload;
  };
  const handleErrors = (formData) => {
    if (!formData.sessiondate) {
      setSnackbar({
        open: true,
        message: "Please fill in the session date",
        severity: "error",
      });
      return true;
    }
    if (!formData.sessiontype) {
      setSnackbar({
        open: true,
        message: "Please fill in the session type",
        severity: "error",
      });
      return true;
    } else if (!formData.facilityid) {
      setSnackbar({
        open: true,
        message: "Please fill in the facility name",
        severity: "error",
      });
      return true;
    } else if (!formData.clientid) {
      setSnackbar({
        open: true,
        message: "Please fill in the client name",
        severity: "error",
      });
      return true;
    } else if (!formData.staffid) {
      setSnackbar({
        open: true,
        message: "Please fill in the staff name",
        severity: "error",
      });
      return true;
    } else if (!formData.supervisorid) {
      setSnackbar({
        open: true,
        message: "Please fill in the supervisor name",
        severity: "error",
      });
      return true;
    } else if (!formData.parentorgurdianname) {
      setSnackbar({
        open: true,
        message: "Please fill in the parent or guardian name",
        severity: "error",
      });
      return true;
    } else if (!formData.placeofservicecode) {
      setSnackbar({
        open: true,
        message: "Please fill in the place of service",
        severity: "error",
      });
      return true;
    } else if (!formData.modifier) {
      setSnackbar({
        open: true,
        message: "Please fill in the modifier",
        severity: "error",
      });
      return true;
    } else if (!formData.diagnosiscode1) {
      setSnackbar({
        open: true,
        message: "Please fill in the diagnosis code",
        severity: "error",
      });
      return true;
    } else if (
      !formData.sessiontime ||
      !formData.sessiontime[0] ||
      !formData.sessiontime[1] === null
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the session time",
        severity: "error",
      });
      return true;
    } else if (
      formData.sessiontime[0].format("HH:mm:a") == "Invalid Date" ||
      formData.sessiontime[1].format("HH:mm:a") == "Invalid Date" ||
      formData.sessiontime[0].format("HH:mm:a") >
        formData.sessiontime[1].format("HH:mm:a")
    ) {
      setSnackbar({
        open: true,
        message: "Please fill session time correctly",
        severity: "error",
      });
      return true;
    } else if (formData.placeofservicecode === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the place of service",
        severity: "error",
      });
      return true;
    } else if (
      formData.placeofservicecode == "Other" &&
      formData.otherplaceofservice == ""
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the other place of service",
        severity: "error",
      });
      return true;
    } else if (
      formData.diagnosiscode1 == "Other" &&
      formData.otherdiagnosiscode == ""
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the other diagnosis code",
        severity: "error",
      });
      return true;
    } else if (formData.modifier === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the modifier",
        severity: "error",
      });
      return true;
    } else if (formData.procedurecode === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the procedure code",
        severity: "error",
      });
      return true;
    } else if (formData.diagnosiscode1 === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the diagnosis code",
        severity: "error",
      });
      return true;
    } else if (formData.sessionsummary === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the session summary",
        severity: "error",
      });
      return true;
    }
    if (formData.primarytargetareaaddressed === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the primary target area addressed",
        severity: "error",
      });
      return true;
    }
    if (formData.nextsessionplan === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the next session plan",
        severity: "error",
      });
      return true;
    }

    if (formData.feedbackrbt === "") {
      setSnackbar({
        open: true,
        message: "Please fill in the feedback to rbt",
        severity: "error",
      });
      return true;
    }
    if (
      !formData.supervisorsignatureexist &&
      !sigSupervisor.current.isEmpty() &&
      formData.supervisorsignaturedate === null
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the supervisor signature date",
        severity: "error",
      });
      return true;
    }
    if (
      moreInfoState.otherinfocheckbox &&
      formData.othersupervisionprovided === ""
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the other supervision provided",
        severity: "error",
      });
      return true;
    }
    if (
      sessionparticipantsState.other &&
      formData.othersessionparticipants === ""
    ) {
      setSnackbar({
        open: true,
        message: "Please fill in the other session participants",
        severity: "error",
      });
      return true;
    }

    return false;
  };
  const handleSaveAsDraft = (formData, { resetForm }) => {
    console.log("draft", formData);
    if (handleErrors(formData)) {
      return;
    }
    const payload = handleData(formData);
    if (payload === null) {
      return;
    }
    console.log(payload, "sent");
    // console.log(values);
    setSnackbar({
      open: true,
      message: "Saving as draft...",
      severity: "info",
    });
    payload.sessionstatus = "Draft";
    console.log(payload);
    handleFormSubmit(payload, { resetForm });

    // Add your logic for saving as draft
  };

  const resetOtherValues = (values) => {
    values.clientid = "";

    clearSupervisorSignature();

    setSessionParticipants({
      child: false,
      parent: false,
      bcba: false,
      behaviourtechnician: false,
      other: false,
    });
    setMoreInfoState({
      optiona: false,
      otherinfocheckbox: false,
    });
  };

  const handleFormSubmit = (payload, { resetForm }) => {
    axiosInstance
      .post(sharedLink + "/updatesessionnote", {
        ...payload,
        staffid: payload.staffid.id,
        supervisorid: payload.supervisorid.id,
        facilityid: payload.facilityid.id,
        clientid: payload.clientid.id,
      })
      .then((res) => {
        console.log("response", res);
        if (res.status === 200) {
          setSnackbar({
            open: true,
            message: "Saved Successfully",
            severity: "success",
          });
          //   resetForm();
          //   resetOtherValues(payload);
        } else {
          setSnackbar({
            open: true,
            message: "Failed to save",
            severity: "error",
          });
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response &&
          error.response.data &&
          typeof error.response.data === "string" &&
          error.response.data.trim() !== ""
            ? error.response.data
            : "An error has occurred. Please contact support.";

        setSnackbar({
          open: true,
          message: errorMessage,
          severity: "error",
        });

        console.error("An error occurred:", errorMessage);
      });
  };
  if (isloading) {
    return <LoadingIndicator />;
  } else {
    return (
      <Box m="20px">
        <Snackbar
          open={snackbar.open}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
          <Header
            title="Create Session Note (97155) "
            subtitle=""
            style={{ flexGrow: 1, textAlign: "center" }}
          />
          <br />
        </Box>
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}>
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={facilities}
                    getOptionLabel={(option) => `${option.facilityName} `}
                    value={values.facilityid || null}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Facility Name"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Facility",
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue("facilityid", newValue ? newValue : "");
                    }}
                    disabled={true}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={clients}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} `
                    }
                    autoHighlight
                    value={values.clientid || null}
                    disabled={true}
                    onChange={(event, newValue) => {
                      setFieldValue("clientid", newValue ? newValue : "");
                      setFieldValue(
                        "insurancename",
                        newValue ? newValue.insuranceName : ""
                      );
                      setFieldValue(
                        "insurancepolicynumber",
                        newValue ? newValue.policyNumber : ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Client Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={staffs}
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} `
                    }
                    value={values.staffid || null}
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Staff Name"
                        variant="filled"
                        className="animated-input"
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Staff Name",
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue("staffid", newValue ? newValue : "");
                    }}
                    disabled={true}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    options={supervisor}
                    getOptionLabel={(option) =>
                      `${option.name}` +
                      (option.role ? ` (${option.role}) ` : "")
                    }
                    autoHighlight
                    value={values.supervisorid || null}
                    onChange={(event, newValue) => {
                      setFieldValue("supervisorid", newValue ? newValue : "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Supervisor Name"
                        variant="filled"
                        className="animated-input"
                        required
                      />
                    )}
                    disabled={true}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Insurance Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={values.insurancename}
                  name="insurancename"
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Insurance Policy Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  disabled
                  value={values.insurancepolicynumber}
                  name="insurancepolicynumber"
                  error={
                    !!touched.insurancepolicynumber &&
                    !!errors.insurancepolicynumber
                  }
                  helperText={
                    touched.insurancepolicynumber &&
                    errors.insurancepolicynumber
                  }
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <Autocomplete
                  fullWidth
                  autoHighlight
                  options={parentOrGurdian} // Array of parent or guardian names
                  getOptionLabel={(option) => option} // Assuming each option has a 'name' property
                  value={
                    parentOrGurdian.find(
                      (item) => item === values.parentorgurdianname
                    ) || ""
                  }
                  onChange={(event, newValue) => {
                    setFieldValue(
                      "parentorgurdianname",
                      newValue ? newValue : ""
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parent or Guardian"
                      variant="filled"
                      className="animated-input"
                      required
                    />
                  )}
                  disabled={true}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={sessionType}
                    getOptionLabel={(option) => option} // Since options are strings
                    autoHighlight
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Session Type"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select Session Type",
                        }}
                      />
                    )}
                    value={values.sessiontype || null} // Fallback to null if no value is set
                    onChange={(event, newValue) => {
                      setFieldValue("sessiontype", newValue);
                    }}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    disabled={true}
                  />
                </FormControl>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Session Date"
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    name="sessiondate"
                    required
                    disabled={true}
                    value={values.sessiondate}
                    onChange={(newValue) => {
                      handleChange({
                        target: {
                          name: "sessiondate",
                          value: newValue,
                        },
                      });
                    }}
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    onBlur={handleBlur}
                    shouldDisableDate={disableFutureDates}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <TimePicker
                          label="Session start Time"
                          sx={{ gridColumn: "span 2",...themeStyles.textField }}
                          fullWidth
                          variant="filled"
                          className="animated-input"
                          name="sessionstarttime"
                          value={values.sessionstarttime}
                          onChange={(newValue) => {
                            handleChange({
                              target: {
                                name: "sessionstarttime",
                                value: newValue,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                        /> */}
                  <MultiInputTimeRangeField
                    name="sessiontime"
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    fullWidth
                    value={values.sessiontime}
                    onChange={(newValue) => {
                      handleChange({
                        target: {
                          name: "sessiontime",
                          value: newValue,
                        },
                      });
                    }}
                    disabled={true}
                    slotProps={{
                      textField: ({ position }) => ({
                        label:
                          position === "start"
                            ? "Session start time"
                            : "Session end time",
                        required: true,
                      }),
                    }}
                  />
                </LocalizationProvider>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          label="Session End Time"
                          sx={{ gridColumn: "span 2",...themeStyles.textField }}
                          fullWidth
                          variant="filled"
                          className="animated-input"
                          name="sessionendtime"
                          value={values.sessionendtime}
                          onChange={(newValue) => {
                            handleChange({
                              target: {
                                name: "sessionendtime",
                                value: newValue,
                              },
                            });
                          }}
                          onBlur={handleBlur}
                        />
                      </LocalizationProvider> */}
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={placeOfService} // Assuming 'placeOfService' is the array of options
                    autoHighlight
                    disabled={true}
                    getOptionLabel={(option) => option.toString()} // Assuming 'option' is a string or object that can be converted to string
                    value={values.placeofservicecode}
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "placeofservicecode",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Place of service"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a place of service",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Other Place of Service (Maxlength: 30)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required={values.placeofservicecode === "Other"}
                  value={values.otherplaceofservice}
                  name="otherplaceofservice"
                  disabled={true}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={moDifier} // Assuming 'moDifier' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.modifier || ""} // Fallback to an empty string if values.modifier is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "modifier",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    disabled={true}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Modifier"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a modifier",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option} value={option}>
                        {option}
                      </MenuItem>
                    )}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={procedureCode} // Assuming 'procedureCode' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.procedurecode || ""} // Fallback to an empty string if values.procedurecode is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "procedurecode",
                          value: newValue || null, // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Procedure Code"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a procedure code",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    disabled={true}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option} value={option}>
                        {option}
                      </MenuItem>
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <Autocomplete
                    fullWidth
                    options={diagnosisCode} // Assuming 'diagnosisCode' is the array of options
                    autoHighlight
                    getOptionLabel={(option) => option} // Assuming 'option' is a string you want to display
                    value={values.diagnosiscode1 || ""} // Fallback to an empty string if values.diagnosiscode1 is undefined
                    onChange={(event, newValue) => {
                      // Create a synthetic event to pass to handleChange
                      handleChange({
                        target: {
                          name: "diagnosiscode1",
                          value: newValue || "", // Use an empty string if newValue is null
                        },
                      });
                    }}
                    onBlur={handleBlur} // Assuming you have a handleBlur function for onBlur event
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Diagnosis Code 1"
                        variant="filled"
                        className="animated-input"
                        required
                        inputProps={{
                          ...params.inputProps,
                          "aria-label": "Select a diagnosis code",
                        }}
                      />
                    )}
                    sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                    disabled={true}
                  />
                </FormControl>

                <TextField
                  fullWidth
                  variant="filled"
                  className="animated-input"
                  label="Other Diagnosis Code (Maxlength: 30)"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="otherdiagnosiscode"
                  required={values.diagnosiscode1 === "Other"}
                  disabled={true}
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                  inputProps={{
                    maxLength: 30,
                  }}
                />

                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Session Participant</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={sessionparticipantsState.child}
                        onChange={handleChange1}
                        name="child"
                      />
                    }
                    label="Child"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={sessionparticipantsState.parent}
                        onChange={handleChange1}
                        name="parent"
                      />
                    }
                    label="Parent"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={sessionparticipantsState.bcba}
                        onChange={handleChange1}
                        name="bcba"
                      />
                    }
                    label="BCBA"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={sessionparticipantsState.behaviourtechnician}
                        onChange={handleChange1}
                        name="behaviourtechnician"
                      />
                    }
                    label="Behaviour Technician"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={sessionparticipantsState.other}
                        onChange={handleChange1}
                        name="other"
                      />
                    }
                    label="Other"
                  />
                  {sessionparticipantsState.other && (
                    <TextareaAutosize
                      fullWidth
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="othersessionparticipants"
                      value={values.othersessionparticipants}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={2}
                      disabled={isDisabled}
                      required={sessionparticipantsState.other}
                      inputProps={{ maxLength: 30 }}
                    />
                  )}
                </FormGroup>
                <FormGroup
                  sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                  <FormLabel component="legend">Supervision Provided</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={moreInfoState.optiona}
                        onChange={handleMoreInformation}
                        name="optiona"
                      />
                    }
                    label="Direct Supervision of RBT/BT"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={isDisabled}
                        checked={moreInfoState.otherinfocheckbox}
                        onChange={handleMoreInformation}
                        name="otherinfocheckbox"
                      />
                    }
                    label="Other"
                  />
                  {moreInfoState.otherinfocheckbox && (
                    <TextareaAutosize
                      fullWidth
                      disabled={isDisabled}
                      variant="filled"
                      className="animated-input"
                      placeholder="Type in here… (Maxlength: 30)"
                      name="othersupervisionprovided"
                      value={values.othersupervisionprovided}
                      onChange={handleChange}
                      minRows={2}
                      maxRows={3}
                      required={moreInfoState.otherinfocheckbox}
                      inputProps={{ maxLength: 30 }}
                    />
                  )}
                </FormGroup>

                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Feedback to RBT/BT (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() => handleImageModal("feedbackrbt.jpeg")}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    disabled={isDisabled}
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.feedbackrbt}
                    name="feedbackrbt"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                  />
                </FormGroup>

                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Supervision Session Summary (Maxlength: 2500)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() => handleImageModal("sessionsummary.png")}>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.sessionsummary}
                    name="sessionsummary"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 2500 }}
                    required
                  />
                </FormGroup>
                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Primary Target Areas Observed/ Addressed (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() =>
                          handleImageModal("primarytargetareaaddressed.jpeg")
                        }>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    disabled={isDisabled}
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.primarytargetareaaddressed}
                    name="primarytargetareaaddressed"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                  />
                </FormGroup>

                <FormGroup
                  fullWidth
                  sx={{ gridColumn: "span 4", ...themeStyles.textField }}>
                  <FormLabel component="legend">
                    Next Session Plans (Maxlength: 1000)
                    <Tooltip title="Help">
                      <IconButton
                        onClick={() =>
                          handleImageModal("nextsessionplan.jpeg")
                        }>
                        <HelpOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </FormLabel>
                  <TextField
                    fullWidth
                    variant="filled"
                    className="animated-input"
                    disabled={isDisabled}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.nextsessionplan}
                    name="nextsessionplan"
                    sx={{ gridColumn: "span 4", ...themeStyles.textField }}
                    rows={5}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                  />
                </FormGroup>

                {/* <FormGroup fullWidth sx={{ gridColumn: "span 2",...themeStyles.textField }}></FormGroup> */}
                {values.supervisorImage ? (
                  <>
                    <FormGroup
                      sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                      <FormLabel component="legend">
                        supervisor's signature
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <img
                            name="supervisorImage"
                            src={values.supervisorImage}
                            alt="Bytecode Image"
                            height="100"
                            width="150"
                          />
                        }
                      />
                    </FormGroup>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Supervisor Signature Date"
                        sx={{ gridColumn: "span 2", ...themeStyles.textField }}
                        fullWidth
                        variant="filled"
                        className="animated-input"
                        name="supervisorsignaturedate"
                        value={values.supervisorsignaturedate}
                        disabled
                        onBlur={handleBlur}
                      />
                    </LocalizationProvider>
                  </>
                ) : (
                  !isDisabled && (
                    <>
                      <FormGroup
                        sx={{ gridColumn: "span 2", ...themeStyles.textField }}>
                        <FormLabel component="legend">Signature</FormLabel>
                        <FormControlLabel
                          control={
                            <SignaturePad
                              ref={sigSupervisor}
                              onEnd={handleSupervisorSignature}
                              canvasProps={{
                                height: 200,
                                className: "sigCanvas",
                              }}
                            />
                          }
                        />
                        <FormControlLabel
                          control={
                            <div>
                              <Button
                                onClick={clearSupervisorSignature}
                                // fullWidth
                                //  fullWidth
                                variant="contained"
                                color="primary"
                                margin="10px"
                                sx={{ gridColumn: "span 1", ...buttonStyle }}>
                                Clear
                              </Button>
                            </div>
                          }
                        />
                      </FormGroup>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Signature Date"
                          sx={{
                            gridColumn: "span 2",
                            ...themeStyles.textField,
                          }}
                          fullWidth
                          variant="filled"
                          className="animated-input"
                          name="supervisorsignaturedate"
                          value={values.supervisorsignaturedate}
                          onChange={(newValue) => {
                            handleChange({
                              target: {
                                name: "supervisorsignaturedate",
                                value: newValue,
                              },
                            });
                          }}
                          slotProps={{
                            textField: {
                              required: isSignaturePresent, // Dynamic required attribute

                              // Dynamic disabled attribute
                            },
                          }}
                          disabled={!isSignaturePresent}
                          onBlur={handleBlur}
                          shouldDisableDate={disableFutureDates}
                        />
                      </LocalizationProvider>
                    </>
                  )
                )}
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  onClick={() => {
                    // When navigating to the "viewSessionNotes" route

                    const stateToSave = {
                      formValues: previouspagevalues?.formValues,
                      rows: previouspagevalues?.rows,
                      staffs: previouspagevalues?.staffs,
                      clients: previouspagevalues?.clients,
                    };
                    console.log(from + "from");
                    console.log(stateToSave, "edit");
                    navigate(from ? "/" + from : -1, {
                      state: { previouspagevalues: stateToSave },
                    });
                  }}
                  color="secondary"
                  sx={{ ...buttonStyle }}
                  variant="contained"
                  style={{ marginRight: "10px" }}>
                  Go Back
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  sx={{ ...buttonStyle }}
                  variant="contained"
                  disabled={isDisabled}
                  style={{ marginRight: "10px" }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    handleSaveAsDraft(values, { resetForm });
                  }} // pass the values directly
                >
                  Save as draft
                </Button>

                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  disabled={isDisabled}
                  sx={{ ...buttonStyle }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default form submission
                    handleApprove(values, { resetForm });
                  }}>
                  Save as Approve
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    );
  }
};

const checkoutSchema = yup.object().shape({});

export default UpdateSessionNote97155;
